import * as actionTypes from '../action';
import fonts from '../../Components/utils/options/fonts';

const initialState = {
    texts: []
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_TEXT:
            const text = {
                uiId: action.uiId,
                className: action.uiId,
                text: action.value,
                type: "text",
                fontType: fonts[0].id,
                animationType:'none',
                iterationType:'default',
                animationDelay: 0,
                animationDuration:0,
                fontSize: 16,
                color: '#000000',
                index: 0,
                transform:'',
                redirectionUrl: '',
                inFocus:false
            };
            return {
                ...state,
                texts: [...state.texts, text]
            }
        case actionTypes.DELETE_TEXT:
            return {
                ...state,
                texts: state.texts.filter(txt => txt.uiId !== action.uiId)
            }
        case actionTypes.ADD_ALL_TEXTS:
            return {
                ...state,
                texts:[...action.texts]
        }
        case actionTypes.DELETE_ALL_TEXTS:
            return {
                ...state,
                texts:[]
        }
        case actionTypes.SET_TEXT_REDIRECTION_URL:
            return {
                ...state,
                texts: state.texts.map(txt => txt.uiId === action.uiId ? { ...txt, redirectionUrl: action.value } : txt)
            }
        case actionTypes.SET_TEXT_COLOR:
            return {
                ...state,
                texts: state.texts.map(
                    txt => txt.uiId === action.uiId ? { ...txt, color: action.value } : txt
                )
            }
        case actionTypes.SET_TEXT_Z_INDEX:
            return {
                ...state,
                texts: state.texts.map(
                    txt => txt.uiId === action.uiId ? { ...txt, index: action.value } : txt
                )
            }
        case actionTypes.SET_TEXT_FONT_FAMILY:
            return {
                ...state,
                texts: state.texts.map(
                    txt => txt.uiId === action.uiId ? { ...txt, fontType: action.value } : txt
                )
            }
        case actionTypes.SET_TEXT_FONT_SIZE:
            return {
                ...state,
                texts: state.texts.map(
                    txt => txt.uiId === action.uiId ? { ...txt, fontSize: action.value } : txt
                )
            }
        case actionTypes.SET_TEXT_ANIMATION:
            return {
                ...state,
                texts: state.texts.map(
                    txt => txt.uiId === action.uiId ? {
                        ...txt,
                        animationType: action.data.animationType,
                        iterationType: action.data.iterationType,
                        animationDelay: action.data.animationDelay,
                        animationDuration: action.data.animationDuration,
                        className: action.data.className
                    } : txt
                )
            }

        case actionTypes.SET_TEXT_TRANSFORM:
            return {
                ...state,
                texts: state.texts.map(txt => txt.uiId === action.uiId ? { ...txt, transform: action.value} : txt)
            }
        case actionTypes.SET_TEXT_CONTENT:
            return {
                ...state,
                texts: state.texts.map(txt => txt.uiId === action.uiId ? { ...txt, text: action.value} : txt)
            }
        case actionTypes.REVERT_POSITION_TEXT:
            let transformVal = action.transform ? action.transform.replace(/\b(\d*\.?\d+) *(px)/g, 0) : null;
            return {
                ...state,
                texts: state.texts.map(txt => txt.uiId === action.uiId ? { ...txt, transform: transformVal} : txt)
            }
        case actionTypes.FOCUS_TEXT:
            return {
                ...state,
                texts: state.texts.map(txt => txt.uiId === action.uiId ? { ...txt, inFocus: true} : txt)
            }
        case actionTypes.TEXT_REMOVE_FOCUS:
            return {
                ...state,
                texts: state.texts.map(txt => txt.inFocus === true ? { ...txt, inFocus: false} : txt )
            }
        default:
            return state;
    }

}

export default reducer