import { Autocomplete, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import './AutoComplete.scss';

const FontAutoComplete = (props) => {
  const jsonToString = (value) => {
    return JSON.stringify(value);
  };

  const stringToJson = (value) => {
    return value && value !== '' ? JSON.parse(value) : '';
  }

  const whatIsIt = (object) => {
    const stringConstructor = "".constructor;
    const arrayConstructor = [].constructor;
    const objectConstructor = ({}).constructor;

    if (object === null) {
      return "null";
    }
    if (object === undefined) {
      return "undefined";
    }
    if (object.constructor === stringConstructor) {
      return "String";
    }
    if (object.constructor === arrayConstructor) {
      return "Array";
    }
    if (object.constructor === objectConstructor) {
      return "Object";
    }
    return "don't know";
  };

  const font = useSelector(state => state.creative.font);
  const [creativeFont, setCreativeFont] = useState(whatIsIt(font) === 'Object' ? stringToJson(font) : null);
  const [fonts, setFonts] = useState([]);

  const handleChangeFont = (value) => {
    const stringValue = jsonToString(value);
    props.onChange(stringValue);
    setCreativeFont(value);
  };

  // TODO: split to diffrent file
  const fetchGoogleFonts = () => {
    const url = 'https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=';
    const key = 'AIzaSyCEgJDxSSAzjlbeZ67SaEWm7Ml6snZkjt4';

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(url + key, requestOptions)
      .then(response => response.json())
      .then(result => {
        setFonts(result.items);
        return;
      })
      .catch(error => console.log('error', error));
  };

  useEffect(() => {
    if (font) {
      const jsonValue = stringToJson(font);
      setCreativeFont(jsonValue);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchGoogleFonts();
  }, []);

  const checkValue = (option, value) => {
    return option?.family === value?.family;
  };

  // console.log({ font })
  // console.log({ fonts });
  // console.log({ creativeFont: creativeFont });

  return (
    <Stack>
      <label 
        style={{
          fontSize: '1.1vw',
          fontWeight: 'bold', 
          fontFamily: 'Lexend',
          lineHeight: '2vw',
        }}
      >
        {props.label}
      </label>
      <Autocomplete
        disablePortal
        options={fonts}
        getOptionLabel={(option) => option.family || ''}
        renderInput={(params) => {
          return <TextField {...params} label="" />
        }}
        onChange={(event, value) => handleChangeFont(value)}
        value={creativeFont}
        isOptionEqualToValue={checkValue}
          sx={{
            fontFamily: 'Lexend',
            width: '100%',
            marginTop: '8px',
            '& .MuiInputBase-root': {
              padding: '0.6vw 1.2vw'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #35A7E0',
              borderRadius: '10vw',
              '& legend': {
                display: 'none',
              },
            },
            '& .MuiAutocomplete-input': {
              fontSize: '1vw', 
              padding: '0 !important',
              height: '1.883vw',
              fontFamily: 'Lexend',
            },
          }}
      />
    </Stack>
  )
}

export default FontAutoComplete;