import * as utils from '../../../utils.js';
import { ITERATION_MAP } from './iterations.js';

export const NONE = 'none';
export const SHAKE = 'shake';
export const BOUNCE = 'bounce';
export const PULSE = 'pulse';
export const HEARTBEAT = 'heartbeat';
export const FADE_IN = 'fadein';
export const SLIDE_IN_LEFT = 'slideinleft';
export const SLIDE_IN_DOWN = 'slideindown';
export const SLIDE_IN_RIGHT = 'slideinright';
export const SLIDE_IN_UP = 'slideinup';

const TIMING_FUNCTION = {
    BEZIER: 'cubic-bezier(.5,.09,.58,1.28)',
    BEZIER_1: 'cubic-bezier(.59,.2,0,1.06)',
    BEZIER_2:'cubic-bezier(.5,.09,.59,1.47)',
    LINEAR: 'linear',
    EASE:'ease',
    EASE_IN_OUT:'ease-in-out'
}

const createShake = (id, {top, left}, app, iterationValue, delayValue, durationValue, prefix) => {
    let iteration = iterationValue === 'default'? 'infinite' : ITERATION_MAP[iterationValue],
        duration = durationValue === 0? '4.72s' : `${durationValue}s`,
        animationName = utils.getAnimationClassName(id, SHAKE, iterationValue, delayValue, durationValue, prefix),
        animationCss = utils.getCrossBrowserAnimationCss(animationName, duration, TIMING_FUNCTION.EASE, iteration, delayValue);
    let ani = `
        @keyframes ${animationName} {
            0% { transform:translate(0,0) }
            1% { transform:translate(5px,0) }
            3% { transform:translate(0,0) }
            5% { transform:translate(5px,0) }
            7% { transform:translate(0,0) }
            9% { transform:translate(5px,0) }
            11% { transform:translate(0,0) }
            100% { transform:translate(0,0) }
        }
        .${animationName} {
            transform-origin: 50% 50%;
            ${animationCss}
        }`;
    return ani;
}

const createBounce = (id, {top, left}, app, iterationValue, delayValue, durationValue, prefix) => {
    let iteration = iterationValue === 'default'? 'infinite' : ITERATION_MAP[iterationValue],
        duration = durationValue === 0? '1s' : `${durationValue}s`,
        animationName = utils.getAnimationClassName(id, BOUNCE, iterationValue, delayValue, durationValue, prefix),
        animationCss = utils.getCrossBrowserAnimationCss(animationName, duration, TIMING_FUNCTION.LINEAR, iteration, delayValue);
    let ani = `
        @keyframes ${animationName} {
            from {
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(${left}${app ? 'px' : '%'}, ${top}${app ? 'px' : '%'}, 0);
            }
            20% {
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(${left}${app ? 'px' : '%'}, ${top}${app ? 'px' : '%'}, 0);
            }
            40% {
                animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                transform: translate3d(${left}${app ? 'px' : '%'}, ${top-utils.percentage.height(30)}${app ? 'px' : '%'}, 0) scaleY(1.1);
            }
            43% {
                animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                transform: translate3d(${left}${app ? 'px' : '%'}, ${top-utils.percentage.height(30)}${app ? 'px' : '%'}, 0) scaleY(1.1);
            }
            53% {
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(${left}${app ? 'px' : '%'}, ${top}${app ? 'px' : '%'}, 0);
            }
            70% {
                animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                transform: translate3d(${left}${app ? 'px' : '%'}, ${top-utils.percentage.height(15)}${app ? 'px' : '%'}, 0) scaleY(1.05);
            }
            80% {
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(${left}${app ? 'px' : '%'}, ${top}${app ? 'px' : '%'}, 0) scaleY(0.95});
            }
            90% {
                transform: translate3d(${left}${app ? 'px' : '%'}, ${top-utils.percentage.height(4)}${app ? 'px' : '%'}, 0) scaleY(1.02);
            }
            to {
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(${left}${app ? 'px' : '%'}, ${top}${app ? 'px' : '%'}, 0);
            }
        }
        .${animationName} {
            transform-origin: center bottom;
            ${animationCss}
        }`;
    return ani;
}

const createPulse = (id, {top, left}, app, iterationValue, delayValue, durationValue, prefix) => {
    let iteration = iterationValue === 'default'? 'infinite' : ITERATION_MAP[iterationValue],
        duration = durationValue === 0? '2s' : `${durationValue}s`,
        animationName = utils.getAnimationClassName(id, PULSE, iterationValue, delayValue, durationValue, prefix),
        animationCss = utils.getCrossBrowserAnimationCss(animationName, duration, TIMING_FUNCTION.LINEAR, iteration, delayValue);
    let ani = `
        @keyframes ${animationName} {
            from {
                -webkit-transform: scale3d(1,1,1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                -moz-transform: scale3d(1,1,1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                -ms-transform: scale3d(1,1,1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                -o-transform: scale3d(1,1,1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                transform: scale3d(1,1,1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
            }
            50% {
                -webkit-transform: scale3d(1.1,1.1,1.1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                -moz-transform: scale3d(1.1,1.1,1.1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                -ms-transform: scale3d(1.1,1.1,1.1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                -o-transform: scale3d(1.1,1.1,1.1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                transform: scale3d(1.1,1.1,1.1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
            }
            to {
                -webkit-transform: scale3d(1,1,1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                -moz-transform: scale3d(1,1,1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                -ms-transform: scale3d(1,1,1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                -o-transform: scale3d(1,1,1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                transform: scale3d(1,1,1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
            }
        }
        .${animationName} {
            -webkit-animation-fill-mode: both;
            -moz-animation-fill-mode: both;
            -o-animation-fill-mode: both;
            animation-fill-mode: both;
            ${animationCss}
        }`;
    return ani;
}

const createHeartBeat = (id, {top, left}, app, iterationValue, delayValue, durationValue, prefix) => {
    let iteration = iterationValue === 'default'? 'infinite' : ITERATION_MAP[iterationValue],
        duration = durationValue === 0? '1.3s' : `${durationValue}s`,
        animationName = utils.getAnimationClassName(id, HEARTBEAT, iterationValue, delayValue, durationValue, prefix),
        animationCss = utils.getCrossBrowserAnimationCss(animationName, duration, TIMING_FUNCTION.EASE_IN_OUT, iteration, delayValue);
    let ani = `
        @keyframes ${animationName} {
            0% {
                -webkit-transform: scale(1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                transform: scale(1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
            }
            14% {
                -webkit-transform: scale(1.3) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                transform: scale(1.3) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
            }
            28% {
                -webkit-transform: scale(1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                transform: scale(1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
            }
            42% {
                -webkit-transform: scale(1.3) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                transform: scale(1.3) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
            }
            70% {
                -webkit-transform: scale(1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
                transform: scale(1) translate(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'});
            }
        }
        .${animationName} {
            ${animationCss}
        }`;
    return ani;
}

const createFadeIn = (id, {top, left}, app, iterationValue, delayValue, durationValue, prefix) => {
    let iteration = iterationValue === 'default'? '' : ITERATION_MAP[iterationValue],
        duration = durationValue === 0? '6s' : `${durationValue}s`,
        animationName = utils.getAnimationClassName(id, FADE_IN, iterationValue, delayValue, durationValue, prefix),
        animationCss = utils.getCrossBrowserAnimationCss(animationName, duration, TIMING_FUNCTION.BEZIER_1, iteration, delayValue);
    let ani = `
        @keyframes ${animationName} {
            from { opacity:0 }
            to { opacity:1 }
        }
        .${animationName} {
            transform-origin: 50% 50%;
            animation-fill-mode: forwards;
            ${animationCss}
        }`;
    return ani;
}

const createSlideInLeft = (id, {top, left}, app, iterationValue, delayValue, durationValue, prefix) => {
    let iteration = iterationValue === 'default'? '' : ITERATION_MAP[iterationValue],
        duration = durationValue === 0? '1s' : `${durationValue}s`,
        animationName = utils.getAnimationClassName(id, SLIDE_IN_LEFT, iterationValue, delayValue, durationValue, prefix),
        animationCss = utils.getCrossBrowserAnimationCss(animationName, duration, TIMING_FUNCTION.BEZIER_2, iteration, delayValue);
    let ani = `
        @keyframes ${animationName} {
            0% {
                -webkit-transform: translate3d(-100vw, ${(top)}${app ? 'px' : '%'}, 0);
                transform: translate3d(-100vw, ${(top)}${app ? 'px' : '%'}, 0);
                visibility: visible
            }

            to {
                -webkit-transform: translate3d(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'},0);
                transform: translate3d(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'},0);
            }
        }
        .${animationName} {${animationCss}}`;
    return ani;
}

const createSlideInDown = (id, {top, left}, app, iterationValue, delayValue, durationValue, prefix) => {
    let iteration = iterationValue === 'default'? '' : ITERATION_MAP[iterationValue],
        duration = durationValue === 0? '1s' : `${durationValue}s`,
        animationName = utils.getAnimationClassName(id, SLIDE_IN_DOWN, iterationValue, delayValue, durationValue, prefix),
        animationCss = utils.getCrossBrowserAnimationCss(animationName, duration, TIMING_FUNCTION.BEZIER, iteration, delayValue);
    let ani = `
        @keyframes ${animationName} {
            0% {
                -webkit-transform: translate3d(${left}${app ? 'px' : '%'}, -100vh, 0);
                transform: translate3d(${left}${app ? 'px' : '%'}, -100vh, 0);
                visibility: visible
            }

            to {
                -webkit-transform: translate3d(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'},0);
                transform: translate3d(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'},0);
            }
        }
        .${animationName} {${animationCss}}`;
    return ani;
}

const createSlideInRight = (id, {top, left}, app, iterationValue, delayValue, durationValue, prefix) => {
    let iteration = iterationValue === 'default'? '' : ITERATION_MAP[iterationValue],
        duration = durationValue === 0? '1s' : `${durationValue}s`,
        animationName = utils.getAnimationClassName(id, SLIDE_IN_RIGHT, iterationValue, delayValue, durationValue, prefix),
        animationCss = utils.getCrossBrowserAnimationCss(animationName, duration, TIMING_FUNCTION.BEZIER_2, iteration, delayValue);
    let ani = `
        @keyframes ${animationName} {
            0% {
                -webkit-transform: translate3d(100vw, ${(top)}${app ? 'px' : '%'}, 0);
                transform: translate3d(100vw, ${(top)}${app ? 'px' : '%'}, 0);
                visibility: visible
            }

            to {
                -webkit-transform: translate3d(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'},0);
                transform: translate3d(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'},0);
            }
        }
        .${animationName} {${animationCss}}`;
    return ani;
};

const createSlideInUp = (id, {top, left}, app, iterationValue, delayValue, durationValue, prefix) => {
    let iteration = iterationValue === 'default'? '' : ITERATION_MAP[iterationValue],
        duration = durationValue === 0? '1s' : `${durationValue}s`,
        animationName = utils.getAnimationClassName(id, SLIDE_IN_UP, iterationValue, delayValue, durationValue, prefix),
        animationCss = utils.getCrossBrowserAnimationCss(animationName, duration, TIMING_FUNCTION.BEZIER, iteration, delayValue);
    let ani = `
        @keyframes ${animationName} {
            0% {
                -webkit-transform: translate3d(${left}${app ? 'px' : '%'}, 100vh, 0);
                transform: translate3d(${left}${app ? 'px' : '%'}, 100vh, 0);
                visibility: visible
            }
            to {
              -webkit-transform: translate3d(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'},0);
              transform: translate3d(${left}${app ? 'px' : '%'},${(top)}${app ? 'px' : '%'},0);
            }
        }
        .${animationName} {
            ${animationCss}
        }`;
    return ani;
};

const animations = [
    {
        id:NONE,
        name: 'None',
        convert: false
    },
    {
        id:SHAKE,
        name: 'Shake',
        convert: createShake
    },
    {
        id:BOUNCE,
        name: 'Bounce',
        convert: createBounce
    },
    {
        id:PULSE,
        name: 'Pulse',
        convert: createPulse
    },
    {
        id:HEARTBEAT,
        name: 'Heartbeat',
        convert: createHeartBeat
    },
    {
        id:FADE_IN,
        name: 'Fade-In',
        convert: createFadeIn
    },
    {
        id:SLIDE_IN_LEFT,
        name: 'Slide-In-Left',
        convert: createSlideInLeft
    },
    {
        id:SLIDE_IN_DOWN,
        name: 'Slide-In-Down',
        convert: createSlideInDown
    },
    {
        id:SLIDE_IN_RIGHT,
        name: 'Slide-In-Right',
        convert: createSlideInRight
    },
    {
        id:SLIDE_IN_UP,
        name: 'Slide-In-Up',
        convert: createSlideInUp
    }

]

export default animations;