import React, { useRef, useEffect } from "react";
import Moveable from "react-moveable";
import { useDispatch, useSelector  } from "react-redux";
import * as actionTypes from '../../store/action';
import * as utils from '../../utils.js';
import './style.css';
import { handleFocus, handleTransform } from './handlers';
import { handleSideMenu } from '../StyleMenus/handlers';

function MoveableShape(props) {
    const dispatch = useDispatch();
    const movableRef = useRef();
    const moveableElemRef = useRef();
    const didMountRef = useRef(false);
    let assignedTransformVal = props.transform;

    const shapeProps = utils.getShapeProperties(props.shapeType, props.color, props.image, props.borderRadius);

    const shapeDivStyle = {
        ...shapeProps.shape.inlineCss,
        ...shapeProps.style,
        transform: assignedTransformVal
    };


    const animationType = useSelector(state => state.shape_style.animationType);
    const iterationType = useSelector(state => state.shape_style.iterationType);
    const animationDelay = useSelector(state => state.shape_style.animationDelay);
    const animationDuration = useSelector(state => state.shape_style.animationDuration);


    const handleImageFocus = (event) =>{
        handleFocus(dispatch, props, props.type);
        handleSideMenu(dispatch, props.type);
    };

    const handleTransformChange = (target) =>{
        if(!assignedTransformVal || assignedTransformVal === '') return;
        handleTransform(dispatch, assignedTransformVal, props.uiId, props.type);
        if (animationType !== 'none') {
            dispatch({ type: actionTypes.SET_IMAGE_ANIMATION, uiId: props.uiId, data: utils.getAnimationData(props, animationType, iterationType, animationDelay, animationDuration)});
        }
    };

    useEffect(() => {
        if (didMountRef.current) {
            moveableElemRef.current.updateRect();
        } else didMountRef.current = true
    });

    return (
        <div className="item-container" data-focus={props.inFocus} id={`${props.uiId}_container`} style={{zIndex: props.index, opacity: props.opacity}}>

            <div className="shape has-context-menu" onMouseDown={handleImageFocus} id={props.uiId} style={shapeDivStyle} ref={movableRef}></div>
            <Moveable className={ `moveable_${props.uiId}`}
                target={movableRef}
                ref={moveableElemRef}
                rootContainer={props.parentRef.current}
                /* Resize event edges */
                /* draggable */
                draggable={true}
                throttleDrag={0}
                onDragStart={({ target, clientX, clientY }) => {}}
                onDrag={({ target, beforeDelta, beforeDist, left, top, right, bottom, delta, dist, transform,  clientX, clientY }: OnDrag) => {
                    target.style.transform = transform;
                    assignedTransformVal = transform;
                }}
                onDragEnd={({ target, isDrag, clientX, clientY }) => {
                    if(!assignedTransformVal || assignedTransformVal === '') return;
                    handleTransformChange(target);
                }}
                /* When resize or scale, keeps a ratio of the width, height. */
                keepRatio={true}
                /* scalable */
                /* Only one of resizable, scalable, warpable can be used. */
                scalable={true}
                throttleScale={0}
                onScaleStart={({ target, clientX, clientY }) => { }}
                onScale={({target, scale, dist, delta, transform, clientX, clientY  }: OnScale) => {
                   target.style.transform = transform;
                   assignedTransformVal = transform;
                }}
                onScaleEnd={({ target, isDrag, clientX, clientY }) => {
                    if(!assignedTransformVal || assignedTransformVal === '') return;
                    handleTransformChange(target);
                }}
                /* rotatable */
                rotatable={true}
                throttleRotate={0}
                onRotateStart={({ target, clientX, clientY }) => {}}
                onRotate={({ target,delta, dist, transform, clientX, clientY}: onRotate) => {
                    target.style.transform = transform;
                    assignedTransformVal = transform;
                }}
                onRotateEnd={({ target, isDrag, clientX, clientY }) => {
                    if(!assignedTransformVal || assignedTransformVal === '') return;
                    handleTransformChange(target);
                }}
            />
        </div>
    )
}

function anyFieldChanges(prev, next, fields) {
    for (let i = 0; i < fields.length; ++i) {
        const field = fields[i];
        if(prev[field] !== next[field]){
            return true;
        }
    }
    return false;
}

function areEqual(prevProps, nextProps) {
    /* return true if value is not updated,  otherwise return false */
    const fields = ['id', 'image', 'color', 'borderRadius','shapeType', 'inFocus', 'transform', 'opacity', 'index','redirectionUrl','animationDuration','animationType','animationType','iterationType', 'className'];
    if (!anyFieldChanges(prevProps, nextProps, fields)) {
        return true;
    }
    return false;
}

export default React.memo(MoveableShape, areEqual);


