import * as actionTypes from '../action';
import * as utils from '../../utils';
import shapes, { DEFAULT_COLOR } from '../../Components/utils/options/shapes';

function buildShapes(){
    let myShapes = [];
    shapes.forEach((s)=>{
        let obj = {
            uiId : utils.generateId(),
            shapeType: s.id,
            color: DEFAULT_COLOR,
            borderRadius:0,
            image: null,
            imageName:null,
            selected: false,
            hasBgImg: s.hasBgImg,
            hasBorderRadius: s.hasBorderRadius
        };
        myShapes.push(obj);
    });
    myShapes[0].selected = true;
    return myShapes;
}

const initialShapes = buildShapes();

const initialState = {
    shapes: [],
    selectedShape: undefined
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_SHAPES:
             return {
                 ...state,
                 selectedShape: initialShapes[0],
                 shapes: [...initialShapes]
             }
        case actionTypes.SELECT_SHAPE:
            return {
                ...state,
                selectedShape: Object.assign({}, action.shape),
                shapes: state.shapes.map(sh => sh.uiId === action.uiId ? { ...sh, selected: true} : { ...sh, selected: false})
            }
        case actionTypes.COLOR_SHAPE:
            return {
                ...state,
                selectedShape: { ...state.selectedShape, color: action.value, image: null, imageName: null},
                shapes: state.shapes.map(sh => sh.uiId === action.uiId ? { ...sh, color: action.value, image: null, imageName: null} : sh )
            }
        case actionTypes.IMAGE_SHAPE:
            return {
                ...state,
                selectedShape: { ...state.selectedShape, image: action.value, imageName: action.name},
                shapes: state.shapes.map(sh => sh.uiId === action.uiId ? { ...sh, image: action.value, imageName: action.name} : sh )
            }
        case actionTypes.RADIUS_SHAPE:
            return {
                ...state,
                selectedShape: { ...state.selectedShape, borderRadius: action.value},
                shapes: state.shapes.map(sh => sh.uiId === action.uiId ? { ...sh, borderRadius: action.value} : sh )
            }
            default:
                return state;
    }

}

export default reducer