import './style.scss';
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';

export default function Gallery(props) {
    const outerRef = useRef();

    // const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);
    const bgColor = useSelector(state => state.creative.backgroundColor);
    const assets = useSelector(state => state.creative.assets);

    const backgroundImage = assets?.find(asset => asset.url !== "" && asset.order === 0)?.url || "";
    const bannerImage = assets?.find(asset => asset.url !== "" && asset.order === 1)?.url || "";
    const logoImage = assets?.find(asset => asset.url !== "" && asset.order === 2)?.url || "";

    const [imgs, setImgs] = useState([]);

    useEffect(() => {
        setImgs(prevState => assets.filter((asset, index) => index > 2));
    }, [assets]);

    const bannerRatio = 0.5;

        const bannerStyle = {
        backgroundColor: bgColor,
        backgroundImage: backgroundImage !== "" ? `url(${backgroundImage})` : "none",
        backgroundSize: 'cover',
        minHeight: height * 0.2,
        minWidth: height * 0.2 * bannerRatio,
        width: '100%',
        height: '100%',
        borderRadius: '1.5vw',
    };

    return (
        <div 
            className="banner-display-container  interstitial-border" 
            style={{ 
                // backgroundImage: `url(${IphoneFrameImage})`, 
                width: '13vw', 
                height: '26vw',
                backgroundColor: 'white',
                borderRadius: '2vw',
            }}
        >
        {/*<div className="banner-display-container grid-content">*/}
            <img className='banner-display-frame-image' alt="" /> {/* src={IphoneFrameFrontCameraImage} /> */}
            <div className="banner-box gallery">
                <div className="target" style={bannerStyle} ref={outerRef}>
                    <div
                        className="gallery_banner"
                        style={{ backgroundImage: bannerImage !== "" ? `url(${bannerImage})` : "none" }}
                    >
                    </div>
                    {imgs.some(img => img.url !== "") &&
                        // <!-- Slider main container -->
                        <Swiper
                            direction={'horizontal'}
                            loop={true}
                            centeredSlides={true}
                            slidesPerView={1}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="gallery-mySwiper"
                        >
                            {imgs.map(img => {
                                if (img.url !== "") {
                                    return (
                                        <SwiperSlide key={img.id} className="swiper-slide">
                                            <img src={img.url} alt="slider-img" />
                                            {/* <img onClick={() => window.open(img.redirectionUrl)} src={img.url} alt="slider-img" /> */}
                                        </SwiperSlide>
                                    );
                                }
                                return null;
                            })}
                        </Swiper>
                    }
                    <footer className="gallery-logo-footer" style={{ backgroundColor: logoImage !== "" ? "#da0034" : "none" }}>
                        <div className="gallery_logo"
                            style={{ backgroundImage: logoImage !== "" ? `url(${logoImage})` : "none" }}
                        ></div>
                    </footer>
                </div>
            </div>
        </div>
    );
}

