export const tokenKey = 'dsUser';
let domain = 'https://www.adsil1.com/Office'; // development
// let domain = 'http://193.34.144.96:9096'; // development

if (process.env.NODE_ENV !== 'development') { // production
    domain = 'https://www.adsil1.com/Office';
}
const apiURL = domain + '/api/v1';

export async function validateAuthToken(token, callback) {
    if (token) {
        const reqUrl = apiURL + '/security/authentication';
        const requestOptions = {
            headers: { authorization: "Bearer " + token }
        };
        serverRequest(reqUrl, requestOptions, callback, token);
    }
}

export async function getAuthToken(email, password, callback) {
    const reqUrl = apiURL + '/security/authentication';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: password })
    };
    serverRequest(reqUrl, requestOptions, callback);
}

export async function getUsers(token, callback) {
    const reqUrl = apiURL + '/user/list';
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function postUser(token, user, callback) {
    const reqUrl = apiURL + '/user';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify({ user: user })
    };
    serverRequest(reqUrl, requestOptions, callback, token, user);
}

export async function putUser(token, user, callback) {
    const reqUrl = apiURL + '/user';
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify({ user: user })
    };
    serverRequest(reqUrl, requestOptions, callback, token, user);
}


export async function getClients(token, callback) {
    const reqUrl = apiURL + '/client/list';
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function postClient(token, client, callback) {
    const reqUrl = apiURL + '/client';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify({ client: client })
    };
    serverRequest(reqUrl, requestOptions, callback, token, client);
}

export async function getCampaigns(token, callback) {
    const reqUrl = apiURL + '/campaign/list';
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function postCampaign(token, campaign, callback) {
    const reqUrl = apiURL + '/campaign';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify({ campaign: campaign })
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function postCreative(token, creative, callback) {
    // console.log(creative);
    const reqUrl = apiURL + '/creative';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify({ creative: creative })
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function getCreative(token, id, callback) {
    const reqUrl = apiURL + '/creative/' + id;
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function deleteCreative(token, id, callback) {
    const reqUrl = apiURL + '/creative/' + id;
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function getCreativeList(token, callback) {
    const reqUrl = apiURL + '/creative/list';
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function exportReports(token, requestData, callback) {
    const reqUrl = apiURL + '/creative/export';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify(requestData)
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function resizeMedia(requestData, size, signal) {
    var formdata = new FormData();
    formdata.append('file', requestData);
    const reqUrl = `https://coral-app-htq38.ondigitalocean.app/api/file/compress?width=${size.width}&height=${size.height}&compression=${size.compression || '28'}`;
    const requestOptions = {
        method: 'POST',
        headers: { 'challenge': 'fJM9HD06W4tWTKSTJCxScxGNQYmBsET9', 'Access-Control-Allow-Headers': '*' },
        body: formdata,
        signal: signal,
    };

    try {
        const response = await fetch(reqUrl, requestOptions);
        // console.log(response.headers.get('content-length'));
        const blob = await response.blob();
        const fileSize = blob.size / 1024 / 1024;
        const result = await convertToBase64(blob)
        return { base64File: result, fileSize };
    } catch (error) {
        console.log(error)
    };
}

function convertToBase64(blob) {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(reader.result);
            };
        } catch (error) {
            reject(error);
        }
    });
}

async function serverRequest(url, options, callback, token = false, client) {
    await fetch(url, options)
        .then((response) => {
            if (response.ok) return response.json();
            // console.log(response)
            throw new Error('Something went wrong');
        })
        .then((responseJson) => {
            // console.log(responseJson)
            if (token) responseJson.token = token;
            if (client) {
                callback(responseJson, client);
            } else {
                callback(responseJson);
            }
        })
        .catch((error, e, a, b) => {
            console.log(error)
            callback({ error: 4, message: 'Something went wrong' });
        });
}




