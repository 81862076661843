import './style.scss';
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

// import placeholder from '../utils/images/avatar_box_placeholder.png';
import videoImages from "../utils/images/video_buttons.json";

// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';
import { VIDEO } from '../../constants/imageTypes';

export default function MobileBannerVideo(props) {
    const videoRef = useRef(null);

    const [playState, setPlayState] = useState(true);
    const [muteState, setMuteState] = useState(true);

    // const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);
    const bgColor = useSelector(state => state.creative.backgroundColor);
    const assets = useSelector(state => state.creative.assets);
    const mute = useSelector(state => state.creative.mute);
    const play = useSelector(state => state.creative.play);
    // const redirectionUrl = useSelector(state => state.creative.redirectionUrl);
    const darkMode = useSelector(state => state.creative.darkMode);
    const buttonsPosition = useSelector(state => state.creative.buttonsPosition);

    const background = (assets && assets.length > 0 && assets.find(asset => asset.type.toLowerCase() === "background_image")) || null;
    const topImage = (assets && assets.length > 0 && assets.find(asset => asset.type.toLowerCase() === "top_image")) || null;
    const video = (assets && assets.length > 0 && assets.find(asset => asset.type.toLowerCase() === VIDEO)) || null;
    const bottomImage = (assets && assets.length > 0 && assets.find(asset => asset.type.toLowerCase() === "bottom_image")) || null;
    const buttonImage = (assets && assets.length > 0 && assets.find(asset => asset.type.toLowerCase() === "button_image")) || null;

    const pauseBtn = darkMode ? videoImages.black.pause : videoImages.white.pause;
    const playBtn = darkMode ? videoImages.black.play : videoImages.white.play;
    const muteBtn = darkMode ? videoImages.black.mute : videoImages.white.mute;
    const unmuteBtn = darkMode ? videoImages.black.unmute : videoImages.white.unmute;

    const bannerRatio = 0.5;

    const bannerStyle = {
        backgroundColor: bgColor,
        minHeight: height * 0.2,
        minWidth: height * 0.2 * bannerRatio,
        width: '100%',
        height: '100%',
        borderRadius: '1.5vw',
    };
    const videoStyle = {
        width: '100%',
        objectFit: "contain",
    };

    const isVideoPlaying = video => !!(video?.currentTime > 0 && !video?.paused && !video?.ended && video?.readyState > 2);

    const toggleVideoSound = () => {
        if (videoRef.current.muted) {
            setMuteState(false);
        } else {
            setMuteState(true);
        }
    };

    const toggleVideoPlay = () => {
        if (isVideoPlaying(videoRef.current)) {
            videoRef.current.pause();
            setPlayState(false);
        } else {
            videoRef.current.play();
            setPlayState(true);
        }
    };

    const empty = "";

    return (
        // <div className="banner-display-container grid-content">
        <div 
            className="banner-display-container interstitial-border" 
            style={{ 
                // backgroundImage: `url(${IphoneFrameImage})`, 
                width: '13vw', 
                height: '26vw',
                backgroundColor: 'white',
                borderRadius: '2vw',
            }}
        >
            <img className='banner-display-frame-image' alt='' /> {/* src={IphoneFrameFrontCameraImage} /> */}
            <div className="banner-box mobile-banner-video-container" style={{ backgroundImage: background.url !== "" ? `url(${background.url})` : "" }}>
                <div>
                    <div className="top-image" style={{ backgroundImage: topImage.url !== "" ? `url(${topImage.url})` : "none" }} alt="topImage"></div>
                    <div className='mobile-banner-video-video-container'>
                        <div id="ds_div_buttons" className='mobile-banner-video' style={{ width: bannerStyle.width, bottom: buttonsPosition === 'Top' ? '75%' : buttonsPosition === 'Center' ? '45%' : '5%' }}>
                            <img className={`ds_buttons left ${play ? '' : 'd-none'}`} id="sa_play_video" alt="play button" src={playState ? pauseBtn : playBtn} onClick={toggleVideoPlay} />
                            <img className={`ds_buttons right ${mute ? '' : 'd-none'}`} id="sa_play_sound" alt="sound button" src={muteState ? muteBtn : unmuteBtn} onClick={toggleVideoSound} />
                        </div>
                        <video className='mobile-banner-video-video' autoPlay={true} muted={muteState} loop ref={videoRef} src={video?.url} style={videoStyle}>
                            <source type="video/mp4" />
                        </video>
                    </div>
                    <div className="bottom-image" style={{ backgroundImage: bottomImage.url !== "" ? `url(${bottomImage.url})` : "none" }} ></div>
                </div>
                <a className="button-image" style={{ backgroundImage: buttonImage.url !== "" ? `url(${buttonImage.url})` : "none" }}>{empty}</a>
            </div>
        </div>
    )
}

