import * as utils from '../../utils';
import * as actionTypes from '../../store/action';
import {
    // CUSTOM_BANNER,
    INTERSTITIAL,
    HALF_HALF,
    INLINE,
    VIDEO_INTERSTITIAL,
    GALLERY,
    MOBILE_BANNER_VIDEO,
    FLIPPER,
    SCRATCH,
    BOUNCE_GAME,
    VIDEO_TIMER_BANNER,
    DESKTOP_TIMER_BANNER,
    VIDEO_GALLERY,
    COLLECT_OR_EVADE_GAME,
    PROGRAM_VIDEO,
    HALF_VIDEO,
    UNIVERSAL
} from '../utils/options/creativeTypes';

const creativeTypes = [
    INTERSTITIAL,
    HALF_HALF,
    INLINE,
    VIDEO_INTERSTITIAL,
    GALLERY,
    MOBILE_BANNER_VIDEO,
    FLIPPER,
    SCRATCH,
    BOUNCE_GAME,
    VIDEO_TIMER_BANNER,
    DESKTOP_TIMER_BANNER,
    VIDEO_GALLERY,
    COLLECT_OR_EVADE_GAME,
    PROGRAM_VIDEO,
    HALF_VIDEO,
    UNIVERSAL
];

function getCustomizedItems(type, items) {
    let texts = [], images = [], shapes = [], assets = [];

    if (creativeTypes.includes(type)) {
        for (const item of items) {
            assets.push({
                id: item.id,
                url: item.imageContent,
                width: item.width,
                height: item.height,
                maxSize: 204800,
                type: item.type.toLowerCase(),
                side: item.type.toLowerCase(),
                order: item.order,
                score: item.score,
                redirectionUrl: item.redirectionUrl,
            });
        }
    } else {
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            let extractedId = utils.extractUiId(item.className);
            item.uiId = extractedId;
            item.className = null;
            item.inFocus = false;
            item.animationType = item.animationType.toLowerCase();
            item.iterationType = item.iterationType.toLowerCase();
            if (item.animationType !== 'none') {
                item.className = utils.getAnimationClassName(extractedId, item.animationType, item.iterationType, item.animationDelay, item.animationDuration);
            }
            //add to all items? shapeType borderRadius
            switch (item.type.toLowerCase()) {
                case "image":
                    item.image = item.imageContent;
                    images.push(item);
                    break;
                case "text":
                    texts.push(item);
                    break;
                case "shape":
                    item.image = item.imageContent;
                    shapes.push(item);
                    break;
                default:
            }
        }
    }

    return { texts: texts, images: images, shapes: shapes, assets: assets };
}

function formatPresetItems(data) {
    let item = Object.assign({}, data);
    const uiId = utils.generateId();
    item.uiId = uiId;
    item.className = null;
    item.inFocus = false;
    item.animationType = item.animationType.toLowerCase();
    item.iterationType = 'default';
    item.opacity = 1;
    item.index = 0;
    if (item.animationType !== 'none') {
        item.className = utils.getAnimationClassName(uiId, item.animationType, item.iterationType, item.animationDelay, item.animationDuration);
    }
    if (item.type.toLowerCase() === 'image') {
        item.image = item.value;
        delete item.fontSize;
    }
    if (item.type.toLowerCase() === 'text') {
        item.text = item.value;
        item.fontType = item.fontFamily;
        item.color = item.fontColor;
        delete item.fontColor;
        delete item.fontFamily;
    }
    delete item.title;
    delete item.value;

    return item;
}

export function downloadBannerAsPNG() {
    utils.printDocument();
}

export function handleFieldInit(dispatch, creative, isNew) {
    if (isNew) {
        dispatch({ type: actionTypes.CLEAR_CREATIVE_FIELDS });
        dispatch({ type: actionTypes.DELETE_ALL_IMAGES });
        dispatch({ type: actionTypes.DELETE_ALL_TEXTS });
        dispatch({ type: actionTypes.DELETE_ALL_SHAPES });
        return;
    }

    if (!creative) return;
    const items = getCustomizedItems(creative.type, creative.items);

    if (creativeTypes.includes(creative.type)) {
        creative.assets = items.assets;
        handleCreativeFieldInit(dispatch, creative);
    } else {
        handleBannerFieldInit(dispatch, creative);
    }

    dispatch({ type: actionTypes.ADD_ALL_IMAGES, images: items.images });
    dispatch({ type: actionTypes.ADD_ALL_TEXTS, texts: items.texts });
    dispatch({ type: actionTypes.ADD_ALL_SHAPES, shapes: items.shapes });
}

function handleBannerFieldInit(dispatch, data) {
    const fields = {
        id: data.id,
        name: data.name,
        bannerHeight: data.height,
        bannerWidth: data.width,
        creativeType: data.type,
        campaignId: data.campaignId,
        backgroundColor: data.backgroundColor,
        backgroundImage: data.backgroundImage,
        backgroundVideo: data.backgroundVideo,
        redirectionUrl: data.redirectionUrl
    };
    dispatch({ type: actionTypes.SET_TYPE_FIELDS, fields: fields });
}

function handleCreativeFieldInit(dispatch, data) {
    const fields = {
        id: data.id,
        campaignId: data.campaignId,
        name: data.name,
        bannerHeight: data.height,
        bannerWidth: data.width,
        creativeType: data.type,
        backgroundVideo: data.backgroundVideo,
        backgroundColor: data.backgroundColor,
        backgroundImage: data.backgroundImage,
        backgroundImageName: data.backgroundImageName,
        redirectionUrl: data.redirectionUrl,
        font: data.font,
        textColor: data.textColor,
        timerEndDate: data.timerEndDate,
        assets: data.assets,
        mute: data.mute ? data.mute : false,
        play: data.play ? data.play : false,
        darkMode: data.darkMode ? data.darkMode : false,
        buttonsPosition: data.buttonsPosition && data.buttonsPosition !== '' ? data.buttonsPosition : 'bottom',
        autoClose: data.autoClose === true,
        autoCloseTimer: data.autoCloseTimer ? data.autoCloseTimer : 10000,
        showTimer: data.showTimer ? data.showTimer : false,
        scratchComplete: data.scratchComplete ? data.scratchComplete : 15,
        compression: data.compression ? data.compression : 28,
        textBackgroundColor: data.textBackgroundColor,
        gameDuration: data.gameDuration ? data.gameDuration : 30000,
        speedInterval: data.speedInterval ? data.speedInterval : 3,
        increaseSpeedBy: data.increaseSpeedBy ? data.increaseSpeedBy : 0.75,
        gameBaseSpeed: data.gameBaseSpeed ? data.gameBaseSpeed : 1800,
        collectOrEvade: data.collectOrEvade,
        enableGameScore: data.enableGameScore ? data.enableGameScore : false,
        enableGameLives: data.enableGameLives ? data.enableGameLives : false,
        impressionTag: data.impressionTag,
        impressionClickTag: data.impressionClickTag,
        showVideo: data.showVideo ? data.showVideo : false,
        videoOnly: data.videoOnly ? data.videoOnly : false,
        timerPosition: data.timerPosition && data.timerPosition !== '' ? data.timerPosition : 'center',
        programVideo: data.programVideo ? { ...data.programVideo } : { gridColumnsNum: 7, gridRowNum: 5, breakPoints: [] },
    };

    dispatch({ type: actionTypes.SET_TYPE_FIELDS, fields: fields });
}

export function handlePresetInit(dispatch, data) {
    const images = [], texts = [];
    for (let i = 0; i < data.length; i++) {
        const dataVal = data[i];
        if (dataVal.className === 'bg1') {
            dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_COLOR, value: null });
            dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_VIDEO, value: null });
            dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_IMAGE, value: dataVal.value, name: dataVal.name })
            continue;
        }
        const item = formatPresetItems(dataVal);
        if (item.type.toLowerCase() === 'image') images.push(item);
        if (item.type.toLowerCase() === 'text') texts.push(item);
    }

    dispatch({ type: actionTypes.ADD_ALL_IMAGES, images: images });
    dispatch({ type: actionTypes.ADD_ALL_TEXTS, texts: texts });
    //dispatch({type: actionTypes.ADD_ALL_SHAPES, shapes: items.shapes});
}

export function handleBannerName(dispatch, name) {
    dispatch({ type: actionTypes.UPDATE_BANNER_NAME, name: name });
}

export function handleBannerSize(dispatch, width, height) {
    dispatch({ type: actionTypes.UPDATE_BANNER_SIZE, width: width, height: height });
}

export function handleAddAsset(dispatch, width, height, assetType) {
    dispatch({ type: actionTypes.ADD_ASSET , width: width, height: height, assetType: assetType });
}

export function handleDeleteAsset(dispatch, uiId) {
    dispatch({ type: actionTypes.DELETE_ASSET , uiId: uiId });
}

export function handleEditAsset(dispatch, width, height, uiId) {
    dispatch({ type: actionTypes.UPDATE_ASSET, width: width, height: height, uiId: uiId });
}

export function handleAssetSize(dispatch, width, height, order) {
    dispatch({ type: actionTypes.UPDATE_ASSET_SIZE, width: width, height: height, order: order });
}

export async function handleBannerBgImage(dispatch, e) {
    let image = await utils.processImage(e);
    dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_COLOR, value: null });
    dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_VIDEO, value: null });
    dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_IMAGE, value: image.image, name: image.name });
}

export function handleBannerBgVideo(dispatch, value) {
    dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_IMAGE, value: null, name: null });
    dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_COLOR, value: null });
    dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_VIDEO, value: value });
}

export function handleBannerBgColor(dispatch, color) {
    dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_IMAGE, value: null, name: null });
    dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_VIDEO, value: null });
    dispatch({ type: actionTypes.UPDATE_BANNER_BACKGROUND_COLOR, value: color.hex });
}

export function handleShowColorPicker(dispatch, isShow) {
    dispatch({ type: actionTypes.SET_SHOW_BANNER_COLOR_PICKER, value: isShow });
}

export function handleShowMuteBtn(dispatch, isShow) {
    dispatch({ type: actionTypes.SET_MUTE_BTN, value: isShow });
}

export function handleVideoOnly(dispatch, videoOnly) {
    dispatch({ type: actionTypes.SET_VIDEO_ONLY, value: videoOnly });
}

export function handlePlaceTimer(dispatch, timerPosition) {
    dispatch({ type: actionTypes.SET_TIMER_POSITION, value: timerPosition });
}

export function handleShowPlayBtn(dispatch, isShow) {
    dispatch({ type: actionTypes.SET_PLAY_BTN, value: isShow });
}

export function handlePlayVideo(dispatch, playVideo) {
    dispatch({ type: actionTypes.SET_PLAY_VIDEO, value: playVideo });
}

export function handleShowBlackBtn(dispatch, isShow) {
    dispatch({ type: actionTypes.SET_BLACK_BTN, value: isShow });
}

export function handlePlaceBtns(dispatch, buttonsPosition) {
    dispatch({ type: actionTypes.SET_BTNS_POSITION, value: buttonsPosition });
}

export function handleAutoClose(dispatch, autoClose) {
    dispatch({ type: actionTypes.SET_AUTO_CLOSE, value: autoClose });
}

export function handleAutoCloseTimer(dispatch, autoCloseTimer) {
    dispatch({ type: actionTypes.SET_AUTO_CLOSE_TIMER, value: autoCloseTimer });
}

export function handleShowTimer(dispatch, showTimer) {
    dispatch({ type: actionTypes.SET_SHOW_TIMER, value: showTimer });
}

export function handleShowVideo(dispatch, showVideo) {
    dispatch({ type: actionTypes.SET_SHOW_VIDEO, value: showVideo });
}

export function handleScratchComplete(dispatch, scratchComplete) {
    dispatch({ type: actionTypes.SET_SCRATCH_COMPLETE, value: scratchComplete });
}

export function handleCompression(dispatch, compression) {
    dispatch({ type: actionTypes.SET_COMPRESSION, value: compression });
}

export function handleOpenImageSettings(dispatch, openVideoSettings) {
    dispatch({ type: actionTypes.SET_OPEN_IMAGE_SETTINGS, value: openVideoSettings });
}

export function handleOpenVideoSettings(dispatch, openVideoSettings) {
    dispatch({ type: actionTypes.SET_OPEN_VIDEO_SETTINGS, value: openVideoSettings });
}

export function handleImageCompression(dispatch, imageCompression) {
    dispatch({ type: actionTypes.SET_IMAGE_COMPRESSION, value: imageCompression });
}

export function handleTextBackgroundColor(dispatch, textBackgroundColor) {
    dispatch({ type: actionTypes.SET_TEXT_BACKGROUND_COLOR, value: textBackgroundColor });
}

export function handleBackgroundColor(dispatch, backgroundColor) {
    dispatch({ type: actionTypes.SET_BACKGROUND_COLOR, value: backgroundColor });
}

export function handleGameDuration(dispatch, gameDuration) {
    dispatch({ type: actionTypes.SET_GAME_DURATION, value: gameDuration });
}

export function handleSpeedInterval(dispatch, speedInterval) {
    dispatch({ type: actionTypes.SET_SPEED_INTERVAL, value: speedInterval });
}

export function handleIncreaseSpeedBy(dispatch, increaseSpeedBy) {
    dispatch({ type: actionTypes.SET_INCREASE_SPEED_BY, value: increaseSpeedBy });
}

export function handleGameBaseSpeed(dispatch, gameBaseSpeed) {
    dispatch({ type: actionTypes.SET_GAME_BASE_SPEED, value: gameBaseSpeed });
}

export function handleCollectOrEvade(dispatch, collectOrEvade) {
    dispatch({ type: actionTypes.SET_COLLECT_OR_EVADE, value: collectOrEvade });
}

export function handleEnableGameScore(dispatch, enableGameScore) {
    dispatch({ type: actionTypes.SET_ENABLE_GAME_SCORE, value: enableGameScore });
}

export function handleEnableGameLives(dispatch, enableGameLives) {
    dispatch({ type: actionTypes.SET_ENABLE_GAME_LIVES, value: enableGameLives });
}

export function handleBallScore(dispatch, itemId, asset) {
    dispatch({ type: actionTypes.SET_ASSET_SCORE, uiId: itemId, value: asset });
}

export function handleCurrentVideos(dispatch, currentVideos) {
    dispatch({ type: actionTypes.SET_CURRENT_VIDEOS, value: currentVideos });
}

export function handleBannerRedirectionUrl(dispatch, url) {
    dispatch({ type: actionTypes.UPDATE_BANNER_REDIRECTION_URL, value: url });
}

export function handleImpressionTag(dispatch, impressionTag) {
    dispatch({ type: actionTypes.SET_IMPRESSION_TAG, value: impressionTag });
}

export function handleImpressionClickTag(dispatch, impressionClickTag) {
    dispatch({ type: actionTypes.SET_IMPRESSION_CLICK_TAG, value: impressionClickTag });
}

export function handleGridRowsNum(dispatch, gridRowsNum) {
    dispatch({ type: actionTypes.SET_GRID_ROWS_NUMBER, value: gridRowsNum });
}

export function handleGridColumnsNum(dispatch, gridColumnsNum) {
    dispatch({ type: actionTypes.SET_GRID_COLUMNS_NUMBER, value: gridColumnsNum });
}

export function handleBreakPoint(dispatch, breakpoint) {
    dispatch({ type: actionTypes.ADD_NEW_BREAK_POINT, value: breakpoint });
}

export function handleEditBreakPoint(dispatch, breakpoint) {
    dispatch({ type: actionTypes.SET_BREAK_POINT, value: breakpoint });
}

export function handleDeleteBreakPoint(dispatch, breakpoint) {
    dispatch({ type: actionTypes.DELETE_BREAK_POINT, value: breakpoint });
}

export function handleBannerFont(dispatch, url) {
    dispatch({ type: actionTypes.UPDATE_BANNER_FONT, value: url });
}

export function handleBannerTextColor(dispatch, url) {
    dispatch({ type: actionTypes.UPDATE_BANNER_TEXT_COLOR, value: url });
}

export function handleBannerTimerEndDate(dispatch, date) {
    dispatch({ type: actionTypes.UPDATE_BANNER_TIMER_END_DATE, value: date });
}

export async function handleInterstitialImage(dispatch, itemId, value, type) {
    if (type === GALLERY || type === VIDEO_GALLERY) {
        dispatch({ type: actionTypes.ADD_IMAGE, uiId: itemId, value: value });
    }
    dispatch({ type: actionTypes.SET_INTERSTITIAL_IMAGE, uiId: itemId, value: value });
}

export async function handleInlineVideo(dispatch, itemId, value) {
    dispatch({ type: actionTypes.SET_INLINE_VIDEO, uiId: itemId, value });
}
