import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import placeholder from "../utils/images/avatar_box_placeholder.png";
import videoImages from "../utils/images/video_buttons.json";
import DesktopFrameImage from '../utils/images/creative_images/Desktop.png';
import { VIDEO } from "../../constants/imageTypes";

const stringToJson = (value) => {
  return value && value !== '' ? JSON.parse(value) : '';
}

export default function DesktopTimerBanner(props) {
  const videoRef = useRef(null);

  const [playState, setPlayState] = useState(true);
  const [muteState, setMuteState] = useState(true);

  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  const width = useSelector((state) => state.creative.bannerWidth);
  const height = useSelector((state) => state.creative.bannerHeight);
  const bgColor = useSelector((state) => state.creative.backgroundColor);
  const assets = useSelector((state) => state.creative.assets);
  const mute = useSelector((state) => state.creative.mute);
  const play = useSelector((state) => state.creative.play);
  const font = useSelector((state) => state.creative.font);
  const textColor = useSelector((state) => state.creative.textColor);
  const darkMode = useSelector((state) => state.creative.darkMode);
  const showTimer = useSelector((state) => state.creative.showTimer);
  const videoOnly = useSelector((state) => state.creative.videoOnly);
  const showVideo = useSelector((state) => state.creative.showVideo);
  const timerPosition = useSelector((state) => state.creative.timerPosition);
  const buttonsPosition = useSelector((state) => state.creative.buttonsPosition);

  const [creativeFont, setCreativeFont] = useState(stringToJson(font));

  const background = assets.find((a) => a.type.toLowerCase() === "background_image");
  const video = assets.find((a) => a.type.toLowerCase() === VIDEO);

  const pauseBtn = darkMode ? videoImages.black.pause : videoImages.white.pause;
  const playBtn = darkMode ? videoImages.black.play : videoImages.white.play;
  const muteBtn = darkMode ? videoImages.black.mute : videoImages.white.mute;
  const unmuteBtn = darkMode ? videoImages.black.unmute : videoImages.white.unmute;

  const bannerRatio = width / height;
  const backgroundBottomRatio = 3933 / 3188;
  const backgroundTopRatio = 155 / 3188;

  const bannerStyle = {
    backgroundColor: bgColor,
    height: height * 0.4,
    width: height * 0.4 * bannerRatio
  };
  const videoStyle = {
    width: videoOnly ? '100%' : bannerStyle.width * 0.51,
    height: videoOnly ? '100%' : bannerStyle.height * 0.89,
    objectFit: "fill",
    display: showVideo ? 'block' : 'none'
  };

  const videoContainerStyle = videoOnly ? {

  } : {
    left: '2.5%',
    top: '5%'
  }

  const isVideoPlaying = (video) =>
    !!(
      video?.currentTime > 0 &&
      !video?.paused &&
      !video?.ended &&
      video?.readyState > 2
    );

  const toggleVideoSound = () => {
    if (videoRef.current.muted) {
      setMuteState(false);
    } else {
      setMuteState(true);
    }
  };

  const toggleVideoPlay = () => {
    if (isVideoPlaying(videoRef.current)) {
      videoRef.current.pause();
      setPlayState(false);
    } else {
      videoRef.current.play();
      setPlayState(true);
    }
  };

  function checkDate() {
    const now = new Date();
    const END_DATE = new Date(now.setDate(now.getDate() + 7));

    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;

    const remainingTime = END_DATE - now;
    if (remainingTime <= 0) {
      setHours('00');
      setMinutes('00');
      setSeconds('00');
      return;
    }

    const remainingTimeInHours = Math.floor(remainingTime / _hour);
    const remainingTimeInMinutes = Math.floor((remainingTime % _hour) / _minute);
    const remainingTimeInSeconds = Math.floor((remainingTime % _minute) / _second);

    setHours(remainingTimeInHours < 10 ? `0${remainingTimeInHours}` : remainingTimeInHours);
    setMinutes(remainingTimeInMinutes < 10 ? `0${remainingTimeInMinutes}` : remainingTimeInMinutes);
    setSeconds(remainingTimeInSeconds < 10 ? `0${remainingTimeInSeconds}` : remainingTimeInSeconds);
  }

  useEffect(() => {
    checkDate();
  }, []);

  useEffect(() => {
    setCreativeFont(stringToJson(font));
  }, [font]);

  useEffect(() => {
    const newStyle = document.createElement('style');
    newStyle.appendChild(document.createTextNode(`\
            @font-face {\
                font-family: ${creativeFont?.family};\
                src: url('"${creativeFont?.files?.regular}"');\
            }\
            `));

    document.head.appendChild(newStyle);
  }, [creativeFont]);

  const getPositionStyle = () => {
  switch (timerPosition) {
    case 'Left':
      return (width === 970 && height === 330) ? {
        left: bannerStyle.width * 0.1,
        top: bannerStyle.height * 0.75,
        fontSize: bannerStyle.width / 20,
      } : {
        left: bannerStyle.width * 0.1,
        fontSize: bannerStyle.width / 10,
        top: bannerStyle.height * 0.45,
      };
    case 'Right':
      return (width === 970 && height === 330) ? {
        right: bannerStyle.width * 0.09,
        top: bannerStyle.height * 0.75,
        fontSize: bannerStyle.width / 20,
      } : {
        right: bannerStyle.width * 0.1,
        top: bannerStyle.height * 0.75,
        fontSize: bannerStyle.width / 20,
      };
    case 'Center':
      return (width === 970 && height === 330) ? {
        left: '50%',
        transform: 'translate(-50%, 0)',
        top: bannerStyle.height * 0.75,
        fontSize: bannerStyle.width / 20,
      } : {
        left: '50%',
        transform: 'translate(-50%, 0)',
        top: bannerStyle.height * 0.8,
        fontSize: bannerStyle.width / 15,
      };
    default:
      return {};
  }
};

  // const timeTextRightStyle = {
  //   width: bannerStyle.width * 0.25,
  //   top: bannerStyle.height * 0.75,
  //   right: bannerStyle.width * 0.1,
  //   fontSize: bannerStyle.width / 20,
  // };
  
  // const timeTextCenterStyle = {
  //   width: bannerStyle.width * 0.25,
  //   top: bannerStyle.height * 0.75,
  //   right: bannerStyle.width * 0.1,
  //   fontSize: bannerStyle.width / 20,
  // };
  
  // const timeTextLeftStyle = width === 970 && height === 330 ? {
  //   width: bannerStyle.width * 0.25,
  //   top: bannerStyle.height * 0.75,
  //   left: bannerStyle.width * 0.1,
  //   fontSize: bannerStyle.width / 20,
  // } : width === 970 && height === 540 ? {
  //   top: bannerStyle.height * 0.75,
  //   left: bannerStyle.width * 0.1,
  //   fontSize: bannerStyle.width / 10,
  // } : {
  //   top: bannerStyle.height * 0.75,
  //   left: bannerStyle.width * 0.1,
  //   fontSize: bannerStyle.width / 10,
  // };

  const timerTextStyle = {
    display: background?.url !== placeholder ? 'flex' : 'none',
    color: textColor || 'black',
    fontFamily: creativeFont ? `${creativeFont?.family}, ${creativeFont?.category}` : '',
    position: "absolute",
    ...getPositionStyle()
  };

  let desktopImageSizeRatio = 1;
  if(width === 970 && height === 330) {
    desktopImageSizeRatio = 1.1;
  } else if (width === 970 && height === 540) {
    desktopImageSizeRatio = 0.674;
  }

  return (
    <div className="banner-display-container grid-content" style={{ backgroundImage: `url(${DesktopFrameImage})`, backgroundSize: 'contain', width: height * backgroundBottomRatio * desktopImageSizeRatio ,height: height * desktopImageSizeRatio, alignItems: 'baseline', paddingTop: `${height * desktopImageSizeRatio * backgroundTopRatio}px` }}>
      <div
        className="banner-box video-timer-banner-container"
        style={{
          width: bannerStyle.width,
          height: bannerStyle.height,
          borderRadius: 0,
          backgroundImage: background?.url !== placeholder ? `url(${background.url})` : "",
        }}
      >
        <div className='desktop-timer-banner-video-container' style={videoContainerStyle}>
          <div id="ds_div_buttons" className='desktop-timer-banner-btns-container' style={{ display: showVideo ? 'block' : 'none', left: 0, bottom: buttonsPosition === 'Top' ? '75%' : buttonsPosition === 'Center' ? '40%' : '5%' }}>
            <img className={`ds_buttons left ${play ? '' : 'd-none'}`} id="sa_play_video" alt="play button" src={playState ? pauseBtn : playBtn} onClick={toggleVideoPlay} />
            <img className={`ds_buttons right ${mute ? '' : 'd-none'}`} id="sa_play_sound" alt="sound button" src={muteState ? muteBtn : unmuteBtn} onClick={toggleVideoSound} />
          </div>
          <video className='desktop-timer-banner-video' autoPlay={true} muted={muteState} loop ref={videoRef} src={video?.url} style={videoStyle}>
            <source type="video/mp4" />
          </video>
        </div>
        {showTimer && (
          <div className="desktop-timer-banner-timer-container" style={{...timerTextStyle}}>
            <p className="desktop-timer-banner-timer">
              <span name="hours">{hours}</span>:
              <span name="minutes">{minutes}</span>:
              <span name="seconds">{seconds}</span>
            </p>
            {/* <div className="desktop-timer-banner-timer-text-container" style={{ fontSize: bannerStyle.width / 60, gap: bannerStyle.width * 0.045 }}>
              <span>שעות</span>
              <span>דקות</span>
              <span>שניות</span>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
