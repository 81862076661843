import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Button from "../Input/Button";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  proceedBtn: {
    padding: '0.083vw 0.833vw',
  },
  fontFamily: {
    fontFamily: 'Lexend',
  },
}));

const DeleteCreativeDialog = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      onKeyDown={props.onKeyDown}
      onBackdropClick={props.onClose}
      open={true}
      PaperProps={{
        sx: {
          maxWidth: "unset",
          maxHeight: "unset",
        },
      }}
    >
      <DialogTitle variant="h5" sx={{ m: 0, p: 2, fontSize: '1.25vw' }}>
        Delete Creative
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ display: "flex", flexDirection: "column", gap: "1vw" }}
      >
        <Typography variant="h6" sx={{ fontSize: '1.042vw' }}>
          Are you sure you want to delete this creative?
        </Typography>
        <Typography variant="h7" sx={{ fontSize: '0.98vw' }}>
          <b>
            This action isn't reversable creative will be deleted permanently!
          </b>
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ gap: "2vw", justifyContent: "space-between", padding: "8px 1vw" }}
      >
        <Button 
          autoFocus 
          onClick={props.handleDelete} 
          variant="contained"
          className={`${classes.fontFamily} ${classes.proceedBtn}`}
        >
          Confirm
        </Button>
        <Button 
          onClick={props.onClose} 
          variant="text"
          className={classes.fontFamily}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCreativeDialog;
