import React, { useEffect, useState } from "react";
import { handleEditAsset } from "../CreativeTemplates/handlers";
import { useDispatch } from "react-redux";

const AssetUpdateInput = (props) => {
  const [selectedAssetWidth, setSelectedAssetWidth] = useState(props.asset.width);
  const [selectedAssetHeight, setSelectedAssetHeight] = useState(props.asset.height);

  const [focus1, setFocus1] = useState(false);
  const [focus2, setFocus2] = useState(false);
  const [focusedInput, setFocusedInput] = useState(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    if(!focus1 && !focus2 && focusedInput === null) {
      if(props.asset.height !== selectedAssetHeight || props.asset.width !== selectedAssetWidth) {
        handleEditAsset(dispatch, +selectedAssetWidth, +selectedAssetHeight, props.asset.uiId);
      }
      props.onClose()
    }
  }, [focus1, focus2, focusedInput, selectedAssetWidth, selectedAssetHeight, props.asset, dispatch, props.onClose]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        backgroundColor: props.asset.url !== "" ? "#2E3C8D" : "transparent",
      }}
    >
      <input
        className="title"
        style={{
          padding: "0.05vw 0",
          color: "white",
          border: 0,
          outline: 0,
          backgroundColor: "transparent",
          textAlign: 'right',
        }}
        type="number"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleEditAsset(dispatch, +selectedAssetWidth, +selectedAssetHeight, props.asset.uiId);
            props.onClose();
          }
          if (event.key === "Escape") {
            props.onClose();
            return;
          }
          return;
        }}
        value={selectedAssetWidth}
        onChange={(event) => {
          const newValue = event.target.value.replace(/[^0-9]/g, "");
          setSelectedAssetWidth(newValue);
        }}
        onBlur={() => {
          if(focusedInput === 'height') {
            setFocusedInput(null);
          }
          setFocus2(false);
        }}
        onFocus={() => {
          setFocusedInput('width');
          setFocus2(true);
        }}
      />
      <span
        className="title"
        style={{
          color: "white",
          lineHeight: 1,
          fontSize: "0.65vw",
          padding: "0.05vw 0",
          width: "unset",
          height: "100%",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        x
      </span>
      <input
        className="title"
        style={{
          padding: "0.05vw 0",
          color: "white",
          border: 0,
          outline: 0,
          backgroundColor: "transparent",
          textAlign: 'left',
        }}
        type="number"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleEditAsset(dispatch, +selectedAssetWidth, +selectedAssetHeight, props.asset.uiId);
            props.onClose();
          }
          if (event.key === "Escape") {
            props.onClose();
            return;
          }
          return;
        }}
        value={selectedAssetHeight}
        onChange={(event) => {
          const newValue = event.target.value.replace(/[^0-9]/g, "");
          setSelectedAssetHeight(newValue);
        }}
        onBlur={() => {
          if(focusedInput === 'width') {
            setFocusedInput(null);
          }
          setFocus1(false);
        }}
        onFocus={() => {
          setFocusedInput('height');
          setFocus1(true);
        }}
      />
    </div>
  );
};

export default AssetUpdateInput;
