import './style.scss';
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import MoveableImage from "../MoveableElements/MoveableImage";
import MoveableText from "../MoveableElements/MoveableText";
import MoveableShape from "../MoveableElements/MoveableShape";
import CreativeContextMenu from './CreativeContextMenu';

export default function Banner(props) {
    const outerRef = useRef();

    const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);


    const imageArr = useSelector(state => state.img.images);
    const textArr = useSelector(state => state.txt.texts);
    const shapeArr = useSelector(state => state.shape.shapes);

    const bgColor = useSelector(state => state.creative.backgroundColor);
    const bgImage = useSelector(state => state.creative.backgroundImage);
    const bgVideo = useSelector(state => state.creative.backgroundVideo);

    const videoStyle = {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0
    };

    const bannerStyle = {
        backgroundImage: `url(${bgImage})`,
        backgroundColor: bgColor,
        height: height * 0.45,//: height > window.innerHeight * 0.6 ? height * 0.8 : height > window.innerHeight * 0.5 ? height * 0.5 : height,
        width: width * 0.45,//: height > window.innerHeight * 0.6 ? width * 0.8 : height > window.innerHeight * 0.5 ? width * 0.5 : width,
    };

    //https://adsil1.com/fox/21_07/foxhome/live/videos/foxhome.mp4
    return (
        <div className="banner-display-container grid-content">
            <div className="banner-box box-shadow">
                <CreativeContextMenu parentRef={outerRef} images={imageArr} texts={textArr} shapes={shapeArr} />
                <div className="target" style={bannerStyle} ref={outerRef}>
                    {bgVideo && bgVideo !== '' ? <video src={bgVideo} preload="auto" autoPlay muted loop style={videoStyle}></video> : null}
                    {imageArr.map((img, index) => <MoveableImage {...img} key={index} parentRef={outerRef} />)}
                    {textArr.map((txt, index) => <MoveableText {...txt} key={index} parentRef={outerRef} />)}
                    {shapeArr.map((sh, index) => <MoveableShape {...sh} key={index} parentRef={outerRef} />)}
                </div>
            </div>
        </div>
    )
}

