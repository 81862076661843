import './style.scss';
import React from "react";
import { useSelector } from "react-redux";
// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';

export default function HalfAndHalf(props) {

    const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);
    const bgColor = useSelector(state => state.creative.backgroundColor);
    const assets = useSelector(state => state.creative.assets);

    const firstImage = (assets && assets.length > 0 && assets.find(asset => asset.width === width && asset.height === height && asset.type === 'left')?.url) || '';
    const secondImage = (assets && assets.length > 0 && assets.find(asset => asset.width === width && asset.height === height && asset.type === 'right')?.url) || '';

    const divider = useSelector(state => state.creative.divider);

    const bannerRatio = 0.5;

    const bannerStyle = {
        backgroundColor: bgColor,
        minHeight: height * 0.2,
        minWidth: height * 0.2 * bannerRatio,
        width: '100%',
        height: '100%',
        borderRadius: '1.5vw',
    };

    return (
        <div className="banner-display-container  interstitial-border" 
            style={{ 
                // backgroundImage: `url(${IphoneFrameImage})`, 
                width: '13vw', 
                height: '26vw',
                backgroundColor: 'white',
                borderRadius: '2vw',
            }}>
        {/* <div className="banner-display-container grid-content"> */}
            <img className='banner-display-frame-image' alt="" /> {/* src={IphoneFrameFrontCameraImage} /> */}
            <div className="banner-box">
                <div className="target" style={bannerStyle}>
                    <div className="left display-images" style={{ backgroundImage: `url(${firstImage})` }}></div>
                    <div className="right display-images" style={{ backgroundImage: `url(${secondImage})` }}></div>
                    <img className="divider" src={divider.url} alt="divider" />
                </div>
            </div>
        </div>
    )
}

