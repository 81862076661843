import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';

import placeholder from '../../Components/utils/images/avatar_box_placeholder.png';
import video_placeholder from '../../Components/utils/images/avatar_box_video_placeholder.png';
// import * as utils from '../../utils';

const validateMp4Video = (file) => !!file && file.type === "video/mp4";

function readVideo(file, onAddVideo, setError) {
  const reader = new FileReader();

  reader.onload = (e) => {
    onAddVideo(file, e.target.result, setError)
  }

  reader.readAsDataURL(file);
}

const VideoInput = ({ id, className, onAddVideo, poster, maxSize, image }) => {
  const [error, setError] = useState(null);
  const [video, setVideo] = useState();
  const fileInputRef = useRef(null);
  const labelRef = useRef(null);
  const classNames = className ? 'ds-inputs ds-image ' + className : 'ds-inputs ds-image';
  const inputId = 'dsImage_' + id;
  // const imgUrl = poster ? poster : placeholder;

  const handleOnChange = (e) => {
    const file = e.target.files[0];
    setVideo(e.target.files[0]);
    if (!validateMp4Video(file)) {
      toast.error(`Error: Not a valid mp4 file!`, { theme: "colored" });
      return
    }
    file.id = id;
    readVideo(file, onAddVideo, setError);
    labelRef.current.style.backgroundImage = "none";
  }
  const handleInputClick = () => {
    fileInputRef.current.click();
  }

  useEffect(() => {
    if (!error && (video || (image !== '' && image !== video_placeholder))) {
      labelRef.current.style.backgroundImage = error ? "none" : "url(https://static.block.org.il/wp-content/uploads/2018/04/13201822/v-green.png)";
    }
  }, [error, video, image]);

  
    const defaultImageStyles = {
        backgroundColor: "#9BD4F5", 
    };

    const placeholderStyles = {
        backgroundSize: '2.8vw',
        backgroundPosition: 'center center',
        backgroundImage: `url(${video_placeholder})`
    };

    const imageStyles = {
        backgroundSize: "cover", 
        backgroundPosition: "center center",
        backgroundImage: "url(https://static.block.org.il/wp-content/uploads/2018/04/13201822/v-green.png)"
    };

    const combinedStyles = image && image !== placeholder ? 
    { ...defaultImageStyles, ...imageStyles } : 
    { ...defaultImageStyles, ...placeholderStyles };


  return (
    <>
      <label 
        ref={labelRef} 
        htmlFor={inputId} 
        className={classNames} 
        style={combinedStyles} 
        onPointerDown={handleInputClick}
      >
        <input ref={fileInputRef} type="file" onInput={handleOnChange} multiple={false} accept='video/mp4' hidden />
      </label>
    </>
  )
}

export default VideoInput