import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import ClientGrid from '../Grid/ClientGrid';
import Button from '../Input/Button';
import NewClient from '../PopUp/NewClient';
import LoadingIndicator from '../Layout/LoadingIndicator';
import { getClients, getCampaigns, postClient, postCampaign } from '../../api_requests';
import { SET_CLIENTS, SET_CAMPAIGNS } from '../../store/action';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
//import { deleteCreative } from '../../api_requests';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '2.5vw 0',
        gap: '2vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'normal',
        alignItems: 'center',
    },
    action_bar: {
        width: '90%',
        display: 'flex',
        boxSizing: 'content-box',
        gap: '1vw',
        alignItems: 'center',
    },
    title: {
        fontWeight: 'bold', 
        fontSize: '1.8rem', 
        fontFamily: 'Lexend',
    },
    btn: {
        backgroundColor: '#35A7E0',
        boxShadow: 'none',
        fontSize: '1.1rem',
        padding: '0.1rem 1.75rem',
        borderRadius: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#35a7e0db',
            boxShadow: 'none',
        },
    }
}));

export default function CreativeManagementPage(props) {
    const classes = useStyles();

    const mountRef = React.useRef(false);
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.user.token);
    const [clientPoPupState, setClientPoPupState] = useState(false);
    const [requestingData, setRequestingData] = useState(false);
    const [clientName, setClientName] = useState('');
    const [apiData, setApiData] = useState(false);
    const [gridData, setGridData] = useState([]);

    const handleEdit = (id) => { };

    const handleDelete = (id, callback) => { };

    const handleCampaignAdd = (campaign) => {
        setRequestingData(true);
        postCampaign(authToken, campaign, addCampaignCallback);
    };

    const addCampaignCallback = (response, campaign) => {
        if (response.error !== 0) {
            toast.error('Error: ' + response.message);
            return;
        }
        setRequestingData(false);
        setApiData(false);
        getClients(authToken, requestCampaigns);
    };

    const handleCreateBtnClick = () => {
        setClientPoPupState(true);
    };

    const handleClientPopUpDiscard = () => {
        setClientName('');
        setClientPoPupState(false);
    };

    const handleClientPopUpConfirm = (data) => {
        setRequestingData(true);
        postClient(authToken, data, addClientCallback);
    };

    const addClientCallback = (response, client) => {
        if (response.error !== 0) {
            toast.error('Error: ' + response.message);
            return;
        }
        let newRows = [...gridData];
        newRows.push({
            id: response.result,
            clientId: response.result,
            clientName: client.name,
            campaigns: []
        });
        setGridData(newRows);
        setRequestingData(false);
        setClientPoPupState(false);
    };

    const handleGridData = useCallback((clients, campaigns) => {
        let gridRows = [];
        for (let i = 0; i < clients.length; i++) {
            let client = clients[i];
            let clientCamps = campaigns.filter((c) => c.clientId === client.id).map((c) => c.name);
            gridRows.push({
                id: client.id,
                clientId: client.id,
                clientName: client.name,
                campaigns: clientCamps
            });
        }
        setGridData(gridRows);
        setApiData(true);
    }, []);

    const requestCampaigns = useCallback((data) => {
        if (data.error !== 0) {
            toast.error('Error: ' + data.message);
            return;
        }
        dispatch({ type: SET_CLIENTS, clients: data.clients });
        getCampaigns(authToken, (cmpData) => {
            if (cmpData.error !== 0) {
                toast.error('Error: ' + cmpData.message);
                return;
            }
            dispatch({ type: SET_CAMPAIGNS, campaigns: cmpData.campaigns });
            handleGridData(data.clients, cmpData.campaigns);
        });
    }, [dispatch, authToken, handleGridData]);

    useEffect(() => {
        if (!mountRef.current) {
            getClients(authToken, requestCampaigns);
            mountRef.current = true;
        }
    }, [authToken, requestCampaigns]);

    return (
        <div className={`page-container ${classes.container}`} >
            <div className={classes.action_bar}>
                <Typography className={classes.title}>
                    Clients
                </Typography>
                <Button
                    variant='contained'
                    onClick={handleCreateBtnClick}
                    className={classes.btn}
                >
                    Add New Client
                </Button>
            </div>
            <ClientGrid
                rows={gridData}
                apiData={apiData}
                addCampaign={handleCampaignAdd}
                editCreative={handleEdit}
                deleteCreative={handleDelete} />
            {requestingData ? <LoadingIndicator /> : null}
            {clientPoPupState ? <NewClient name={clientName} discardCallback={handleClientPopUpDiscard} confirmCallback={handleClientPopUpConfirm} /> : null}
        </div>
    )
}