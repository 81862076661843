import './style.scss';
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '../Input/Button';
import ImageInput from '../Input/ImageInput';
import Slider from '../Input/Slider';
import Shape from '../Elements/Shape';
import { BlockPicker } from 'react-color';
import { handleShapeSelect, handleColorChange, handleImageChange, handleRadiusChange } from '../Elements/handlers';


export default function ShapePopUp(props) {
    const dispatch = useDispatch();
    const confirmCallback = useSelector(state => state.pop_up.confirmCallback);
    const discardCallback = useSelector(state => state.pop_up.discardCallback);
    const shapes = useSelector(state => state.shape_selection.shapes);
    const selectedShape = useSelector(state => state.shape_selection.selectedShape);

    const handleShapeFocus = (shape) => {
        handleShapeSelect(dispatch, shape);
    }

    const handleColorPickerChangeComplete = (color) => {
        handleColorChange(dispatch, selectedShape.uiId, color);
    }

    const handleShapeImage = (e) => {
        handleImageChange(dispatch, selectedShape.uiId, e);
    }

    const handleRadius = (e) => {
        handleRadiusChange(dispatch, selectedShape.uiId, e);
    }

    const handleAddShape = () => {
        confirmCallback(selectedShape);
    }

    return (
        <div className="pop-up-container">
            <div className="shape pop-up box-shadow">
                <header>Shapes</header>
                <div className="ds-divider"></div>
                <main className="content">
                    <div className="menu">
                        <BlockPicker color={selectedShape.color} onChangeComplete={handleColorPickerChangeComplete} width="100%" triangle="hide" />
                        {selectedShape.hasBgImg ? <ImageInput elemId={selectedShape.uiId} addImage={handleShapeImage} image={selectedShape.image} /> : null}
                        {selectedShape.hasBorderRadius ? <Slider value={selectedShape.borderRadius} onChange={handleRadius} minRange={0} maxRange={50} rangeStep={1} /> : null}

                    </div>
                    <div className="box">
                        {shapes.map((sh, index) => <Shape {...sh} key={index} onClick={handleShapeFocus} />)}
                    </div>
                </main>
                <div className="ds-divider"></div>
                <footer>
                    <Button className="no-btn borderless mr-20" onClick={discardCallback} variant="text">Cancel</Button>
                    <Button className="yes-btn colored-light" onClick={handleAddShape} variant="contained">Add</Button>
                </footer>
            </div>
        </div>
    )
}