import React from "react";
import { useDispatch } from "react-redux";
import useContextMenu from "../../hooks/useContextMenu";
import { handlePositionRevert, handleDelete } from '../MoveableElements/handlers';
import { handleSideMenu } from '../StyleMenus/handlers';
import { MdDeleteOutline, MdCenterFocusStrong } from "react-icons/md";

const CreativeContextMenu = ({ parentRef, images, texts, shapes }) => {
    const dispatch = useDispatch();
    const { xPos, yPos, menu, elemId, classList } = useContextMenu(parentRef);

    function getItem(){
        let item;
        if (classList.contains('image')) {
            item = images.find(i=> i.uiId === elemId);
        }
        if (classList.contains('text')) {
            item = texts.find(t=> t.uiId === elemId);
        }
        if (classList.contains('shape')) {
            item = shapes.find(s=> s.uiId === elemId);
        }
        return item;
    }

    const handleDeleteClick = () =>{
        const item = getItem();
        handleSideMenu(dispatch, 'delete');
        handleDelete(dispatch, elemId, item.type);
    };

    const handleRecenterClick = () =>{
        const item = getItem();
        handlePositionRevert(dispatch, item);
    };

    if (menu) {
        return (
            <div className="banner-context-menu" style={{ top: yPos, left: xPos }}>
                <div className="context-menu-item" onClick={handleRecenterClick}>
                    <MdCenterFocusStrong className="icon"/> Initial Position
                </div>
                <div className="context-menu-item" onClick={handleDeleteClick}>
                    <MdDeleteOutline className="icon"/> Delete
                </div>
            </div>
        );
    }
    return <></>;
};

export default CreativeContextMenu;