import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import React from 'react';
// import './style.scss';

export default function DropDown(props) {
    const isMultiple = props.isMultiple ? props.isMultiple : false;
    const isDisabled = props.disable ? props.disable : false;

    const handleOptionChange = (event) => {
        const newValue = props.options.find(x => x.id === event.target.value);
        props.onChange(newValue);
    };

    const handleOptionWithLabelsChange = (event) => {
        let newValue;
        for (const item of props.options) {
            newValue = item.sizes.find(o => o.id === event.target.value);
            if (newValue) break;
        }
        props.onChange(newValue);
    };

    if (props.withLabels) {
        return (
            <FormControl disabled={isDisabled} sx={props.sx}>
                <InputLabel>{props.label}</InputLabel>
                <Select
                    value={props.value}
                    onChange={handleOptionWithLabelsChange}
                    multiple={isMultiple}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: '13vw',
                            },
                        },
                    }}
                >
                    {props.options.map((o, index) => (
                        <React.Fragment key={index}>
                            <ListSubheader>{o.name}</ListSubheader>
                            {o.sizes.map((sub_o, sub_index) => (
                                <MenuItem value={sub_o.id} key={sub_index}>{sub_o.name}</MenuItem>
                            ))}
                        </React.Fragment>
                    ))}
                </Select>
            </FormControl>
        );
    }

    return (
        <FormControl 
            disabled={isDisabled} 
            style={props.style}
        >
            <label
                style={{
                    fontSize: '1.1vw',
                    fontWeight: 'bold', 
                    fontFamily: 'Lexend',
                    lineHeight: '2vw',
                }}
            >
                {props.label}
            </label>
            <Select
                fullWidth
                value={props.value}
                onChange={handleOptionChange}
                multiple={isMultiple}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: '10vw',
                        },
                    },
                }}
                sx={{
                    fontFamily: 'Lexend',
                    marginTop: '8px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #35A7E0',
                        borderRadius: '10vw',
                    },
                    '& .MuiSelect-nativeInput': {
                        fontFamily: 'Lexend',
                        padding: 0,
                    },
                    '& .MuiSelect-select': {
                        padding: '0.6vw 1.2vw',
                        height: '1.883vw !important',
                        textAlign: 'left',
                        lineHeight: '2vw',
                        fontSize: '1vw !important', 
                    },
                    '& legend': {
                        display: 'none', // Hide the legend
                    },
                    '& .MuiSvgIcon-root': {
                        width: '2vw',
                        height: '2vw',
                        top: 'calc(50% - 1vw)'
                    },
                }}
            >
                {props.options.map((o, index) => {
                    return <MenuItem value={o.id} key={index} disabled={o.disabled}>{o.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}