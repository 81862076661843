import * as actionTypes from '../action';
import { determineNewHeight } from '../../utils';

const maxSize = 200;
const initialState = {
    images: []
}

function getSizeValues(height, width) {
    if (width > height) {
        return { height: maxSize, width: Math.round(determineNewHeight(width, height, maxSize)) }
    }
    return { height: Math.round(determineNewHeight(height, width, maxSize)), width: maxSize }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_IMAGE:
            let sizeInit = getSizeValues(action.height, action.width);
            let img = {
                uiId: action.uiId,
                className: action.uiId,
                width: sizeInit.width,
                height: sizeInit.height,
                animationType: 'none',
                iterationType: 'default',
                animationDelay: 0.01,
                animationDuration: 0,
                imageName: action.imageName,
                image: action.value,
                opacity: 1,
                index: 0,
                type: "image",
                redirectionUrl: '',
                inFocus: false,
            }
            let oldImages = state.images;
            let newImages = oldImages.concat(img);
            return {
                ...state,
                images: newImages
            }
        case actionTypes.DELETE_IMAGE:
            return {
                ...state,
                images: state.images.filter(img => img.uiId !== action.uiId)
            }
        case actionTypes.ADD_ALL_IMAGES:
            return {
                ...state,
                images: [...action.images]
            }
        case actionTypes.DELETE_ALL_IMAGES:
            return {
                ...state,
                images: []
            }
        case actionTypes.SET_IMAGE_REDIRECTION_URL:
            return {
                ...state,
                images: state.images.map(
                    img => img.uiId === action.uiId ? { ...img, redirectionUrl: action.value } : img
                )
            }
        case actionTypes.SET_IMAGE_Z_INDEX:
            return {
                ...state,
                images: state.images.map(
                    img => img.uiId === action.uiId ? { ...img, index: action.value } : img
                )
            }
        case actionTypes.SET_IMAGE_OPACITY:
            return {
                ...state,
                images: state.images.map(
                    img => img.uiId === action.uiId ? { ...img, opacity: action.value } : img
                )
            }
        case actionTypes.SET_IMAGE_ANIMATION:
            return {
                ...state,
                images: state.images.map(
                    img => img.uiId === action.uiId ? {
                        ...img,
                        animationType: action.data.animationType,
                        iterationType: action.data.iterationType,
                        animationDelay: action.data.animationDelay,
                        animationDuration: action.data.animationDuration,
                        className: action.data.className
                    } : img
                )
            }
        case actionTypes.SET_IMAGE_TRANSFORM:
            return {
                ...state,
                images: state.images.map(img => img.uiId === action.uiId ? { ...img, transform: action.value } : img)
            }
        case actionTypes.SET_IMAGE_CONTENT:
            let sizeValues = getSizeValues(action.value.height, action.value.width);
            return {
                ...state,
                images: state.images.map(img => img.uiId === action.uiId ?
                    {
                        ...img,
                        image: action.value.image,
                        width: sizeValues.width,
                        height: sizeValues.height,
                        imageName: action.value.name
                    }
                    : img)
            }
        case actionTypes.REVERT_POSITION_IMAGE:
            let transformVal = action.transform ? action.transform.replace(/\b(\d*\.?\d+) *(px)/g, 0) : null;
            return {
                ...state,
                images: state.images.map(img => img.uiId === action.uiId ? { ...img, transform: transformVal } : img)
            }
        case actionTypes.FOCUS_IMAGE:
            return {
                ...state,
                images: state.images.map(img => img.uiId === action.uiId ? { ...img, inFocus: true } : img)
            }
        case actionTypes.IMAGE_REMOVE_FOCUS:
            return {
                ...state,
                images: state.images.map(img => img.inFocus === true ? { ...img, inFocus: false } : img)
            }
        default:
            return state;
    }

}

export default reducer