import React from 'react'
import { Box, FormLabel, Stack, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const RadioGroupInput = (props) => {
  return (
    <Stack sx={props.sx}>
      <Box>
        <FormLabel
          sx={props.labelSx}
        >
          {props.label}
        </FormLabel>
        <RadioGroup 
          onClick={props.onClick}
          sx={{...props.groupSx, width: 'max-content'}}
          value={props.value}
          >
            {props.radios && props.radios.map((radio, index) => (
              <FormControlLabel
                key={`radio-label${index}`} 
                labelPlacement="start" 
                value={radio.value} 
                control={<Radio />} 
                label={radio.label}
                sx={radio.sx}
              />
            ))}
        </RadioGroup>
      </Box>
    </Stack>
  )
}

export default RadioGroupInput;