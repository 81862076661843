/************* pop-up reducer actions *************/
export const ENABLE_CONFIRM_POPUP = 'ENABLE_CONFIRM_POPUP';
export const DISABLE_CONFIRM_POPUP = 'DISABLE_CONFIRM_POPUP';
export const SHOW_BANNER_PREVIEW = 'SHOW_BANNER_PREVIEW';
export const HIDE_BANNER_PREVIEW = 'HIDE_BANNER_PREVIEW';
export const SHOW_SHAPE_POPUP = 'SHOW_SHAPE_POPUP';
export const HIDE_SHAPE_POPUP = 'HIDE_SHAPE_POPUP';

/************* control (style menu container) reducer actions *************/
export const SET_IMAGE_MENU_STATE = 'SET_IMAGE_MENU_STATE';
export const SET_TEXT_MENU_STATE = 'SET_TEXT_MENU_STATE';
export const SET_SHAPE_MENU_STATE = 'SET_SHAPE_MENU_STATE';
export const SET_LEFT_DRAWER_CLOSE = 'SET_LEFT_DRAWER_CLOSE';
export const SET_LEFT_DRAWER_CURRENT_TAB = 'SET_LEFT_DRAWER_CURRENT_TAB';
export const SET_GRID_PAGE_CONTENT = 'SET_GRID_PAGE_CONTENT';

/************* banner style reducer actions *************/
//export const SET_BANNER_FIELDS = 'SET_BANNER_FIELDS';
//export const SET_INTERSTITIAL_FIELDS = 'SET_INTERSTITIAL_FIELDS';
export const SET_ASSET_SCORE = 'SET_ASSET_SCORE';
export const SET_INTERSTITIAL_IMAGE = 'SET_INTERSTITIAL_IMAGE';
export const SET_INLINE_VIDEO = 'SET_INLINE_VIDEO';
export const SET_VIDEO_INTERSTITIAL = 'SET_VIDEO_INTERSTITIAL';
export const CLEAR_CREATIVE_FIELDS = 'CLEAR_CREATIVE_FIELDS';
export const SET_CREATIVE_FIELDS = 'SET_CREATIVE_FIELDS';
export const SET_CREATIVE_NAME = 'SET_CREATIVE_NAME';
export const SET_CREATIVE_TYPE = 'SET_CREATIVE_TYPE';
export const SET_CREATIVE_CLIENT_ID = 'SET_CREATIVE_CLIENT_ID';
export const SET_CREATIVE_CAMPAIGN_ID = 'SET_CREATIVE_CAMPAIGN_ID';
export const SET_CREATIVE_TYPE_DEFAULTS = 'SET_CREATIVE_TYPE_DEFAULTS';
export const SET_TYPE_FIELDS = 'SET_TYPE_FIELDS';

export const ADD_ASSET = 'ADD_ASSET';
export const DELETE_ASSET = 'DELETE_ASSET';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const UPDATE_ASSET_SIZE = 'UPDATE_ASSET_SIZE';
export const UPDATE_BANNER_SIZE = 'UPDATE_BANNER_SIZE';
export const UPDATE_BANNER_NAME = 'UPDATE_BANNER_NAME';
export const UPDATE_BANNER_BACKGROUND_COLOR = 'UPDATE_BANNER_BACKGROUND_COLOR';
export const UPDATE_BANNER_BACKGROUND_IMAGE = 'UPDATE_BANNER_BACKGROUND_IMAGE';
export const UPDATE_BANNER_BACKGROUND_VIDEO = 'UPDATE_BANNER_BACKGROUND_VIDEO';
export const UPDATE_BANNER_REDIRECTION_URL = 'UPDATE_BANNER_REDIRECTION_URL';
export const UPDATE_BANNER_FONT = 'UPDATE_BANNER_FONT';
export const UPDATE_BANNER_TEXT_COLOR = 'UPDATE_BANNER_TEXT_COLOR';
export const UPDATE_BANNER_TIMER_END_DATE = 'UPDATE_BANNER_TIMER_END_DATE';
export const SET_SHOW_BANNER_COLOR_PICKER = 'SET_SHOW_BANNER_COLOR_PICKER';
export const STORE_DELETED_ITEM = 'STORE_DELETED_ITEM';
export const SET_MUTE_BTN = 'SET_MUTE_BTN';
export const SET_PLAY_BTN = 'SET_PLAY_BTN';
export const SET_BLACK_BTN = 'SET_BLACK_BTN';
export const SET_BTNS_POSITION = 'SET_BTNS_POSITION';
export const SET_AUTO_CLOSE = 'SET_AUTO_CLOSE';
export const SET_AUTO_CLOSE_TIMER = 'SET_AUTO_CLOSE_TIMER';
export const SET_SHOW_TIMER = 'SET_SHOW_TIMER';
export const SET_SCRATCH_COMPLETE = 'SET_SCRATCH_COMPLETE';
export const SET_COMPRESSION = 'SET_COMPRESSION';
export const SET_TEXT_BACKGROUND_COLOR = 'SET_TEXT_BACKGROUND_COLOR';
export const SET_BACKGROUND_COLOR = 'SET_BACKGROUND_COLOR';
export const SET_GAME_DURATION = 'SET_GAME_DURATION';
export const SET_SPEED_INTERVAL = 'SET_SPEED_INTERVAL';
export const SET_INCREASE_SPEED_BY = 'SET_INCREASE_SPEED_BY';
export const SET_GAME_BASE_SPEED = 'SET_GAME_BASE_SPEED';
export const SET_COLLECT_OR_EVADE = 'SET_COLLECT_OR_EVADE';
export const SET_ENABLE_GAME_SCORE = 'SET_ENABLE_GAME_SCORE';
export const SET_ENABLE_GAME_LIVES = 'SET_ENABLE_GAME_LIVES';
export const SET_CURRENT_VIDEOS = 'SET_CURRENT_VIDEOS';
export const SET_IMPRESSION_TAG = 'SET_IMPRESSION_TAG';
export const SET_IMPRESSION_CLICK_TAG = 'SET_IMPRESSION_CLICK_TAG';
export const SET_SHOW_VIDEO = 'SET_SHOW_VIDEO';

/************* text style reducer actions *************/
export const SET_STYLE_TEXT_CONTENT = 'SET_STYLE_TEXT_CONTENT';
export const SET_STYLE_TEXT_FOCUS = 'SET_STYLE_TEXT_FOCUS';
export const SET_STYLE_TEXT_COLOR = 'SET_STYLE_TEXT_COLOR';
export const SET_STYLE_TEXT_Z_INDEX = 'SET_STYLE_TEXT_Z_INDEX';
export const SET_STYLE_TEXT_FONT_FAMILY = 'SET_STYLE_TEXT_FONT_FAMILY';
export const SET_STYLE_TEXT_FONT_SIZE = 'SET_STYLE_TEXT_FONT_SIZE';
export const SET_STYLE_TEXT_ANIMATION_TYPE = 'SET_STYLE_TEXT_ANIMATION_TYPE';
export const SET_STYLE_TEXT_ITERATION_TYPE = 'SET_STYLE_TEXT_ITERATION_TYPE';
export const SET_STYLE_TEXT_DELAY_TYPE = 'SET_STYLE_TEXT_DELAY_TYPE';
export const SET_STYLE_TEXT_ANIMATION_DURATION = 'SET_STYLE_TEXT_ANIMATION_DURATION';
export const SET_STYLE_TEXT_REDIRECTION_URL = 'SET_STYLE_TEXT_REDIRECTION_URL';

/************* image style reducer actions  *************/
export const SET_STYLE_IMAGE_CONTENT = 'SET_STYLE_IMAGE_CONTENT';
export const SET_STYLE_IMAGE_FOCUS = 'SET_STYLE_IMAGE_FOCUS';
export const SET_STYLE_IMAGE_OPACITY = 'SET_STYLE_IMAGE_OPACITY';
export const SET_STYLE_IMAGE_Z_INDEX = 'SET_STYLE_IMAGE_Z_INDEX';
export const SET_STYLE_IMAGE_ANIMATION_TYPE = 'SET_STYLE_IMAGE_ANIMATION_TYPE';
export const SET_STYLE_IMAGE_ITERATION_TYPE = 'SET_STYLE_IMAGE_ITERATION_TYPE';
export const SET_STYLE_IMAGE_DELAY_TYPE = 'SET_STYLE_IMAGE_DELAY_TYPE';
export const SET_STYLE_IMAGE_ANIMATION_DURATION = 'SET_STYLE_IMAGE_ANIMATION_DURATION';
export const SET_STYLE_IMAGE_REDIRECTION_URL = 'SET_STYLE_IMAGE_REDIRECTION_URL';
export const SET_STYLE_CURRENT_IMAGE = 'SET_STYLE_CURRENT_IMAGE';
export const SET_STYLE_IMAGE_INDEX = 'SET_STYLE_IMAGE_INDEX';

/************* shape style reducer actions  *************/
export const SET_STYLE_SHAPE_FOCUS = 'SET_STYLE_SHAPE_FOCUS';
export const SET_STYLE_SHAPE_IMAGE = 'SET_STYLE_SHAPE_IMAGE';
export const SET_STYLE_SHAPE_COLOR = 'SET_STYLE_SHAPE_COLOR';
export const SET_STYLE_SHAPE_OPACITY = 'SET_STYLE_SHAPE_OPACITY';
export const SET_STYLE_SHAPE_Z_INDEX = 'SET_STYLE_SHAPE_Z_INDEX';
export const SET_STYLE_SHAPE_ANIMATION_TYPE = 'SET_STYLE_SHAPE_ANIMATION_TYPE';
export const SET_STYLE_SHAPE_ITERATION_TYPE = 'SET_STYLE_SHAPE_ITERATION_TYPE';
export const SET_STYLE_SHAPE_DELAY_TYPE = 'SET_STYLE_SHAPE_DELAY_TYPE';
export const SET_STYLE_SHAPE_ANIMATION_DURATION = 'SET_STYLE_SHAPE_ANIMATION_DURATION';
export const SET_STYLE_SHAPE_REDIRECTION_URL = 'SET_STYLE_SHAPE_REDIRECTION_URL';
export const SET_STYLE_SHAPE_BORDER_RADIUS = 'SET_STYLE_SHAPE_BORDER_RADIUS';

/************* moveable image actions *************/
export const ADD_IMAGE = 'ADD_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const ADD_ALL_IMAGES = 'ADD_ALL_IMAGES';
export const REVERT_POSITION_IMAGE = 'REVERT_POSITION_IMAGE';
export const FOCUS_IMAGE = 'FOCUS_IMAGE';
export const IMAGE_REMOVE_FOCUS = 'IMAGE_REMOVE_FOCUS';
export const DELETE_ALL_IMAGES = 'DELETE_ALL_IMAGES';
export const SET_IMAGE_CONTENT = 'SET_IMAGE_CONTENT';
export const SET_IMAGE_Z_INDEX = 'SET_IMAGE_Z_INDEX';
export const SET_IMAGE_OPACITY = 'SET_IMAGE_OPACITY';
export const SET_IMAGE_ANIMATION = 'SET_IMAGE_ANIMATION';
export const SET_IMAGE_TRANSFORM = 'SET_IMAGE_TRANSFORM';
export const SET_IMAGE_REDIRECTION_URL = 'SET_IMAGE_REDIRECTION_URL';

/************* moveable text actions *************/
export const ADD_TEXT = 'ADD_TEXT';
export const DELETE_TEXT = 'DELETE_TEXT';
export const ADD_ALL_TEXTS = 'ADD_ALL_TEXTS';
export const DELETE_ALL_TEXTS = 'DELETE_ALL_TEXTS';
export const REVERT_POSITION_TEXT = 'REVERT_POSITION_TEXT';
export const FOCUS_TEXT = 'FOCUS_TEXT';
export const TEXT_REMOVE_FOCUS = 'TEXT_REMOVE_FOCUS';
export const SET_TEXT_CONTENT = 'SET_TEXT_CONTENT';
export const SET_TEXT_COLOR = 'SET_TEXT_COLOR';
export const SET_TEXT_Z_INDEX = 'SET_TEXT_Z_INDEX';
export const SET_TEXT_FONT_FAMILY = 'SET_TEXT_FONT_FAMILY';
export const SET_TEXT_FONT_SIZE = 'SET_TEXT_FONT_SIZE';
export const SET_TEXT_ANIMATION = 'SET_TEXT_ANIMATION';
export const SET_TEXT_POSITION = 'SET_TEXT_POSITION';
export const SET_TEXT_TRANSFORM = 'SET_TEXT_TRANSFORM';
export const SET_TEXT_REDIRECTION_URL = 'SET_TEXT_REDIRECTION_URL';

/************* moveable shape actions *************/
export const ADD_SHAPE = 'ADD_SHAPE';
export const DELETE_SHAPE = 'DELETE_SHAPE';
export const ADD_ALL_SHAPES = 'ADD_ALL_SHAPES';
export const DELETE_ALL_SHAPES = 'DELETE_ALL_SHAPES';
export const REVERT_POSITION_SHAPE = 'REVERT_POSITION_SHAPE';
export const SHAPE_REMOVE_FOCUS = 'SHAPE_REMOVE_FOCUS';
export const FOCUS_SHAPE = 'FOCUS_SHAPE';
export const SET_SHAPE_IMAGE = 'SET_SHAPE_IMAGE';
export const SET_SHAPE_COLOR = 'SET_SHAPE_COLOR';
export const SET_SHAPE_Z_INDEX = 'SET_SHAPE_Z_INDEX';
export const SET_SHAPE_OPACITY = 'SET_SHAPE_OPACITY';
export const SET_SHAPE_ANIMATION = 'SET_SHAPE_ANIMATION';
export const SET_SHAPE_TRANSFORM = 'SET_SHAPE_TRANSFORM';
export const SET_SHAPE_REDIRECTION_URL = 'SET_SHAPE_REDIRECTION_URL';
export const SET_SHAPE_BORDER_RADIUS = 'SET_SHAPE_BORDER_RADIUS';

/************* user actions *************/

export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';


/************* new creative *************/
export const SET_NEW_CREATIVE_NAME = 'SET_NEW_CREATIVE_NAME';
export const SET_NEW_CREATIVE_TYPE = 'SET_NEW_CREATIVE_TYPE';
export const SET_NEW_CREATIVE_CLIENT_ID = 'SET_NEW_CREATIVE_CLIENT_ID';
export const SET_NEW_CREATIVE_CAMPAIGN_ID = 'SET_NEW_CREATIVE_CAMPAIGN_ID';
export const CLEAR_NEW_CREATIVE_FIELDS = 'CLEAR_NEW_CREATIVE_FIELDS';

/************* shape selection *************/
export const INIT_SHAPES = 'INIT_SHAPES';
export const SELECT_SHAPE = 'SELECT_SHAPE';
export const COLOR_SHAPE = 'COLOR_SHAPE';
export const IMAGE_SHAPE = 'IMAGE_SHAPE';
export const RADIUS_SHAPE = 'RADIUS_SHAPE';

/************* local state *************/
export const SET_SIDE_MENU_HEIGHT = 'SET_SIDE_MENU_HEIGHT';
export const SET_PLAY_VIDEO = 'SET_PLAY_VIDEO';
export const SET_IMAGE_COMPRESSION = 'SET_IMAGE_COMPRESSION';
export const SET_OPEN_IMAGE_SETTINGS = 'SET_OPEN_IMAGE_SETTINGS';
export const SET_OPEN_VIDEO_SETTINGS = 'SET_OPEN_VIDEO_SETTINGS';

/************* Program video state *************/
export const ADD_NEW_BREAK_POINT = 'ADD_NEW_BREAK_POINT';
export const SET_GRID_COLUMNS_NUMBER = 'SET_GRID_COLUMNS_NUMBER';
export const SET_GRID_ROWS_NUMBER = 'SET_GRID_ROWS_NUMBER';
export const SET_BREAK_POINTS = 'SET_BREAK_POINTS';
export const SET_BREAK_POINT = 'SET_BREAK_POINT';
export const DELETE_BREAK_POINT = 'DELETE_BREAK_POINT';
export const SET_BREAKE_POINT__ACTION_TYPE = 'SET_BREAKE_POINT__ACTION_TYPE';
export const SET_BREAKE_POINT__INTERACTION_PATH = 'SET_BREAKE_POINT__INTERACTION_PATH';
export const SET_BREAKE_POINT__REWIND_FROM = 'SET_BREAKE_POINT__REWIND_FROM';
export const SET_BREAKE_POINT__REWIND_TO = 'SET_BREAKE_POINT__REWIND_TO';
export const SET_BREAKE_POINT_REDIRECTION_URL = 'SET_BREAKE_POINT_REDIRECTION_URL';
export const SET_BREAKE_POINT_ON_EXIT_LOOP = 'SET_BREAKE_POINT_ON_EXIT_LOOP';
export const SET_BREAKE_POINT_LOOP = 'SET_BREAKE_POINT_LOOP';
export const SET_BREAKE_POINT_MUTE = 'SET_BREAKE_POINT_MUTE';
export const SET_BREAKE_POINT_PLAY = 'SET_BREAKE_POINT_PLAY';

/************* Desktop Timer Banner state *************/
export const SET_VIDEO_ONLY = 'SET_VIDEO_ONLY';
export const SET_TIMER_POSITION = 'SET_TIMER_POSITION';
