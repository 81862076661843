import { useState, useEffect, useCallback, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validateAuthToken, getClients, getCampaigns, tokenKey } from '../../api_requests';
import { SET_USER, SET_CLIENTS, SET_CAMPAIGNS, CLEAR_USER } from '../../store/action';
import LoaderPage from '../Pages/LoaderPage';
import { LOGIN, MAIN } from '../../constants/path';

export default function PrivateRoute({ children }) {
    const mountRef = useRef(false);
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.user.token);
    const [isAuth, setIsAuth] = useState(false);
    const [apiData, setApiData] = useState(false);
    const navigate = useNavigate();

    const handleClients = useCallback((data) => {
        dispatch({ type: SET_CLIENTS, clients: data.clients });
        getCampaigns(data.token, (cmpData) => {
            dispatch({ type: SET_CAMPAIGNS, campaigns: cmpData.campaigns });
        });
    }, [dispatch]
    );

    const handleAuthentication = useCallback((data) => {
        //TODO: fix the first reload on login
        if (data.error === 0) {
            if (!data.isExpired) {
                setIsAuth(true);
                const user = data.user;
                dispatch({ type: SET_USER, id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, token: data.token, roles: user.roles });
                //get client & campaign data
                getClients(data.token, handleClients);
                navigate(MAIN);
            } else {
                dispatch({ type: CLEAR_USER });
                localStorage.removeItem(tokenKey);
                navigate(LOGIN);
            }
        }
    }, [dispatch, handleClients, navigate]);

    useEffect(() => {
        if (authToken) {
            setIsAuth(true);
            setApiData(true);
            if (!mountRef.current) {
                mountRef.current = true;
                validateAuthToken(authToken, handleAuthentication);
            }
        }
        else {
            setApiData(true);
            const token = localStorage.getItem(tokenKey);
            if (token) {
                validateAuthToken(token, handleAuthentication);
            } else {
                setApiData(false);
                localStorage.removeItem(tokenKey);
                dispatch({ type: CLEAR_USER });
                navigate(LOGIN);
            }
        }
    }, [authToken, handleAuthentication]);

    if (!apiData) {
        return <LoaderPage />;
    }

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isAuth ? children : <Navigate to="/login" />;
}