import './style.scss';
import React from 'react';
import Button from '../Input/Button';
import { useSelector } from 'react-redux';

import placeholder from "../../Components/utils/images/avatar_box_placeholder.png";

export default function ActionFooter({ returnCallback, proceedCallback }) {

    const creative = useSelector(state => state.creative);

    const disableButton = creative.name === "" || creative.assets?.some(asset => {
        if(asset.type === "video") {
            return asset.url === "" ? true : false;
        } else {
            return asset.url === placeholder;
        }
    });

    return (
        <footer className="page-footer">
            {/* <Button className="back-btn" onClick={returnCallback} variant="text" style={{ color: '#30a4d0' }} sx={{ '&:hover': { outline: '1px solid #1c90bc' } }}>{'< Return'}</Button> */}
            <Button className="proceed-btn full-width-btn" onClick={proceedCallback} variant="contained" disabled={disableButton}>Save</Button>
        </footer>
    )
}