import React, { useState } from 'react';
import './style.css';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
// import InlineLoader from '../Layout/InlineLoader';
import NewCampaign from '../PopUp/NewCampaign';
import { GrFormAdd, GrFormEdit } from "react-icons/gr";

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    grid_title: {
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold !important',
        }
    },
    green: {
        color: 'green',
    },
    red: {
        color: 'red',
    }
}));

function ClientGrid(props) {
    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuTop, setMenuTop] = useState(0);
    const [menuLeft, setMenuLeft] = useState(0);
    const [selected, setSelected] = useState(null);
    const [campaignPoPupState, setCampaignPoPupState] = useState(false);

    const [sortModel, setSortModel] = useState([
        {
            field: 'clientName',
            sort: 'asc',
        },
    ]);

    const rows = props.rows;
    const columns = [
        // { field: 'clientId', headerName: 'Id', minWidth: 300 },
        { field: 'clientName', headerClassName: classes.grid_title, headerName: 'Name', flex: 1 },
        { field: 'campaigns', headerClassName: classes.grid_title, headerName: 'Campaigns', flex: 1 }
    ];

    const handleRowClick = (e) => {
        setSelected(rows.find(row => row.id === e.currentTarget.getAttribute('data-id')));
        setMenuOpen(true)
        setMenuTop(e.clientY);
        setMenuLeft(e.clientX);
    };

    const handleClickAway = (e) => {
        setMenuOpen(false);
    };

    const handleAddCampaign = (e) => {
        setMenuOpen(false);
        setCampaignPoPupState(true);
    };

    const handleEditCampaign = () => {

    };

    const handleEditClient = () => {

    }; 

    const handleCampaignPopUpDiscard = (e) => {
        setCampaignPoPupState(false);
    };

    const handleCampaignPopUpConfirm = (campaign) => {
        campaign.clientId = selected.id;
        props.addCampaign(campaign);
        setCampaignPoPupState(false);
    };

    const customToolbar = () => {
        return (
            <GridToolbarContainer sx={{ backgroundColor: '#9BD4F5', padding: '4px 8px 4px 8px', borderRadius: '10px 10px 0 0', gap: '1vw' }}>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="grid" onContextMenu={(e) => e.preventDefault()}>
                {/* {props.apiData ? null : <InlineLoader />} */}
                <DataGrid
                    components={{
                        Toolbar: customToolbar,
                    }}
                    loading={!props.apiData}
                    rows={rows}
                    columns={columns}
                    disableMultipleSelection={true}
                    disableColumnSelector
                    // onRowClick={handleRowClick}
                    autoPageSize
                    onCellFocusOut={handleClickAway}
                    hideFooterSelectedRowCount
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    componentsProps={{
                        row: {
                            onContextMenu: handleRowClick,
                            style: { cursor: 'context-menu' },
                        },
                    }}
                />
                {menuOpen ?
                    <MenuList autoFocusItem={menuOpen} className="grid-menu" style={{ top: `calc( ${menuTop}px - 10px)`, left: `calc( ${menuLeft}px - 10px)` }}>
                        <MenuItem disabled onClick={handleEditClient}><GrFormEdit className="icon" />Edit Client</MenuItem>
                        <MenuItem onClick={handleAddCampaign}><GrFormAdd className="icon" />Add campaign</MenuItem>
                        <MenuItem disabled onClick={handleEditCampaign}><GrFormEdit className="icon" />Edit Campaign</MenuItem>
                    </MenuList> : null}
                {campaignPoPupState ? <NewCampaign discardCallback={handleCampaignPopUpDiscard} confirmCallback={handleCampaignPopUpConfirm} /> : null}
            </div>
        </ClickAwayListener>
    )
}

function areEqual(prevProps, nextProps) {
    /* return true if value is not updated,  otherwise return false */
    if (prevProps.apiData !== nextProps.apiData || JSON.stringify(prevProps.rows) !== JSON.stringify(nextProps.rows)) {
        return false;
    }
    return true;
}

export default React.memo(ClientGrid, areEqual);

