import { Stack, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect } from "react";
import { toast } from "react-toastify";

const DateTimeInput = (props) => {
  const classNames = props.className ? 'ds-mui-inputs ds-date-time-input ' + props.className : 'ds-mui-inputs ds-date-time-input';

  useEffect(() => {
    if (new Date(props.timer) < new Date(props.minValue)) {
      toast.error(props.placeholder + ' cannot be earlier than the current time');
    }
    // eslint-disable-next-line
  }, [props.timer]);

  return (
    <Stack spacing={1}>
      <label 
        style={{
          fontSize: '1.111vw',
          fontWeight: 'bold', 
          fontFamily: 'Lexend',
          lineHeight: '2vw',
        }}
      >
        {props.label}
      </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          className={classNames}
          label={props.placeholder}
          value={new Date(props.timer)}
          onChange={props.onChange}
          renderInput={(params) => 
            <TextField 
              {...params} 
              error={props.timer === ''} 
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #35A7E0',
                    borderRadius: '10vw',
                  },
                  '& legend': {
                    display: 'none', // Hide the legend
                  },
                  '& input': {
                    fontSize: '1vw', 
                    padding: '0.6vw 1.2vw',
                    height: '2.083vw',
                    fontFamily: 'Lexend',
                  },
                }
              }}
            />}
          inputFormat='DD-MM-YY HH:mm:ss' //? use 24-hour format with capital H
          ampm={false}
        />
      </LocalizationProvider>
    </Stack>
  )
}

export default DateTimeInput;