import './style.scss';
import React, { useRef } from "react";
import { useSelector } from "react-redux";
// import placeholder from '../../Components/utils/images/avatar_box_placeholder.png';
// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';

export default function Scratch(props) {
  const outerRef = useRef();
  const backgroundRef = useRef();

  // const width = useSelector(state => state.creative.bannerWidth);
  const height = useSelector(state => state.creative.bannerHeight);
  const bgColor = useSelector(state => state.creative.backgroundColor);
  const assets = useSelector(state => state.creative.assets);

  const frontImage = assets?.find(asset => asset.url !== "" && asset.type.toLowerCase() === "front_image" && asset.width === 640 && asset.height === 1280)?.url;
  const movableImage = assets?.find(asset => asset.url !== "" && asset.type.toLowerCase() === "movable_image")?.url;

  const bannerRatio = 0.5;

  const bannerStyle = {
    backgroundColor: bgColor,
    minHeight: height * 0.25,
    minWidth: height * 0.25 * bannerRatio,
    width: '100%',
    height: '100%',
    borderRadius: '1.5vw',
  };

  return (
      <div 
        className="banner-display-container interstitial-border" 
        style={{ 
          // backgroundImage: `url(${IphoneFrameImage})`, 
          width: '15vw', 
          height: '30vw',
          backgroundColor: 'white',
          borderRadius: '2vw',
        }}
      >
      {/*<div className="banner-display-container grid-content">*/}
      <img className='banner-display-frame-image' alt='' /> {/* src={IphoneFrameFrontCameraImage} /> */}
      <div className="banner-box">
        <div className="target scratch" style={bannerStyle} ref={outerRef}>
          <div ref={backgroundRef} className="scratch_background" style={{ backgroundImage: `url(${frontImage})` }}></div>
          <img className="scratch_movable_image" src={movableImage} alt="Movable img" style={{ visibility: `${movableImage ? 'unset' : 'hidden'}` }} />
        </div>
      </div>
    </div>
  )
}

