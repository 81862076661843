import * as actionTypes from '../../store/action';

export function handleShapeSelect(dispatch, shape) {
    dispatch({type: actionTypes.SELECT_SHAPE, shape:shape, uiId: shape.uiId});
}

export function handleColorChange(dispatch, uiId, color) {
    dispatch({type: actionTypes.COLOR_SHAPE, uiId: uiId, value: color.hex});
}

export function handleImageChange(dispatch, uiId, imageData) {
    dispatch({type: actionTypes.IMAGE_SHAPE, uiId: uiId, value: imageData.image, name: imageData.name});
}

export function handleRadiusChange(dispatch, uiId, value) {
    dispatch({type: actionTypes.RADIUS_SHAPE, uiId: uiId, value: value});
}





