import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css';
import './style.scss';

// import placeholder from '../../Components/utils/images/avatar_box_placeholder.png';
import videoImages from "../utils/images/video_buttons.json";

export default function VideoGallery(props) {
  const videoRef = useRef(null);

  const [playState, setPlayState] = useState(true);
  const [muteState, setMuteState] = useState(true);

  const sideMenuHeight = useSelector(state => state.localState.sideMenuHeight);

  const width = useSelector(state => state.creative.bannerWidth);
  const height = useSelector(state => state.creative.bannerHeight);
  const bgColor = useSelector(state => state.creative.backgroundColor);
  const assets = useSelector(state => state.creative.assets);
  const mute = useSelector(state => state.creative.mute);
  const play = useSelector(state => state.creative.play);
  const darkMode = useSelector(state => state.creative.darkMode);
  const buttonsPosition = useSelector(state => state.creative.buttonsPosition);

  const video = assets?.find(asset => asset.url !== '' && asset.order === 0)?.url || '';

  const [imgs, setImgs] = useState([]);

  const pauseBtn = darkMode ? videoImages.black.pause : videoImages.white.pause;
  const playBtn = darkMode ? videoImages.black.play : videoImages.white.play;
  const muteBtn = darkMode ? videoImages.black.mute : videoImages.white.mute;
  const unmuteBtn = darkMode ? videoImages.black.unmute : videoImages.white.unmute;

  const isVideoPlaying = video => !!(video?.currentTime > 0 && !video?.paused && !video?.ended && video?.readyState > 2);

  const toggleVideoSound = () => {
    if (videoRef.current.muted) {
      setMuteState(false);
    } else {
      setMuteState(true);
    }
  };

  const toggleVideoPlay = () => {
    if (isVideoPlaying(videoRef.current)) {
      videoRef.current.pause();
      setPlayState(false);
    } else {
      videoRef.current.play();
      setPlayState(true);
    }
  };

  useEffect(() => {
    setImgs(prevState => assets.filter((asset, index) => index > 2));
  }, [assets]);

  const bannerRatio = width / height;

  const bannerStyle = {
    backgroundColor: bgColor,
    height: sideMenuHeight * 0.6,
    width: sideMenuHeight * 0.6 * bannerRatio
  };

  return (
    <div className="banner-display-container grid-content">
      <div className="banner-box box-shadow video-gallery">
        <div className="target" style={bannerStyle}>
          <div id="ds_div_buttons" className='video-gallery-btns' style={{ width: bannerStyle.width, top: buttonsPosition === 'Top' ? '1vw' : 'unset', bottom: buttonsPosition === 'Top' ? 'unset' : buttonsPosition === 'Center' ? '10vw' : '1vw' }}>
            <img className={`ds_buttons left ${play ? '' : 'd-none'}`} id="sa_play_video" alt="play button" src={playState ? pauseBtn : playBtn} onClick={toggleVideoPlay} />
            <img className={`ds_buttons right ${mute ? '' : 'd-none'}`} id="sa_play_sound" alt="sound button" src={muteState ? muteBtn : unmuteBtn} onClick={toggleVideoSound} />
          </div>
          <video className='video-gallery-video' autoPlay={true} muted={muteState} loop ref={videoRef} src={video}>
          </video>
          <div className='video-gallery-swiper-container'>
            <div className='video-gallery-arrow'>
              <i className="arrow_down"></i>
            </div>
            {imgs.some(img => img.url !== '') && (
              // <!-- Slider main container -->
              <Swiper
                spaceBetween={125}
                slidesPerView={3}
                direction={'horizontal'}
                loop={true}
                className='video-gallery-mySwiper'
              >
                {imgs.map((img, index) => {
                  if (img.url !== '') {
                    return (
                      <SwiperSlide key={img + index} className="swiper-slide">
                        <img src={img.url} alt="slide-img" className='slide-img' />
                        {/* <img onClick={() => window.open(img.redirectionUrl)} src={img.url} alt="slider-img" /> */}
                      </SwiperSlide>
                    );
                  } else {
                    return null;
                  }
                })}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

