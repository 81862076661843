import React from 'react';
import './style.scss';

export default function Slider(props) {
    const classNames = props.className ? 'ds-inputs ds-slider ' + props.className : 'ds-inputs ds-slider';

    const handleValueChange = (event) => {
        const newValue = event.target.value;
        props.onChange(newValue);
    };

    return (
        <div className={classNames}>
            <input className={props.inputClassName} min={props.minRange} max={props.maxRange} step={props.rangeStep} type="range" value={props.value} onChange={handleValueChange} />
        </div>
    )
}