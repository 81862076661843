import React, { useState, useEffect, useCallback } from "react";
import { InputAdornment, Stack, TextField } from '@mui/material';
import './inputs.scss';

function NumberInput(props) {
    const value = props.value ? props.value : '';
    const classNames = props.className ? 'ds-mui-inputs ds-num-input ' + props.className : 'ds-mui-inputs ds-num-input';
    const [valueState, setValueState] = useState(value);
    const [isValid, setIsValid] = useState(true);
    const validation = props.validation;

    const handleOnChange = (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') { // up arrow or down arrow (keyboard press) and mouse wheel
            event.preventDefault();
        } else {
            setValueState(+event.target.value);
            props.onComplete(+event.target.value, event);
        }
    };

    const handleValidation = useCallback((val) => {
        if (typeof val === 'number') {
            if (!isNaN(val) && (val > 0 || (props.canBeZero && val >= 0) || props.canBeNegative)) setIsValid(true);
            else if (validation && typeof (validation) === 'function') setIsValid(validation(val));
            else setIsValid(false);
        }
        if (typeof val === 'string') {
            if (val !== '' && !val.includes('-')) setIsValid(true);
            else if (validation && typeof (validation) === 'function') setIsValid(validation(val));
            else setIsValid(false);
        }
    }, [validation, props.canBeZero, props.canBeNegative]);

    useEffect(() => {
        const newVal = props.value;
        setValueState(newVal);
        handleValidation(newVal);
    }, [props.value, valueState, setValueState, handleValidation]);

    const handleArrowsDisable = (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
        }
    };

    const icons = {
        // 'color': <FormatColorTextRounded />,
    }

    const icon = icons[props.icon];
    if(props.id && props.id.includes("ball")) {
        console.log(props);
    }

    return (
        <Stack
            sx={props.sx}
            spacing={1}
        >
            <label 
                style={{
                    fontSize: '1.1vw',
                    fontWeight: 'bold', 
                    fontFamily: 'Lexend',
                    lineHeight: '2vw',
                }}
            >
                {props.label}
            </label>
            <TextField
                id={props.id}
                color={props.color || "info"}
                className={classNames}
                onChange={handleOnChange}
                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} //? on element focus add non-passive event for mouse wheel and prevent scrolling inside the input
                value={valueState}
                type="number"
                label={props.placeholder}
                InputProps={{
                    startAdornment:
                        props.icon && (
                            <InputAdornment position="start">
                                {icon}
                            </InputAdornment>
                        ),
                }}
                sx={{
                    fontFamily: 'Lexend',
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #35A7E0',
                        borderRadius: props.circledInput === true ? '50%' : '10vw',
                    },
                    '& legend': {
                        display: 'none', // Hide the legend
                    },
                    '& input': {
                        fontSize: '1vw', 
                        padding: '0.6vw 1.2vw',
                        height: '1.883vw',
                        fontFamily: 'Lexend',
                        textAlign: props.inputTextCenter === true ? 'center' : 'unset'
                    },
                }}
                variant="outlined"
                onKeyDown={handleArrowsDisable}
                max={props.maxValue}
                min={props.minValue}
                disabled={props.disabled}
                error={!isValid}
            />
        </Stack>
    )
}

function areEqual(prevProps, nextProps) {
    /* return true if value is not updated,  otherwise return false */
    if (prevProps.value === nextProps.value && prevProps.itemId === nextProps.itemId) {
        return true;
    }
    return false;
}
export default React.memo(NumberInput, areEqual);