import './complex_text_input.scss';
import './style.scss';
import React, { useState} from "react";

import TextInput from './TextInput';
import DropDown from './DropDown';
import Number from './Number';
import { BlockPicker } from 'react-color';
import { IoMdColorFill } from "react-icons/io";
import fonts from '../utils/options/fonts';

function ComplexTextInput(props) {
    const classNames = props.className? 'ds-inputs ds-ctxt-input '+ props.className: 'ds-inputs ds-ctxt-input';
    const [showColorPicker, setShowColorPicker] = useState(false);

    const [textState, setTextState] = useState(props.value);
    const [sizeState, setSizeState] = useState(props.fontSize);
    const [colorState, setColorState] = useState(props.fontColor);
    const [familyState, setFamilyState] = useState(props.fontFamily);

    const handleNameChange = (val) => {
        setTextState(val);
        props.onChange({value:val, fontSize:sizeState, fontColor:colorState, fontFamily:familyState});
    };

    const handleSizeChange = (val) => {
        setSizeState(val);
        props.onChange({value:textState, fontSize:val, fontColor:colorState, fontFamily:familyState});
    };

    const handleColorPickerChangeComplete = (val) => {
        setColorState(val.hex);
        props.onChange({value:textState, fontSize:sizeState, fontColor:val.hex, fontFamily:familyState});
        setShowColorPicker(false);
    };

    const handleFontChange = (val) => {
        setFamilyState(val.id);
        props.onChange({value:textState, fontSize:sizeState, fontColor:colorState, fontFamily:val.id});
    };

    const handleBackgroundColorPickerClick = () => {
        setShowColorPicker(!showColorPicker);
    };

    return (
        <div className={classNames}>
            <div className="text-menu">
                { showColorPicker ?<BlockPicker color={colorState} onChangeComplete={handleColorPickerChangeComplete}/> : null }
                <div className="ds-inputs ds-i-button">
                    <IoMdColorFill className="color-icon" onClick={handleBackgroundColorPickerClick} data-tip="Choose background color"/>
                </div>
                <div className="m-inputs gr-10">
                    <Number value={sizeState} onChange={handleSizeChange} minValue={10} maxValue={40}/>
                </div>
                 <div className="m-inputs gr-20">
                     <DropDown value={familyState} onChange={handleFontChange} options={fonts}/>
                 </div>
            </div>
            <TextInput value={textState} onComplete={handleNameChange} />
        </div>
    )
}

function areEqual(prevProps, nextProps) {
    /* return true if value is not updated,  otherwise return false */
    if (prevProps.text === nextProps.text &&
        prevProps.font === nextProps.font &&
        prevProps.color === nextProps.color &&
        prevProps.family === nextProps.family &&
        prevProps.itemId === nextProps.itemId) {
        return true;
    }
    return false;
}
export default React.memo(ComplexTextInput, areEqual);