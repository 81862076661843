import React, { createRef, useRef, useState } from 'react';
import { RiDeleteBin2Fill } from "react-icons/ri";
import { MdCenterFocusStrong } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import Shape from '../Elements/Shape';
import ImageInput from '../Input/ImageInput';
import { handleSideMenu } from '../StyleMenus/handlers';
import { handleDeleteAsset, handleInlineVideo, handleInterstitialImage } from '../CreativeTemplates/handlers';
import { handleDelete, handleFocus, handlePositionRevert } from '../MoveableElements/handlers';
import { toast } from 'react-toastify';
import {
    INTERSTITIAL,
    HALF_HALF,
    INLINE,
    VIDEO_INTERSTITIAL,
    GALLERY,
    MOBILE_BANNER_VIDEO,
    FLIPPER,
    SCRATCH,
    BOUNCE_GAME,
    VIDEO_TIMER_BANNER,
    DESKTOP_TIMER_BANNER,
    VIDEO_GALLERY,
    COLLECT_OR_EVADE_GAME,
    PROGRAM_VIDEO,
    HALF_VIDEO,
    UNIVERSAL
    /*CUSTOM_BANNER*/
} from '../utils/options/creativeTypes';
import VideoInput from '../Input/VideoInput';
import * as actionTypes from '../../store/action';
import { /*POSTER,*/ VIDEO } from '../../constants/imageTypes';
import placeholder from '../../Components/utils/images/avatar_box_placeholder.png';
import { Box } from '@mui/material';
import AssetUpdateInput from './AssetUpdateInput';
// import { useParams } from 'react-router-dom';

export default function AvatarBox() {
    const creative = useSelector(state => state.creative);
    const images = useSelector(state => state.img.images);
    // const img_style = useSelector(state => state.img.img_style);
    const texts = useSelector(state => state.txt.texts);
    const shapes = useSelector(state => state.shape.shapes);
    const assets = useSelector(state => state.creative.assets);
    // const creative = useSelector(state => state.creative);
    const creativeType = useSelector(state => state.creative.type);
    const divider = useSelector(state => state.creative.divider);
    const dispatch = useDispatch();

    const creativeTypes = [
        INTERSTITIAL,
        HALF_HALF,
        INLINE,
        VIDEO_INTERSTITIAL,
        GALLERY,
        MOBILE_BANNER_VIDEO,
        FLIPPER,
        SCRATCH,
        BOUNCE_GAME,
        VIDEO_TIMER_BANNER,
        DESKTOP_TIMER_BANNER,
        VIDEO_GALLERY,
        COLLECT_OR_EVADE_GAME,
        PROGRAM_VIDEO,
        HALF_VIDEO,
        UNIVERSAL
    ];

    // const url = useParams();

    // const redirectionUrl = useSelector(state => state.img_style.redirectionUrl);
    // const imageIndex = useSelector(state => state.img_style.imageIndex);

    //? createRef() must be added if there is a creative in which the video input is not in the indexes from 0 - 6
    const refs = useRef([createRef(), createRef(), createRef(), createRef(), createRef(), createRef(), createRef()]);

    // TODO: add refs to hoverMenu to solver the bug that all menus open on hover
    // itemSideBarRefsArray = 
    // const itemSideBarRefs = useRef([createRef(), createRef(), createRef(), createRef(), createRef(), createRef()]);
    const [currentTitleId, setCurrentTitleId] = useState();
    // const [itemSideBar, setItemSideBar] = useState("-4vw");

    const [isAssetEditMode, setIsAssetEditMode] = useState(false);

    const isRegularBanner = [INTERSTITIAL, HALF_HALF].includes(creativeType);

    if (assets) {
        assets.sort((a, b) => {
            return a.order - b.order;
        });
    }

    const handleItemDelete = (item) => {
        handleSideMenu(dispatch, 'delete');
        handleDelete(dispatch, item.uiId, item.type);
    }

    // const handleAssetDelete = (item) => {
    //     if (item.url !== placeholder) {
    //     }
    // }

    const handleItemFocus = (item, index) => {
        if (images[index]) { //* if the clicked avatarbox has no image dont open the right side bar
            handleFocus(dispatch, item, item.type);
            if (index) dispatch({ type: actionTypes.SET_STYLE_IMAGE_INDEX, uiId: item.uiId, value: index });
            if (!isRegularBanner || creativeType === GALLERY || creativeType === VIDEO_GALLERY) handleSideMenu(dispatch, item.type, creativeType);
        } else {
            //if (assets.every(asset => asset.url === placeholder)) { 
            if (item.url === placeholder) { //*  if the clicked avatarbox has placeholder image dont open the right side bar
                dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: false });
                dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: false });
                dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: false });
            } else { //* if the clicked avatarbox has an image open the right side bar
                if (!isRegularBanner || creativeType === GALLERY || creativeType === VIDEO_GALLERY) {
                    handleSideMenu(dispatch, item.type);
                    //* update images state
                    // dispatch({ type: actionTypes.ADD_IMAGE, uiId: item.id, width: item.width, height: item.height, imageName: item.imageName, value: item.url, redirectionUrl: item.redirectionUrl });
                    //* update images style state
                    dispatch({ type: actionTypes.SET_STYLE_IMAGE_FOCUS, image: item.url, uiId: item.id, redirectionUrl: item.redirectionUrl });
                }
            }
        }
    }

    const changeImageCustoms = (imageData, item) => {
        let err = null;
        const errorMsg = "Error: Image file dimensions (IMAGE_SIZE) don't match requirements (MAX_SIZE)";
        if (item.height !== imageData.height || item.width !== imageData.width) {
            err = errorMsg.replace('IMAGE_SIZE', imageData.width + "×" + imageData.height).replace('MAX_SIZE', item.width + '×' + item.height)
            toast.error(err, { theme: "colored" });
            return err;
        }
        //validate size & width of uploaded image
        // console.log(item);
        handleInterstitialImage(dispatch, item.uiId || item.id, imageData, creativeType);
    }

    const handleVideoUpload = (file, dataUrl, setError) => {
        // eslint-disable-next-line
        let file_width;
        // eslint-disable-next-line
        let file_height;
        const $video = document.createElement("video");
        $video.style.display = "none";
        $video.src = dataUrl;
        $video.addEventListener("loadedmetadata", (event) => {
            file_width = event.target.videoWidth;
            file_height = event.target.videoHeight;
        });
        setTimeout(() => {
            // validate ?
            // const errorMsg = "Error: Video file dimensions (VIDEO_SIZE) don't match requirements (MAX_SIZE)";
            if (currentTitleId >= 0) {
                // set video to display
                handleInlineVideo(dispatch, file.id, dataUrl)
            }
            setError(null);
            $video.remove();
        }, 500);
    }

    const deleteAssetHandler = (asset) => {
        handleDeleteAsset(dispatch, asset.uiId);
    };

    //tooltip // upload option 100kb
    return (
        <div className="avatar-box">
            {creativeType !== GALLERY && creativeType !== VIDEO_GALLERY && images.map((img, index) =>
                <div className="avatar-action-box" style={{ backgroundImage: `url(${img.image})` }} key={index} onMouseDown={() => handleItemFocus(img)}>
                    <div className="hover-menu">
                        <div className="right-corner add">
                            <RiDeleteBin2Fill className="icon top" onClick={() => handleItemDelete(img)} />
                            <MdCenterFocusStrong className="icon bottom" onClick={() => handlePositionRevert(dispatch, img)} />
                        </div>
                    </div>
                </div>
            )}
            {texts.map((txt, index) =>
                <div className="avatar-action-box" key={index} onMouseDown={() => handleItemFocus(txt)}>
                    <div className="text-display">{txt.text}</div>
                    <div className="hover-menu">
                        <div className="right-corner add">
                            <RiDeleteBin2Fill className="icon top" onClick={() => handleItemDelete(txt)} />
                            <MdCenterFocusStrong className="icon bottom" onClick={() => handlePositionRevert(dispatch, txt)} />
                        </div>
                    </div>
                </div>
            )}
            {shapes.map((sh, index) =>
                <div className="avatar-action-box" key={index} onMouseDown={() => handleItemFocus(sh)}>
                    <Shape {...sh} />
                    <div className="hover-menu">
                        <div className="right-corner add">
                            <RiDeleteBin2Fill className="icon top" onClick={() => handleItemDelete(sh)} />
                            <MdCenterFocusStrong className="icon bottom" onClick={() => handlePositionRevert(dispatch, sh)} />
                        </div>
                    </div>
                </div>
            )}

            {creativeType === HALF_HALF && divider && (
                <div className='avatar-container'>
                    <p className='img-type'>Divider Image</p>
                    <div className="avatar-action-box img-placeholder divider" onMouseDown={() => handleItemFocus(divider)}>
                        <ImageInput elemId={divider.uiId || divider.id} maxSize={divider.maxSize} addImage={(e) => changeImageCustoms(e, divider)} image={divider.url} />
                        <div className="title">{divider.width + '×' + divider.height}</div>
                    </div>
                </div>
            )}

            {creativeTypes.includes(creativeType) && assets && assets.map((asset, index) => {
                let assetTitle = "";
                if (asset.type.includes('_')) {
                    assetTitle = asset.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                } else {
                    assetTitle = asset.type.charAt(0).toUpperCase() + asset.type.slice(1);
                }
                if (asset.type === VIDEO) {
                    return (
                        <div className='avatar-container' key={asset.uiId || asset.id}>
                            <Box sx={{ position: 'relative', width: '100%' }}>
                                {creativeType === UNIVERSAL && (
                                    <button onClick={() => deleteAssetHandler(asset)} style={{ position: 'absolute', top: 0, right: '4px', padding: 0, fontSize: '0.7vw !important', border: 'none', backgroundColor: 'transparent', lineHeight: 1 }}>X</button>
                                )}
                                <p className='img-type'>{assetTitle}</p>
                            </Box>
                            <div className="avatar-action-box img-placeholder" onClick={() => setCurrentTitleId(index)}>
                                <VideoInput id={asset.uiId || asset.id} maxSize={asset.maxSize} onAddVideo={handleVideoUpload} image={asset.url} />
                                {!isAssetEditMode && (
                                    <div 
                                        ref={refs.current[index]} 
                                        className="title" 
                                        style={{
                                            padding: '0.05vw',
                                            backgroundColor: asset.url !== "" ? '#2E3C8D' : 'transparent'
                                        }}
                                        onDoubleClick={() => {
                                            if(creativeType === UNIVERSAL) {
                                                setIsAssetEditMode(true);
                                            }
                                        }}
                                    >
                                        {asset.width + '×' + asset.height}
                                    </div>
                                )}
                                {creativeType === UNIVERSAL && isAssetEditMode && (
                                    <AssetUpdateInput asset={asset} onClose={() => setIsAssetEditMode(false)} />
                                )}
                            </div>
                        </div>
                    );
                } else {
                    return !creative.videoOnly && (
                        <div className='avatar-container' key={asset.uiId || asset.id}>
                            <Box sx={{ position: 'relative', width: '100%' }}>
                                {creativeType === UNIVERSAL && (
                                    <button onClick={() => deleteAssetHandler(asset)} style={{ position: 'absolute', top: 0, right: '4px', padding: 0, fontSize: '0.7vw !important', border: 'none', backgroundColor: 'transparent', lineHeight: 1 }}>X</button>
                                )}
                                <p className='img-type'>{assetTitle}</p>
                            </Box>
                            <div className="avatar-action-box img-placeholder">
                                <ImageInput elemId={asset.uiId || asset.id} maxSize={asset.maxSize} addImage={(e) => changeImageCustoms(e, asset)} image={asset.url} />
                                {!isAssetEditMode && (
                                    <div
                                        className="title"
                                        onMouseDown={() => handleItemFocus(asset, index)}
                                        style={{
                                            padding: '0.05vw',
                                            backgroundColor: asset.url !== "" ? '#2E3C8D' : 'transparent'
                                        }}
                                        onDoubleClick={() => {
                                            if(creativeType === UNIVERSAL) {
                                                setIsAssetEditMode(true);
                                            }
                                        }}
                                    >
                                        {asset.width + '×' + asset.height}
                                    </div>
                                )}
                                {creativeType === UNIVERSAL && isAssetEditMode && (
                                    <AssetUpdateInput asset={asset} onClose={() => setIsAssetEditMode(false)} />
                                )}
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    )

}