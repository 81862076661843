export const OPEN_SANS = 'OpenSans';
const RUBIK = 'Rubik';
const HEEBO = 'Heebo';
const LEXEND = 'Lexend';
const ARIMO = 'Arimo';
const VARELA_ROUND = 'VarelaRound';
const ASSISTANT = 'Assistant';
const SECULAR_ONE = 'SecularOne';
const M_PLUS_ROUNDED_1C = 'MPlusRounded1c';

const fonts = [
    {
        id: OPEN_SANS,
        name: 'Open Sans',
        value: OPEN_SANS,
        import:"@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');",
        css:"'Open Sans', sans-serif"
    },
    {
        id: RUBIK,
        name: 'Rubik',
        value: RUBIK,
        import:"@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');",
        css:"'Rubik', sans-serif"
    },
    {
        id: HEEBO,
        name: 'Heebo',
        value: HEEBO,
        import:"@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');",
        css:"'Heebo', sans-serif"
    },
    {
        id: LEXEND,
        name: 'Lexend',
        value: LEXEND,
        import:"@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');",
        css:"'Lexend', sans-serif"
    },
    {
        id: ARIMO,
        name: 'Arimo',
        value: ARIMO,
        import:"@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');",
        css:"'Arimo', sans-serif"
    },
    {
        id: VARELA_ROUND,
        name: 'Varela Round',
        value: VARELA_ROUND,
        import:"@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');",
        css:"'Varela Round', sans-serif"
    },
    {
        id: ASSISTANT,
        name: 'Assistant',
        value: ASSISTANT,
        import: "@import url('https://fonts.googleapis.com/css2?family=Assistant&display=swap');",
        css:"'Assistant', sans-serif"
    },
    {
        id: SECULAR_ONE,
        name: 'Secular One',
        value: SECULAR_ONE,
        import: "@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');",
        css:"'Secular One', sans-serif"
    },
    {
        id: M_PLUS_ROUNDED_1C,
        name: 'M PLUS Rounded 1c',
        value: M_PLUS_ROUNDED_1C,
        import: "@import url('https://fonts.googleapis.com/css2?family=Assistant&display=swap');",
        css:"'M PLUS Rounded 1c', sans-serif"
    }
];

export const FONT_MAP = fonts.reduce(function(map, obj) {
    map[obj.id] = obj;
    return map;
}, {});

export default fonts;