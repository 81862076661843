import React, { useState, useEffect, useCallback } from "react";
import { InputAdornment, Stack, TextField } from '@mui/material';
import { FormatColorTextRounded } from '@mui/icons-material';
import './inputs.scss';

function TextInput(props) {
  const value = props.value ? props.value : '';
  const classNames = props.className ? 'ds-mui-inputs ds-txt-input ' + props.className : 'ds-mui-inputs ds-txt-input';
  const [valueState, setValueState] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const validation = props.validation;

  const handleOnChange = (event) => {
    setValueState(event.target.value);
    props.onComplete(event.target.value);
  };

  const handleValidation = useCallback((val) => {
    if (val !== '') setIsValid(true);
    else if (validation && typeof (validation) === 'function') setIsValid(validation(val));
    else setIsValid(true);
  }, [validation]);

  useEffect(() => {
    if (props.value && props.value !== valueState) {
      const newVal = props.value;
      setValueState(newVal);
      handleValidation(newVal);
    }
  }, [props.value, valueState, setValueState, handleValidation]);

  const icons = {
    'color': <FormatColorTextRounded />,
  }

  const icon = icons[props.icon];

  return (
    // <div className={`${classNames}${isValid ? '' : ' error'} rounded-40`}>
    //     <input className={props.inputClassName} onChange={handleOnChange} value={valueState} type="text" placeholder={props.placeholder} />
    // </div>
    <Stack alignItems={'flex-start'} spacing={1}>
      <label 
        style={{
          fontSize: '1.1vw',
          fontWeight: 'bold', 
          fontFamily: 'Lexend',
          lineHeight: '2vw',
        }}
      >
          {props.label}
      </label>
      <TextField
        className={classNames}
        onChange={handleOnChange}
        value={valueState}
        type="text"
        label={props.placeholder}
        InputProps={{
          startAdornment:
            props.icon && (
              <InputAdornment position="start">
                {icon}
              </InputAdornment>
            )
        }}
        variant="outlined"
        error={!isValid}
        color={props.color || "info"}
        sx={{
          width: '100%',
          fontFamily: 'Lexend',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid #35A7E0',
              borderRadius: '10vw',
            },
            '& legend': {
              display: 'none', // Hide the legend
            },
            '& input': {
              fontSize: '1vw', 
              padding: '0.6vw 1.2vw',
              height: '1.883vw',
              fontFamily: 'Lexend',
            },
          }
        }}
      />
    </Stack>
  )
}

function areEqual(prevProps, nextProps) {
  /* return true if value is not updated,  otherwise return false */
  if (prevProps.value === nextProps.value && prevProps.itemId === nextProps.itemId) {
    return true;
  }
  return false;
}
export default React.memo(TextInput, areEqual);