import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import { toast } from "react-toastify";

import Button from "../Input/Button";
import DropDown from "../Input/DropDown";
import TextInput from "../Input/mui_inputs/TextInput";
import ChipsInput from "../Input/mui_inputs/ChipsInput";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { MAKO, WALLA, YNET, ONE, ISRAEL_HAYOM, SPORT5, GLOBS, THE_MARKER, BIZPORTAL, CALCALIST } from "../../constants/media";
import { GENERATE_TAG, TAGS } from "../utils/options/tags";

import {
  // CUSTOM_BANNER,
  HALF_HALF,
  INTERSTITIAL,
  INLINE,
  VIDEO_INTERSTITIAL,
  GALLERY,
  MOBILE_BANNER_VIDEO,
  FLIPPER,
  SCRATCH,
  BOUNCE_GAME,
  VIDEO_TIMER_BANNER,
  DESKTOP_TIMER_BANNER,
  // VIDEO_GALLERY,
  COLLECT_OR_EVADE_GAME,
  PROGRAM_VIDEO,
  HALF_VIDEO,
  // UNIVERSAL
} from "../../constants/creativeTypes";

import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { makeStyles } from "@mui/styles";

const MEDIA_OPTIONS = [
  {
    id: MAKO,
    name: MAKO.toLowerCase(),
    emails: ["Interactive_Media@keshet-tv.com"],
  },
  {
    id: YNET,
    name: YNET.toLowerCase(),
    emails: ["ynetmedia@yedioth.co.il"],
  },
  {
    id: WALLA,
    name: WALLA.toLowerCase(),
    emails: [
      "media@walla.net.il",
      "boris_b@walla.net.il",
      "tamir_a@walla.net.il",
    ],
  },
  {
    id: ONE,
    name: ONE.toLowerCase(),
    emails: [
      "oded@one.co.il",
      "traffic@one.co.il",
      "adi@one.co.il",
    ],
  },
  {
    id: ISRAEL_HAYOM,
    name: ISRAEL_HAYOM.toLowerCase(),
    emails: [
      'LiorYit@israelhayom.co.il',
      'ShaniB@israelhayom.co.il'
    ],
  },
  {
    id: SPORT5,
    name: SPORT5.toLowerCase(),
    emails: [
      'dorib@rge.co.il',
      'Udi_f@rge.co.il'
    ],
  },
  {
    id: GLOBS,
    name: GLOBS.toLowerCase(),
    emails: [
      'globesmedia@globes.co.il',
      // 'shmulik@globes.co.il'
    ],
  },
  {
    id: THE_MARKER,
    name: THE_MARKER.toLowerCase(),
    emails: [
      'luba.reshef@themarker.com',
    ],
  },
  {
    id: BIZPORTAL,
    name: BIZPORTAL.toLowerCase(),
    emails: [
      'alon@positivemobile.co.il',
      'eyals@bizportal.co.il',
    ],
  },
  {
    id: CALCALIST,
    name: CALCALIST.toLowerCase(),
    emails: [
      'MediaCalcalist@yedioth.co.il',
    ],
  },
];

const interstitialCreatives = [
  INTERSTITIAL,
  HALF_HALF,
  VIDEO_INTERSTITIAL,
  GALLERY,
  FLIPPER,
  MOBILE_BANNER_VIDEO,
  SCRATCH,
  BOUNCE_GAME,
  VIDEO_TIMER_BANNER,
  COLLECT_OR_EVADE_GAME,
  PROGRAM_VIDEO,
  HALF_VIDEO,
];
const inlineCreatives = [INLINE];
const elvisCreatives = [DESKTOP_TIMER_BANNER];
const billboardCreatives = [];

const useStyles = makeStyles((theme) => ({
  proceedBtn: {
    padding: '0.1rem 1rem',
  },
  fontFamily: {
    fontFamily: 'Lexend',
  },
}));

const GenerateTagPopup = ({
  uniqueUser,
  selectedRow,
  onKeyDown,
  onDiscard,
}) => {
  const classes = useStyles();

  const [selectedMedia, setSelectedMedia] = useState(MEDIA_OPTIONS[0].id);
  const [subject, setSubject] = useState("");
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [toEmails, setToEmails] = useState([]);
  const [emailWasSent, setEmailWasSent] = useState(false);

  const user = useSelector((state) => state.user);
  // const creative = useSelector((state) => state.creative);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  useEffect(() => {
    const creativeTemplateType =
      selectedRow.type === "interstitial" ? "static" : selectedRow.type;
    const creativePreviewType = interstitialCreatives.includes(selectedRow.type)
      ? "interstitial"
      : inlineCreatives.includes(selectedRow.type)
      ? "inline"
      : elvisCreatives.includes(selectedRow.type)
      ? "elvis"
      : billboardCreatives.includes(selectedRow.type)
      ? "billboard"
      : "interstitial";

    // Your initial text here
    const initialText = `
            היי לכולם,
            מצרף תגים למעברון ${selectedRow.type} של קמפיין ${selectedRow.creativeName}.
            לחיצה על המעברון מעביר לדף נחיתה
            
            מתי ניתן לעלות לבדיקות בבקשה?

            משרד פרסום: פובליסיס.
            קישור לעמוד תצוגה:
            https://adsil1.com/demos/settings/preview/?src=https://adsil1.com/ads/${creativeTemplateType}/?id=${selectedRow.creativeId}/&name=${selectedRow.creativeName}&type=${creativePreviewType}

            *עלייה לאוויר באישור הלקוח בלבד.
        `;

    // Create ContentState from the initial text
    const initialContentState = ContentState.createFromText(initialText);

    // Create EditorState with the initial ContentState
    const initialEditorState =
      EditorState.createWithContent(initialContentState);

    // Set the initial EditorState
    setEditorState(initialEditorState);

    const date = new Date();
    const today = `${date.getDate()}.${
      date.getMonth() + 1
    }.${date.getFullYear()}`;
    let selectedMediaOption;
    if (typeof selectedMedia === "string") {
      selectedMediaOption = MEDIA_OPTIONS.find(
        (mediaOption) => mediaOption.id === selectedMedia
      );
    } else {
      selectedMediaOption = MEDIA_OPTIONS.find(
        (mediaOption) => mediaOption.id === selectedMedia.id
      );
    }

    setSubject(
      `${today} - ${selectedRow.creativeName} - ${selectedRow.type} - ${selectedMediaOption.name}`
    );
    setToEmails([...selectedMediaOption.emails, "media@dolphinsoft.co.il"]);
  }, [selectedMedia, selectedRow]);

  useEffect(() => {
    if (uniqueUser) {
      //? user.email = test@mako.co.il
      const firstSplit = user.email.split("@")[1]; //? mako.co.il
      const secondSplit = firstSplit.split(".")[0]; //? mako
      const userMedia = MEDIA_OPTIONS.filter(
        (option) => secondSplit === option.name
      )[0];
      setSelectedMedia(userMedia);
    }
  }, [uniqueUser, user.email]);

  const handleSelectedMediaChange = (value) => {
    setSelectedMedia(value);
  };

  const generateTagHandler = (e) => {
    e.preventDefault();
    GENERATE_TAG(
      typeof selectedMedia === "string" ? selectedMedia : selectedMedia.id,
      selectedRow.creativeName,
      selectedRow.type,
      selectedRow.id,
      true
    );
    onDiscard();
  };

  const sendTagToEmailHandler = () => {
    setOpenEmailDialog(true);
  };

  const cancelTagToEmailHandler = () => {
    setOpenEmailDialog(false);
  };

  const getTextFromEditor = () => {
    const contentState = editorState.getCurrentContent();
    const editorText = contentState.getPlainText();
    return editorText;
  };

  const handleSubjectChange = (value) => {
    setSubject(value);
  };

  const sendEmailHandler = () => {
    if (toEmails.length === 0) {
      toast.error(
        "Please enter at least one valid email address and type Enter"
      );
      return;
    }

    setEmailWasSent(true);

    const fileContent = GENERATE_TAG(
      typeof selectedMedia === "string" ? selectedMedia : selectedMedia.id,
      selectedRow.creativeName,
      selectedRow.type,
      selectedRow.id
    );
    const selectedMediaName =
      typeof selectedMedia === "string" ? selectedMedia : selectedMedia.name;

    // Create a new FormData object
    const formData = new FormData();
    // Encode the filename to ensure it contains only URL-safe characters
    const encodedFileName = encodeURIComponent(
      `${selectedMediaName}-${selectedRow.creativeName}-${selectedRow.type}.txt`
    );
    // Append the file content as an attachment to the FormData
    formData.append("attachments", fileContent, encodedFileName);

    // Append other data to the FormData
    formData.append("subject", subject);
    formData.append(
      "body",
      `
            ${getTextFromEditor()}
        `
    );
    toEmails.forEach((email) => {
      formData.append("to", email);
    });

    // Send the request using fetch
    fetch("https://dolphin-mailer-h5obe.ondigitalocean.app/emailService", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Email has been sent successfully");
          cancelTagToEmailHandler();
          onDiscard();
        } else {
          toast.error("Failed to send email!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred while sending the email");
      });

    // //Spotad Server
    // var xhttp_spotad = new XMLHttpRequest();
    // var url_spotad = "https://dolphin-mailer-h5obe.ondigitalocean.app/emailService"
    // xhttp_spotad.open("POST", url_spotad, true);
    // xhttp_spotad.setRequestHeader("Content-type", "application/json; charset=utf-8");
    // xhttp_spotad.setRequestHeader("Authorization", `Bearer ${user.token}`);
    // //xhttp_spotad.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    // xhttp_spotad.onreadystatechange = function () {
    //     if (xhttp_spotad.readyState == 4 && xhttp_spotad.status == 200) {
    //         toast.success("Email has been sent successfully");
    //         cancelTagToEmailHandler();
    //         onDiscard();
    //     } else if (xhttp_spotad.readyState == 4 && xhttp_spotad.status != 200) {
    //         toast.error("Failed to send email!");
    //     }
    // };
    // xhttp_spotad.send(formData);
  };

  const wrapperStyle = {
    width: "60vw",
  };

  const editorStyle = {
    minHeight: "10vw",
    maxHeight: "10vw",
    width: "60vw",
  };

  const toolbarConfig = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    textAlign: {
      options: ["left", "center", "right", "justify"],
    },
    history: {
      options: ["undo", "redo"], // Add undo and redo buttons
    },
  };

  const filteredMediaOptions = uniqueUser ? MEDIA_OPTIONS.filter(
                    (option) => option.id === selectedMedia.id
                  ) :
                  MEDIA_OPTIONS.filter(
                                        // if the creative type is like interstitial                         // if the creative type exists in tag media                    // if creative type is destop_timer_banner use placard or billboard
                    option => (interstitialCreatives.includes(selectedRow.type) && TAGS[option.id].interstitial) || TAGS[option.id][selectedRow.type] || (selectedRow.type === DESKTOP_TIMER_BANNER && (TAGS[option.id].placard || TAGS[option.id].billboard))
                  );

  return (
    <>
      <Dialog
        onKeyDown={onKeyDown}
        onBackdropClick={onDiscard}
        open={true}
        PaperProps={{
          sx: {
            maxWidth: "unset",
            maxHeight: "unset",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
					Generate Tag
				</DialogTitle>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: "1vw" }}
        >
          <DropDown
            options={filteredMediaOptions}
            disabled={uniqueUser}
            value={
              typeof selectedMedia === "string"
                ? selectedMedia
                : selectedMedia.id
            }
            onChange={handleSelectedMediaChange}
            label="Media"
          />
        </DialogContent>
        <DialogActions sx={{ gap: "2vw" }}>
          <Button
            autoFocus
            onClick={sendTagToEmailHandler}
            variant="contained"
            disabled={!selectedMedia}
            className={`${classes.fontFamily} ${classes.proceedBtn}`}
          >
            Send Tag
          </Button>
          <Button
            autoFocus
            onClick={generateTagHandler}
            variant="outlined"
            disabled={!selectedMedia}
						className={`${classes.fontFamily} ${classes.proceedBtn}`}
          >
            Download Tag
          </Button>
          <Button 
            onClick={onDiscard} 
            variant="text"
            className={classes.fontFamily}  
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* 
                Email Dialog 
            */}
      <Dialog
        onKeyDown={onKeyDown}
        onBackdropClick={cancelTagToEmailHandler}
        open={openEmailDialog}
        PaperProps={{
          sx: {
            maxWidth: "unset",
            maxHeight: "unset",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Send Tag to Email</DialogTitle>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: "1vw" }}
        >
          <TextInput
            label={"Subject"}
            value={subject}
            onComplete={handleSubjectChange}
            itemId={"subjectNameInput"}
          />
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            wrapperStyle={wrapperStyle}
            editorStyle={editorStyle}
            toolbar={toolbarConfig}
          />
          <ChipsInput
            className="generate-tag-email-chip"
            fullWidth
            variant="outlined"
            id="tags"
            name="tags"
            placeholder="add Tags"
            label="tags"
            chips={toEmails || ""}
            setChips={setToEmails}
          />
        </DialogContent>
        <DialogActions sx={{ gap: "2vw", justifyContent: 'flex-start' }}>
          <Button
            autoFocus
            onClick={sendEmailHandler}
            variant="contained"
            disabled={toEmails.length === 0 || emailWasSent}
						className={`${classes.fontFamily} ${classes.proceedBtn}`}
          >
            Send
          </Button>
          <Button 
						onClick={cancelTagToEmailHandler} 
						variant="text"
						className={classes.fontFamily}
					>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenerateTagPopup;
