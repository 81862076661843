import React from 'react';
import './shape.scss';
import { getShapeProperties } from '../../utils.js';


function Shape (props) {
    const classNames = `ds-shape ${props.shapeType.toLowerCase()} ${props.selected? 'selected': ''}`;// ${props.className?props.className:null}
    const shapeProps = getShapeProperties(props.shapeType, props.color, props.image, props.borderRadius);

    const handleOnClick = () =>{
        props.onClick(props);
    };

    return (
        <div className={classNames} style={shapeProps.style} onClick={handleOnClick}></div>
    )
}

function areEqual(prevProps, nextProps) {
    /* return true if value is not updated,  otherwise return false */
    if (prevProps.color === nextProps.color && prevProps.image === nextProps.image && prevProps.selected === nextProps.selected && prevProps.borderRadius === nextProps.borderRadius) {
        return true;
    }
    return false;
}
export default React.memo(Shape, areEqual);