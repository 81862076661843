import * as actionTypes from '../action';

const initialState = {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    token: null,
    users: [],
    clients: [],
    campaigns: [],
    roles: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                id: action.id,
                firstName: action.firstName,
                lastName: action.lastName,
                email: action.email,
                token: action.token,
                roles: [...action.roles]
            }
        case actionTypes.CLEAR_USER:
            return {
                ...state,
                id: null,
                firstName: null,
                lastName: null,
                email: null,
                token: null,
                clients: [],
                campaigns: [],
                roles: []
            }
        case actionTypes.SET_USERS:
            return {
                ...state,
                users: [...action.users]
            }
        case actionTypes.SET_CLIENTS:
            return {
                ...state,
                clients: [...action.clients]
            }
        case actionTypes.SET_CAMPAIGNS:
            return {
                ...state,
                campaigns: [...action.campaigns]
            }
        default:
            return state;
    }

}

export default reducer;