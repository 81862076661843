import * as actionTypes from '../action';

const initialState = {
    showBannerPreview:false,
    showConfirmPopUp: false,
    showShapePopUp:false,
    confirmCallback: null,
    discardCallback: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ENABLE_CONFIRM_POPUP:
             return {
                 ...state,
                 showConfirmPopUp: true,
                 confirmCallback: action.confirmCallback,
                 discardCallback: action.discardCallback,
                 content: action.content
             }
        case actionTypes.DISABLE_CONFIRM_POPUP:
             return {
                 ...state,
                 showConfirmPopUp: false,
                 confirmCallback: null,
                 discardCallback: null
             }
        case actionTypes.SHOW_BANNER_PREVIEW:
             return {
                 ...state,
                 showBannerPreview: true
             }
        case actionTypes.HIDE_BANNER_PREVIEW:
             return {
                 ...state,
                 showBannerPreview: false
             }
        case actionTypes.SHOW_SHAPE_POPUP:
             return {
                 ...state,
                 showShapePopUp: true,
                 confirmCallback: action.confirmCallback,
                 discardCallback: action.discardCallback,
             }
        case actionTypes.HIDE_SHAPE_POPUP:
             return {
                 ...state,
                 showShapePopUp: false,
                 confirmCallback: null,
                 discardCallback: null
             }
            default:
                return state;
    }

}

export default reducer