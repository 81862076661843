import React from 'react';
import './style.scss';
import * as utils from '../../utils';
import placeholder from '../../Components/utils/images/avatar_box_placeholder.png';
import divider from '../../Components/utils/images/divider.png';
import { toast } from 'react-toastify';

export default function ImageInput(props) {
    const classNames = props.className ? 'ds-inputs ds-image ' + props.className : 'ds-inputs ds-image';
    const inputId = 'dsImage_' + props.elemId;
    const imgUrl = props.image ? props.image : placeholder;
    const maxSizeDisplay = props.maxSize ? utils.formatBytes(props.maxSize) : '';
    async function onInput(e) {
        if (!e.target.files[0] || !e.target.files[0].size) {
            return;
        }
        let fileSize = e.target.files[0].size;
        if (props.maxSize && fileSize > props.maxSize) {
            let formattedBytes = utils.formatBytes(fileSize);
            toast.error(`Error: Image file size(${formattedBytes}) exceeds allowed size (${maxSizeDisplay})`, { theme: "colored" });
            return;
        }
        let imageData = await utils.processImage(e);
        props.addImage(imageData);
    }

    const defaultImageStyles = {
        backgroundColor: "#9BD4F5", 
        backgroundImage: `url(${imgUrl})`, 
    };

    const placeholderStyles = {
        backgroundSize: '2.8vw',
        backgroundPosition: 'center center',
    };

    const imageStyles = {
        backgroundSize: "cover", 
        backgroundPosition: "center center"
    };

    const combinedStyles = props.image && props.image !== placeholder && props.image !== divider ? 
    { ...defaultImageStyles, ...imageStyles } : 
    { ...defaultImageStyles, ...placeholderStyles };

    return (
        <label 
            htmlFor={inputId} 
            className={classNames} 
            style={combinedStyles}
        >
            {/* <RiImageAddFill /> */}
            <input onInput={onInput} id={inputId} type="file" accept="image/*" />
        </label >
    )
}