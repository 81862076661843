import React from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import CreativeGrid from '../Grid/CreativeGrid';
import { CREATIVE } from '../../constants/path';
import { toast } from 'react-toastify';
import { deleteCreative } from '../../api_requests';

import { SET_LEFT_DRAWER_CURRENT_TAB } from '../../store/action';

export default function CampaignsPage(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const authToken = useSelector(state => state.user.token);
    const uniqueUsers = ['0575507a-78e9-4959-9bb9-2d069974c128', '62d599d1-3380-44ba-9158-bcf58795a7b0', 'c94c112e-8597-4f9f-9890-3a098bf59120', '18222015-3ae8-4336-89d0-e5ed7574215d'];
    const uniqueUser = uniqueUsers.includes(user.id);

    const handleCreativeEdit = (id) => {
        dispatch({ type: SET_LEFT_DRAWER_CURRENT_TAB , value: 1 });
        navigate(CREATIVE + '/' + id);
    };

    const handleCreativeDelete = (id, callback) => {
        deleteCreative(authToken, id, (data) => {
            if (data.error !== 0) {
                toast.error('Error: ' + data.message);
                return;
            }
            callback(id);
        });
    };

    return (
        <div className={`page-container grid-page ${props.gridFullPage ? "full-page-grid" : ""}`}>
            <CreativeGrid uniqueUser={uniqueUser} editCreative={handleCreativeEdit} deleteCreative={handleCreativeDelete} clients={user.clients} campaigns={user.campaigns} />
        </div >
    )
}