import './style.scss';
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { VIDEO } from '../../constants/imageTypes';
// import videoImages from "../utils/images/video_buttons.json";
// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';

export default function ProgramVideo(props) {
    const videoRef = useRef(null);

    const playVideo = useSelector(state => state.localState.playVideo);

    // const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);
    const bgColor = useSelector(state => state.creative.backgroundColor);
    const assets = useSelector(state => state.creative.assets);

    const gridRowsNum = useSelector(state => state.creative.programVideo.gridRowsNum);
    const gridColumnsNum = useSelector(state => state.creative.programVideo.gridColumnsNum);

    const video = assets?.find(asset => asset.type.toLowerCase() === VIDEO && asset.height === 1080 && asset.width === 640);

    const bannerRatio = 0.5;

    const bannerStyle = {
        backgroundColor: bgColor,
        minHeight: height * 0.2,
        minWidth: height * 0.2 * bannerRatio,
        width: '100%',
        height: '100%',
        borderRadius: '1.5vw',
        position: 'relative'
    };
    const videoStyle = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    }

    // const isVideoPlaying = video => !!(video?.currentTime > 0 && !video?.paused && !video?.ended && video?.readyState > 2);

    useEffect(() => {
        if (videoRef.current) {
            if (playVideo) videoRef.current.play();
            else videoRef.current.pause();
        }
    }, [videoRef.current, playVideo]);

    const gridItems = [];

    const totalItems = gridRowsNum * gridColumnsNum;
    if (gridRowsNum && gridColumnsNum) {
        for (let gridItem = 0; gridItem < totalItems; gridItem++) {
        const isFirst = gridItem === 0;
        const isLast = gridItem === totalItems - 1;
        const isFirstInRow = gridItem % gridColumnsNum === 0;
        const isLastInRow = (gridItem + 1) % gridColumnsNum === 0;
        const isLastRow = Math.floor(gridItem / gridColumnsNum) === gridRowsNum - 1;
        const isFirstRow = Math.floor(gridItem / gridColumnsNum) === 0;

        const classNames = ['program-video-grid-item'];
        if (isFirst) classNames.push('top-left');
        if (isLast) classNames.push('bottom-right');
        if (isFirstInRow && isLastRow) classNames.push('bottom-left');
        if (isLastInRow && isFirstRow) classNames.push('top-right');
            gridItems.push(<div key={gridItem} className={classNames.join(' ')}></div>);
        }
    }

    return (
        // <div className="banner-display-container grid-content">
        <div 
            className="banner-display-container interstitial-border" 
            style={{ 
                // backgroundImage: `url(${IphoneFrameImage})`, 
                width: '13vw', 
                height: '26vw',
                backgroundColor: 'white',
                borderRadius: '2vw',
            }}
        >
            <img className='banner-display-frame-image' alt='' /> {/* src={IphoneFrameFrontCameraImage} /> */}
            <div className="banner-box">
                <div style={bannerStyle}>
                    <div className='program-video-grid' style={{ display: !gridRowsNum || !gridColumnsNum ? 'none' : 'grid', gridTemplateColumns: `repeat(${gridColumnsNum}, 1fr)`, gridTemplateRows: `repeat(${gridRowsNum}, 1fr)` }}>
                        {gridItems}
                    </div>
                    {video.url && (
                        <video autoPlay={false} muted loop ref={videoRef} src={video?.url} style={videoStyle}>
                            <source type="video/mp4" />
                        </video>
                    )}
                </div>
            </div>
        </div>
    )
}

