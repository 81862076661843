import * as actionTypes from '../action';


const initialState = {
    showImageMenu:false,
    showTextMenu:false,
    showShapeMenu:false,
    leftDrawerState: {
        close: true,
        currentTab: 0
    },
    gridPageContent: 'carousel',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_IMAGE_MENU_STATE:
             return {
                 ...state,
                 showImageMenu: action.value
             }
        case actionTypes.SET_TEXT_MENU_STATE:
             return {
                 ...state,
                 showTextMenu: action.value
             }
        case actionTypes.SET_SHAPE_MENU_STATE:
             return {
                 ...state,
                 showShapeMenu: action.value
             }
        case actionTypes.SET_LEFT_DRAWER_CLOSE:
             return {
                 ...state,
                 leftDrawerState: {
                    ...state.leftDrawerState,
                    close: action.value
                 }
             }
        case actionTypes.SET_LEFT_DRAWER_CURRENT_TAB:
             return {
                 ...state,
                 leftDrawerState: {
                    ...state.leftDrawerState,
                    currentTab: action.value
                 }
             }
        case actionTypes.SET_GRID_PAGE_CONTENT:
             return {
                 ...state,
                 gridPageContent: action.value
             }
         default:
             return state;
    }

}

export default reducer