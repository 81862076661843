import React, { useState } from 'react';
import './style.css';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
// import InlineLoader from '../Layout/InlineLoader';
import NewCampaign from '../PopUp/NewCampaign';
import { GrFormAdd } from "react-icons/gr";

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    grid_title: {
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold !important',
        }
    },
    green: {
        color: 'green',
    },
    red: {
        color: 'red',
    }
}));

function ClientGrid(props) {
    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuTop, setMenuTop] = useState(0);
    const [menuLeft, setMenuLeft] = useState(0);
    const [selected, setSelected] = useState(null);
    const [userPoPupState, setUserPoPupState] = useState(false);

    const [sortModel, setSortModel] = useState([
        {
            field: 'firstName',
            sort: 'asc',
        },
    ]);

    const formatDate = (date) => {
        const dateObj = new Date(date);
        // let month = '' + (date.getMonth() + 1 + Math.floor(Math.random() * 4)),
        //     day = '' + (date.getDate() + Math.floor(Math.random() * 27)),
        let month = '' + (dateObj.getMonth() + 1),
            day = '' + dateObj.getDate(),
            year = dateObj.getFullYear(),
            hour = dateObj.getHours(),
            minute = dateObj.getMinutes();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (hour < 10)
            hour = '0' + hour;
        if (minute < 10)
            minute = '0' + minute;

        return [day, month, year].join('-') + ' ' + [hour, minute].join(':');
    }

    const rows = props.rows;

    const columns = [
        { field: 'firstName', headerName: 'First Name', headerClassName: classes.grid_title, flex: 1 },
        { field: 'email', headerName: 'Email', headerClassName: classes.grid_title, flex: 1 },
        { field: 'roles', headerName: 'Role', headerClassName: classes.grid_title, flex: 1 },
        {
            field: 'isActive',
            headerName: 'Status',
            headerClassName: classes.grid_title,
            flex: 1,
            renderCell: (params) => {
                const statusClassName = params.value === true ? classes.green : classes.red;
                return <div className={` ${statusClassName}`}>{params.value ? "Active" : "Inactive"}</div>;
            },
        },
        {
            field: 'created', 
            headerName: 'Date', 
            headerClassName: classes.grid_title, 
            flex: 1,
            valueFormatter: (params) => formatDate(params.value),
        },
    ];

    const handleRowClick = (e) => {
        setSelected(rows.find(row => row.id === e.currentTarget.getAttribute('data-id')));
        setMenuOpen(true)
        setMenuTop(e.clientY);
        setMenuLeft(e.clientX);
    };

    const handleClickAway = (e) => {
        setMenuOpen(false);
    };

    const handleAddUser = (e) => {
        setMenuOpen(false);
        setUserPoPupState(true);
    };

    const handleUserPopUpDiscard = (e) => {
        setUserPoPupState(false);
    };

    const handleUserPopUpConfirm = (user) => {
        user.clientId = selected.id;
        props.addUser(user);
        setUserPoPupState(false);
    };

    const customToolbar = () => {
        return (
            <GridToolbarContainer sx={{ backgroundColor: '#9BD4F5', padding: '4px 8px 4px 8px', borderRadius: '10px 10px 0 0', gap: '1vw' }}>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="grid" onContextMenu={(e) => e.preventDefault()}>
                {/* {props.apiData ? null : <InlineLoader />} */}
                <DataGrid
                    components={{
                        Toolbar: customToolbar,
                    }}
                    loading={!props.apiData}
                    rows={rows}
                    columns={columns}
                    disableMultipleSelection={true}
                    disableColumnSelector
                    // onRowClick={handleRowClick}
                    autoPageSize
                    onCellFocusOut={handleClickAway}
                    hideFooterSelectedRowCount
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    componentsProps={{
                        row: {
                            onContextMenu: handleRowClick,
                            style: { cursor: 'context-menu' },
                        },
                    }}
                />
                {menuOpen ?
                    <MenuList autoFocusItem={menuOpen} className="grid-menu" style={{ top: `calc( ${menuTop}px - 10px)`, left: `calc( ${menuLeft}px - 10px)` }}>
                        <MenuItem disabled onClick={handleAddUser}><GrFormAdd className="icon" />Add User</MenuItem>
                    </MenuList> : null}
                {userPoPupState ? <NewCampaign discardCallback={handleUserPopUpDiscard} confirmCallback={handleUserPopUpConfirm} /> : null}
            </div>
        </ClickAwayListener>
    )
}

function areEqual(prevProps, nextProps) {
    /* return true if value is not updated,  otherwise return false */
    if (prevProps.apiData !== nextProps.apiData || JSON.stringify(prevProps.rows) !== JSON.stringify(nextProps.rows)) {
        return false;
    }
    return true;
}

export default React.memo(ClientGrid, areEqual);

