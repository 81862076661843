import './style.scss';
import React, { useRef } from "react";
import { useSelector } from "react-redux";
// import placeholder from '../../Components/utils/images/avatar_box_placeholder.png';
// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';

export default function CollectOrEvadeGame(props) {
  const outerRef = useRef();

  // const width = useSelector(state => state.creative.bannerWidth);
  const height = useSelector(state => state.creative.bannerHeight);
  const bgColor = useSelector(state => state.creative.backgroundColor);
  const assets = useSelector(state => state.creative.assets);
  const textColor = useSelector(state => state.creative.textColor);

  const background = assets && assets.find(asset => asset.type === 'Background_Image' || asset.type === 'background_image')?.url;
  const title = assets && assets.find(asset => asset.type === 'Title_Image' || asset.type === 'title_image')?.url;
  const subtitle = assets && assets.find(asset => asset.type === 'Subtitle_Image' || asset.type === 'subtitle_image')?.url;
  const movable = assets && assets.find(asset => asset.type === 'Movable_Image' || asset.type === 'movable_image')?.url;
  const leftArrow = assets && assets.find(asset => asset.type === 'Left_Arrow_Image' || asset.type === 'left_arrow_image')?.url;
  const rightArrow = assets && assets.find(asset => asset.type === 'Right_Arrow_Image' || asset.type === 'right_arrow_image')?.url;

  const bannerRatio = 0.5;

  const bannerStyle = {
    backgroundColor: bgColor,
    backgroundImage: background && background !== '' ? `url(${background})` : 'none',
    minHeight: height * 0.25,
    minWidth: height * 0.25 * bannerRatio,
    width: '100%',
    height: '100%',
    borderRadius: '1.5vw',
  };

  return (
        <div 
          className="banner-display-container interstitial-border" 
          style={{ 
              // backgroundImage: `url(${IphoneFrameImage})`, 
              width: '15vw', 
              height: '30vw',
              backgroundColor: 'white',
              borderRadius: '2vw',
          }}
        >
        {/*<div className="banner-display-container grid-content">*/}
        <img className='banner-display-frame-image' alt="" /> {/* src={IphoneFrameFrontCameraImage} /> */}
      <div className="banner-box">
        <div className="target" style={bannerStyle} ref={outerRef}>
          <img className="collect_or_evade_title" src={title} alt="title" style={{ visibility: title && title !== '' ? 'initial' : 'hidden' }} />
          <img className="collect_or_evade_subtitle" src={subtitle} alt="subtitle" style={{ visibility: subtitle && subtitle !== '' ? 'initial' : 'hidden' }} />
          <p className='collect_or_evade_timer' style={{ visibility: subtitle && subtitle !== '' ? 'initial' : 'hidden', color: textColor || 'black' }}>
            00:30
          </p>
          <img className="collect_or_evade_movable" src={movable} alt="movable" style={{ visibility: movable && movable !== '' ? 'initial' : 'hidden' }} />
          <img className="collect_or_evade_arrow left_arrow" src={leftArrow} alt="left arrow" style={{ visibility: leftArrow && leftArrow !== '' ? 'initial' : 'hidden' }} />
          <img className="collect_or_evade_arrow right_arrow" src={rightArrow} alt="right arrow" style={{ visibility: rightArrow && rightArrow !== '' ? 'initial' : 'hidden' }} />
        </div>
      </div>
    </div>
  )
}

