export const DEFAULT = 'default';
export const ONE = 'one';
export const TWO = 'two';
export const THREE = 'three';
export const FOUR = 'four';
export const FIVE = 'five';
export const SIX = 'six';
export const INFINITE = 'infinite';

export const ITERATION_MAP = setDelayMap();

function setDelayMap(){
    let map = {};
    map[DEFAULT] = 'default';
    map[ONE] = '1';
    map[TWO] = '2';
    map[THREE] = '3';
    map[FOUR] = '4';
    map[FIVE] = '5';
    map[SIX] = '10';
    map[INFINITE] = 'infinite';
    return map;
}


const iterations = [
    { id: DEFAULT, name: 'Default', value: ITERATION_MAP[DEFAULT] },
    { id: ONE, name: '1 time', value: ITERATION_MAP[ONE] },
    { id: TWO, name: '2 times', value: ITERATION_MAP[TWO] },
    { id: THREE, name: '3 times', value: ITERATION_MAP[THREE] },
    { id: FOUR, name: '4 times', value: ITERATION_MAP[FOUR] },
    { id: FIVE, name: '5 times', value: ITERATION_MAP[FIVE] },
    { id: SIX, name: '10 times', value: ITERATION_MAP[SIX] },
    {id:INFINITE, name: 'Infinite', value: ITERATION_MAP[INFINITE] }
]

export default iterations;