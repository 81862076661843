import "./style.scss";
// import { useSelector } from "react-redux";
import logo from "../../Components/utils/images/logo.png";
import React/*, { useState }*/ from "react";
// import { CLEAR_USER } from "../../store/action";
// import { tokenKey } from "../../api_requests";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  // LOGIN,
  MAIN,
  // CREATIVE_MANAGEMENT,
  // USERS_MANAGEMENT,
} from "../../constants/path";
// import MenuItem from "@mui/material/MenuItem";
// import MenuList from '@mui/material/MenuList';
// import { RiLogoutBoxRLine } from "react-icons/ri";
// import { ImUser } from "react-icons/im";
// import { MdManageAccounts } from "react-icons/md";
// import { BsGearFill } from "react-icons/bs";
import * as actionTypes from "../../store/action";
import {
  AppBar,
  Avatar,
  // Box,
  Container,
  IconButton,
  // Menu,
  // Tooltip,
} from "@mui/material";

// const pages = ['Products', 'Pricing', 'Blog'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const user = useSelector((state) => state.user);

  // const [anchorElNav, setAnchorElNav] = useState(null);
  // const [anchorElUser, setAnchorElUser] = useState(null);

  // const handleLogOut = () => {
  //   handleCloseUserMenu();
  //   //clear storage & token then navigate to login
  //   localStorage.removeItem(tokenKey);
  //   dispatch({ type: CLEAR_USER });
  //   navigate(LOGIN);
  // };

  const handleRedirectToMainPage = () => {
    dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: false });
    dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: false });
    dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: false });
    dispatch({ type: actionTypes.SET_LEFT_DRAWER_CURRENT_TAB , value: 0 });
    navigate(MAIN);
  };

  // const handleRedirectToUsersManagementPage = () => {
  //   handleCloseUserMenu();
  //   navigate(USERS_MANAGEMENT);
  // };

  // const handleRedirectToCreativeManagementPage = () => {
  //   handleCloseUserMenu();
  //   navigate(CREATIVE_MANAGEMENT);
  // };

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  return (
    <AppBar position="static" component="nav" className="header" sx={{ backgroundColor: "white", boxShadow: "none", height: "70px" }}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.5vw 0",
          height: "100%",
          maxWidth: "100% !important",
        }}
      >
        <IconButton onClick={handleRedirectToMainPage} sx={{ p: 0, }} disableRipple>
          <Avatar
            sx={{ width: "150px", height: "100%", objectFit: "contain" }}
            className="header-logo"
            alt="logo"
            src={logo}
            variant="square"
          />
        </IconButton>
        {/* {user.token && (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open user menu">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt="avatar"
                  sx={
                    {
                      // height: '3vw',
                      // width: '3vw',
                      // fontSize: '1.5vw',
                    }
                  }
                >
                  {user?.firstName[0] +
                    (user?.lastName ? user.lastName[0] : "")}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                className="gap-1"
                onClick={handleRedirectToUsersManagementPage}
              >
                <MdManageAccounts className="icon" />
                Users
              </MenuItem>
              <MenuItem
                className="gap-1"
                onClick={handleRedirectToCreativeManagementPage}
              >
                <BsGearFill className="icon" />
                Creative
              </MenuItem>
              <MenuItem className="gap-1" onClick={handleLogOut}>
                <RiLogoutBoxRLine className="icon" />
                Log Out
              </MenuItem>
            </Menu>
          </Box>
        )} */}
      </Container>
    </AppBar>
  );
}

function areEqual(prevProps, nextProps) {
  /* return true if value is not updated,  otherwise return false */
  if (
    prevProps.firstName !== nextProps.firstName ||
    prevProps.lastName !== nextProps.lastName
  ) {
    return false;
  }
  return true;
}

export default React.memo(Header, areEqual);
