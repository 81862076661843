import * as actionTypes from '../action';


const initialState = {
  sideMenuHeight: 606.62,
  playVideo: false,
  imageCompression: 0.5,
  openImageSettings: false,
  openVideoSettings: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SIDE_MENU_HEIGHT:
      return {
        ...state,
        sideMenuHeight: action.value
      }
    case actionTypes.SET_PLAY_VIDEO:
      return {
        ...state,
        playVideo: action.value
      }
    case actionTypes.SET_IMAGE_COMPRESSION:
      return {
        ...state,
        imageCompression: action.value
      }
    case actionTypes.SET_OPEN_IMAGE_SETTINGS:
      return {
        ...state,
        openImageSettings: action.value
      }
    case actionTypes.SET_OPEN_VIDEO_SETTINGS:
      return {
        ...state,
        openVideoSettings: action.value
      }
    default:
      return state;
  }

}

export default reducer