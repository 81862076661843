import './style.scss';
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { VIDEO } from '../../constants/imageTypes';
import videoImages from "../utils/images/video_buttons.json";
// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';

export default function VideoInterstitial(props) {
    const videoRef = useRef(null);

    const [playState, setPlayState] = useState(true);
    const [muteState, setMuteState] = useState(true);

    const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);
    const bgColor = useSelector(state => state.creative.backgroundColor);
    const assets = useSelector(state => state.creative.assets);
    const mute = useSelector(state => state.creative.mute);
    const play = useSelector(state => state.creative.play);
    const darkMode = useSelector(state => state.creative.darkMode);
    const buttonsPosition = useSelector(state => state.creative.buttonsPosition);

    const video = (assets && assets.length > 0 && assets.find(asset => asset.width === width && asset.height === height && asset.type.toLowerCase() === VIDEO)?.url) || null;

    const pauseBtn = darkMode ? videoImages.black.pause : videoImages.white.pause;
    const playBtn = darkMode ? videoImages.black.play : videoImages.white.play;
    const muteBtn = darkMode ? videoImages.black.mute : videoImages.white.mute;
    const unmuteBtn = darkMode ? videoImages.black.unmute : videoImages.white.unmute;

    const bannerRatio = 0.5;

        const bannerStyle = {
        backgroundColor: bgColor,
        minHeight: height * 0.2,
        minWidth: height * 0.2 * bannerRatio,
        width: '100%',
        height: '100%',
        borderRadius: '1.5vw',
    };

    const videoStyle = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: '1vw'
    }

    const isVideoPlaying = video => !!(videoRef.current?.currentTime > 0 && !videoRef.current?.paused && !videoRef.current?.ended && videoRef.current?.readyState > 2);

    const toggleVideoSound = () => {
        if (videoRef.current.muted) {
            setMuteState(false);
        } else {
            setMuteState(true);
        }
    };

    const toggleVideoPlay = () => {
        if (isVideoPlaying(videoRef.current)) {
            videoRef.current.pause();
            setPlayState(false);
        } else {
            videoRef.current.play();
            setPlayState(true);
        }
    };

    return (
        <div 
            className="banner-display-container interstitial-border" 
            style={{ 
                // backgroundImage: `url(${IphoneFrameImage})`, 
                width: '13vw', 
                height: '26vw',
                backgroundColor: 'white',
                borderRadius: '2vw',
            }}
        >
        {/*<div className="banner-display-container grid-content">*/}
            <img className='banner-display-frame-image' alt='' /> {/* src={IphoneFrameFrontCameraImage} /> */}
            <div className="banner-box">
                <div id="ds_div_buttons" className='video-interstitial' style={{ bottom: buttonsPosition === 'Top' ? '85%' : buttonsPosition === 'Center' ? '45%' : '5%' }}>
                    <img className={`ds_buttons left ${play ? '' : 'd-none'}`} id="sa_play_video" alt="play button" src={playState ? pauseBtn : playBtn} onClick={toggleVideoPlay} />
                    <img className={`ds_buttons right ${mute ? '' : 'd-none'}`} id="sa_play_sound" alt="sound button" src={muteState ? muteBtn : unmuteBtn} onClick={toggleVideoSound} />
                </div>
                <div style={bannerStyle}>
                    <video autoPlay={true} muted={muteState} loop ref={videoRef} src={video} style={videoStyle}>
                        <source type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}

