import { useState } from 'react';

const usePresetModal = () => {
  const [visible, setVisible] = useState(false);
  function toggle() {
    setVisible(!visible);
  }
  return {toggle, visible}
};

export default usePresetModal;