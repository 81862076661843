import { useEffect, useCallback, useState } from "react";

const useContextMenu = (parentRef) => {
    const identifyingClass = "has-context-menu";
    const [xPos, setXPos] = useState("0px");
    const [yPos, setYPos] = useState("0px");
    const [menu, showMenu] = useState(false);
    const [elemId, setElemId] = useState(false);
    const [classList, setClassList] = useState(false);

    const handleContextMenu = useCallback( event => {
            if (parentRef && parentRef.current && parentRef.current.contains(event.target) && event.target.classList.contains(identifyingClass)) {
                event.preventDefault();
                setXPos(`${event.pageX}px`);
                setYPos(`${event.pageY}px`);
                setElemId(event.target.id);
                setClassList(event.target.classList);
                if (event.target.id) {
                    setElemId(event.target.id);
                }
                showMenu(true);
            }
            else {
                showMenu(false);
            }
        },[showMenu, parentRef, setXPos, setYPos]
    );

    const handleClick = useCallback(() => { showMenu(false);}, [showMenu]);

    useEffect(() => {
        document.addEventListener("click", handleClick);
        document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.removeEventListener("click", handleClick);
            document.removeEventListener("contextmenu", handleContextMenu);
        };
    });

    return { xPos, yPos, menu, elemId, classList };
};

export default useContextMenu;
