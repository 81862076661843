import { DIVIDER, LEFT, POSTER, RIGHT, VIDEO, BACKGROUND, BANNER, LOGO, FRONT, BACK } from '../../constants/imageTypes';
import * as actionTypes from '../../store/action'

export function handlePositionRevert(dispatch, item) {
    let actionType;
    switch (item.type.toLowerCase()) {
        case "image":
            actionType = actionTypes.REVERT_POSITION_IMAGE;
            break;
        case "text":
            actionType = actionTypes.REVERT_POSITION_TEXT;
            break;
        case "shape":
            actionType = actionTypes.REVERT_POSITION_SHAPE;
            break;
        default:
            actionType = 'error';
    }
    dispatch({ type: actionType, uiId: item.uiId, transform: item.transform });
}

export function handleDelete(dispatch, uiId, type) {
    let actionType;
    switch (type.toLowerCase()) {
        case "image":
            actionType = actionTypes.DELETE_IMAGE;
            break;
        case "text":
            actionType = actionTypes.DELETE_TEXT;
            break;
        case "shape":
            actionType = actionTypes.DELETE_SHAPE;
            break;
        default:
            actionType = 'error';
    }
    dispatch({ type: actionType, uiId: uiId });
}

export function handleDeleteAllItems(dispatch) {
    dispatch({ type: actionTypes.DELETE_ALL_IMAGES });
    dispatch({ type: actionTypes.DELETE_ALL_TEXTS });
    dispatch({ type: actionTypes.DELETE_ALL_SHAPES });
}

export function handleFocus(dispatch, props, type) {
    const lowerCaseType = type?.toLowerCase();
    const noType = !type || lowerCaseType === RIGHT || lowerCaseType === LEFT || lowerCaseType === DIVIDER || lowerCaseType === VIDEO || lowerCaseType === POSTER || lowerCaseType === FRONT || lowerCaseType === BACK;
    if (noType) return;
    let itemActionType, styleAction;
    let assetType;
    if (lowerCaseType === BACKGROUND || lowerCaseType === BANNER || lowerCaseType === LOGO) assetType = "image";
    else assetType = lowerCaseType;

    switch (assetType) {
        case "image":
            itemActionType = actionTypes.FOCUS_IMAGE;
            styleAction = {
                type: actionTypes.SET_STYLE_IMAGE_FOCUS,
                uiId: props.uiId,
                image: props.image || props.url,
                opacity: props.opacity,
                index: props.index,
                animationDelay: props.animationDelay,
                animationType: props.animationType,
                iterationType: props.iterationType,
                animationDuration: props.animationDuration,
                redirectionUrl: props.redirectionUrl
            };
            break;
        case "text":
            itemActionType = actionTypes.FOCUS_TEXT;
            styleAction = {
                type: actionTypes.SET_STYLE_TEXT_FOCUS,
                uiId: props.uiId,
                text: props.text,
                index: props.index,
                fontSize: props.fontSize,
                fontColor: props.color,
                animationDelay: props.animationDelay,
                animationType: props.animationType,
                iterationType: props.iterationType,
                animationDuration: props.animationDuration,
                redirectionUrl: props.redirectionUrl
            };
            break;
        case "shape":
            itemActionType = actionTypes.FOCUS_SHAPE;
            styleAction = {
                type: actionTypes.SET_STYLE_SHAPE_FOCUS,
                uiId: props.uiId,
                image: props.image,
                color: props.color,
                opacity: props.opacity,
                index: props.index,
                shapeType: props.shapeType,
                animationDelay: props.animationDelay,
                animationType: props.animationType,
                iterationType: props.iterationType,
                animationDuration: props.animationDuration,
                redirectionUrl: props.redirectionUrl,
                borderRadius: props.borderRadius
            }
            break;
        default:
            itemActionType = 'error';
    }
    dispatch({ type: actionTypes.IMAGE_REMOVE_FOCUS });
    dispatch({ type: actionTypes.TEXT_REMOVE_FOCUS });
    dispatch({ type: actionTypes.SHAPE_REMOVE_FOCUS });

    dispatch({ type: itemActionType, uiId: props.uiId });
    dispatch(styleAction);
}

export function handleTransform(dispatch, transform, uiId, type) {
    let actionType;
    switch (type.toLowerCase()) {
        case "image":
            actionType = actionTypes.SET_IMAGE_TRANSFORM;
            break;
        case "text":
            actionType = actionTypes.SET_TEXT_TRANSFORM;
            break;
        case "shape":
            actionType = actionTypes.SET_SHAPE_TRANSFORM;
            break;
        default:
            actionType = 'error';
    }
    dispatch({ type: actionType, value: transform, uiId: uiId });
}
