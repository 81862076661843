//import React, { useState } from 'react';
//import React from 'react';


export default function LoadingIndicator(props){
    return (
        <div className="ds-loader page">
            <div className="icon"></div>
        </div>
    );
}


