import './Sass/App.scss';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConfirmPopUp from './Components/PopUp/ConfirmPopUp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Header from './Components/Layout/Header';
import PrivateRoute from './Components/Routing/PrivateRoute';
import ErrorPage from './Components/Pages/ErrorPage';
import LoginPage from './Components/Pages/LoginPage';
import GridPage from './Components/Pages/GridPage';
import CreativePage from './Components/Pages/CreativePage';
import UsersManagementPage from './Components/Pages/UsersManagementPage';
import CreativeManagementPage from './Components/Pages/CreativeManagementPage';
import CreativePreview from './Components/PopUp/CreativePreview';
import ShapePopUp from './Components/PopUp/ShapePopUp';


import { LOGIN, MAIN, CREATIVE, CREATIVE_MANAGEMENT, USERS_MANAGEMENT, CAMPAIGNS } from './constants/path';
import LeftDrawer from './Components/LeftDrawer/LeftDrawer';
import CampaignsPage from './Components/Pages/CampaignsPage';




function App() {
    const showConfirmPopUp = useSelector(state => state.pop_up.showConfirmPopUp);
    const showBannerPreview = useSelector(state => state.pop_up.showBannerPreview);
    const showShapePopUp = useSelector(state => state.pop_up.showShapePopUp);
    const user = useSelector(state => state.user);

    const charAt = `
    ____        _       _     _       ____         __ _   
    |  _ \\  ___ | |_ __ | |__ (_)_ __ / ___|  ___  / _| |_ 
    | | | |/ _ \\| | '_ \\| '_ \\| | '_ \\\\___ \\ / _ \\| |_| __|
    | |_| | (_) | | |_) | | | | | | | |___) | (_) |  _| |_ 
    |____/ \\___/|_| .__/|_| |_|_|_| |_|____/ \\___/|_|  \\__|
                |_|                                      
    `;

    useEffect(() => {
        console.info(`%c${charAt}`, 'color: #5BE49B');
    }, [charAt]);

    return (
        <div className="App">
            <ToastContainer limit={3} position="top-center" autoClose={3000} hideProgressBar={false} newestOnTop={false} style={{ top: "10%" }} />
                <BrowserRouter>
                    <Header />
                    <main className="main-content-container">
                        {user.token && <LeftDrawer />}
                        <Routes>
                            <Route exact path={LOGIN} element={<LoginPage />} />
                            <Route exact path={MAIN} element={<PrivateRoute><GridPage /></PrivateRoute>} />
                            <Route exact path={CAMPAIGNS} element={<PrivateRoute><CampaignsPage gridFullPage /></PrivateRoute>} />
                            <Route exact path={CREATIVE} element={<PrivateRoute><CreativePage /></PrivateRoute>} />
                            <Route exact path={CREATIVE + "/:id"} element={<PrivateRoute><CreativePage /></PrivateRoute>} />
                            <Route exact path={USERS_MANAGEMENT} element={<PrivateRoute><UsersManagementPage /></PrivateRoute>} />
                            <Route exact path={CREATIVE_MANAGEMENT} element={<PrivateRoute><CreativeManagementPage /></PrivateRoute>} />

                            <Route exact path='*' element={<ErrorPage />} />
                        </Routes>
                    </main>
                </BrowserRouter>
            {showBannerPreview ? <CreativePreview backdrop={showBannerPreview} /> : null}
            {showConfirmPopUp ? <ConfirmPopUp /> : null}
            {showShapePopUp ? <ShapePopUp /> : null}
            <canvas id="canvas" style={{ display: 'none' }}></canvas>
        </div>
    )
}

export default App;
