import { useState } from "react";
import { toast } from "react-toastify";
import { Box, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tabs } from "@mui/material";
import creativeTypes, { DESKTOP_TIMER_BANNER, VIDEO_TIMER_BANNER } from "../utils/options/creativeTypes";
import ChipsInput from "../Input/mui_inputs/ChipsInput";
import { WhatsApp as WhatsAppIcon, ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import Button from "../Input/Button";

import mobileBorderImage from "../utils/images/creative_images/mobile_frame.png";
import desktopBorderImage from "../utils/images/creative_images/Desktop.png";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  proceedBtn: {
    padding: '0.1rem 1rem',
  },
  fontFamily: {
    fontFamily: 'Lexend',
  },
}));

const CreativeExamplePreview = (props) => {
  const classes = useStyles();

  const [toEmails, setToEmails] = useState([]);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [copied, setCopied] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [emailWasSent, setEmailWasSent] = useState(false);

  const horizontal_creatives = ['desktop_timer_banner'];
  const isHorizontalCreative = horizontal_creatives.includes(props.creativeType);

  const creativeTypeObj = creativeTypes.find(obj => obj.id === props.creativeType);

  const creativesWithVariations = [VIDEO_TIMER_BANNER, DESKTOP_TIMER_BANNER];

  let ratio = 1;

  if (creativeTypeObj.size.width / creativeTypeObj.size.height < 1) {
    ratio = 0.5;
  } else {
    ratio = 0.8;
  }

  const size = {
    width: creativeTypeObj.size.width * ratio,
    height: creativesWithVariations.includes(creativeTypeObj.id) ? creativeTypeObj.size.height * ratio : creativeTypeObj.size.height * ratio * 1.1
  }

  const creativeVariationPath = creativesWithVariations.includes(creativeTypeObj.id) ? `/${tabValue}` : '';

  const creativeUrl = `https://adsil1.com/creative_examples/${creativeTypeObj.id}${creativeVariationPath}/?SA_vendor=dolphin&SA_type=app&auto_close=false`;

  const previewType = creativeTypeObj.size.width / creativeTypeObj.size.height < 1 ? 'interstitial' : 'inline';

  const creativePreviewUrl = `https://adsil1.com/demos/settings/preview/?src=${creativeUrl}/&name=dolphin&type=${previewType}`;

  const handleOpenShare = () => {
    setOpenShareDialog(true);
  };

  const handleCloseShare = () => {
    setOpenShareDialog(false);
  };

  const handleEscape = (event) => {
    if (event.key === 'Escape') {
      setOpenShareDialog(false);
    }
  };

  const handleSendEmail = () => {
    if (toEmails.length === 0) {
      toast.error('Please enter at least one valid email address and type Enter');
      return;
    }

    setEmailWasSent(true);

    let userModel = {
      fromEmail: "No-reply@dolphinsoft.co.il",
      subject: `${creativeTypeObj.name} Example`,
      Body: ` 
        <div dir="rtl">
        
          שם קריאייטיב: ${creativeTypeObj.name} <br>
          קישור לתצוגה מקדימה: ${creativePreviewUrl}
      
        </div>
      `,
      toEmails,
    };


    const data = JSON.stringify(userModel);

    //Spotad Server
    var xhttp_spotad = new XMLHttpRequest();
    var url_spotad = "https://www.adsil1.com/EmailService/api/v1/emails"
    xhttp_spotad.open("PUT", url_spotad, true);
    xhttp_spotad.setRequestHeader(
      "Content-type",
      "application/json; charset=utf-8"
    );
    //xhttp_spotad.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    xhttp_spotad.onreadystatechange = function () {
      if (xhttp_spotad.readyState === 4 && xhttp_spotad.status === 200) {
        toast.success("Email has been sent successfully");
        handleCloseShare();
        props.discardCallback();
      } else if (xhttp_spotad.readyState === 4 && xhttp_spotad.status !== 200) {
        toast.error("Failed to send email!");
      }
    };
    xhttp_spotad.send(data);
  };

  const handleWhatsAppShare = () => {
    const text = `
      שם קריאייטיב: ${creativeTypeObj.name}
      קישור לתצוגה מקדימה: 
      ${creativePreviewUrl}
    `;
    const url = `https://wa.me/?text=${encodeURIComponent(text)}`;
    window.open(url, "_blank");
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(creativePreviewUrl);
      setCopied(true);
    } catch (err) {
      toast.error("Failed to copy text: ", err);
    }
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const iframeContainerStyles = {
    backgroundImage: isHorizontalCreative || props.creativeType === 'video_gallery' ? `url(${desktopBorderImage})` : `url(${mobileBorderImage})`, 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: 'contain', 
    width: isHorizontalCreative || props.creativeType === 'video_gallery' ? 'calc(196px * 1.5)' : 'calc(96px * 2)', 
    height: isHorizontalCreative || props.creativeType === 'video_gallery' ? 'calc(160px * 1.5)' : 'calc(192px * 2)', 
    alignItems: isHorizontalCreative || props.creativeType === 'video_gallery' ? 'flex-start' : 'center', 
    justifyContent: 'center', 
    display: 'flex',
    // transform: isHorizontalCreative || props.creativeType === 'video_gallery' ? 'rotate(-90deg)' : 'unset',
    paddingTop: isHorizontalCreative ? '12%' : props.creativeType === 'video_gallery' ? '5%' : 'unset',
  };

  const iframeStyles = isHorizontalCreative ? {
    height: '42%',
    width: '82%',
    // transform: 'rotate(90deg)',
  } : props.creativeType === 'inline' ? {
      width: '75%',
      height: '20%',
      alignSelf: 'flex-start',
      marginTop: '20%',
  } : props.creativeType === 'video_gallery' ? {
    width: '75%',
    height: '55%'
  } : {
    width: '75%', 
    height: '75%'
  };

  return (
    <>
      <Dialog
        onKeyDown={props.onKeyDown}
        onBackdropClick={props.discardCallback}
        open={true}
        PaperProps={{
          sx: {
            maxWidth: 'unset',
            maxHeight: 'unset',
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center', fontFamily: 'Lexend', fontWeight: 'bold', fontSize: '1.1vw' }}>
          {creativeTypeObj.name || ''}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1vw', alignItems: 'center', padding: '1vw 5vw' }}>
          {creativesWithVariations.includes(creativeTypeObj.id) && (
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab id="tab-1" label={'First Option'} />
              <Tab id="tab-2" label={'Second Option'} />
            </Tabs>
          )}
          <Box sx={iframeContainerStyles}>
            <iframe className="example-preview-iframe" title={creativeTypes.name || ''} src={creativeUrl} height={size.height} width={size.width} style={iframeStyles}></iframe>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', py: '1vw' }}>
            <Button
              autoFocus
              onClick={() => props.confirmCallback(creativeTypeObj)}
              variant="contained"
              className={`${classes.fontFamily} ${classes.proceedBtn}`}
            >
              Create
            </Button>
          <Button
            disabled={true}
            className={classes.fontFamily}
            onClick={() => {}}
            sx={{
              display: props.showShareBtn ? "block" : "none",
            }}
          >
            Format
          </Button>
          <Button
            disabled={true}
            className={classes.fontFamily}
            onClick={() => {}}
            sx={{
              display: props.showShareBtn ? "block" : "none",
            }}
          >
            Template
          </Button>
          <Button
            className={classes.fontFamily}
            onClick={handleOpenShare}
            sx={{
              display: props.showShareBtn ? "block" : "none",
            }}
          >
            Share
          </Button>
          <Button
            onClick={props.discardCallback}
            className={classes.fontFamily}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onKeyDown={handleEscape}
        onBackdropClick={handleCloseShare}
        open={openShareDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Share
        </DialogTitle>
        <DialogContent dividers>
          <ChipsInput
            fullWidth
            variant="outlined"
            id="tags"
            name="tags"
            placeholder="add Tags"
            label="tags"
            chips={toEmails || ''}
            setChips={setToEmails}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button 
            autoFocus 
            onClick={handleSendEmail} 
            variant="contained"
            disabled={emailWasSent}
            className={`${classes.fontFamily} ${classes.proceedBtn}`}
            >
            Send
          </Button>
          <ButtonGroup
            sx={{
              display: 'flex',
              gap: '0.5vw'
            }}
          >
            <IconButton sx={{ color: 'green' }} onClick={handleWhatsAppShare}>
              <WhatsAppIcon />
            </IconButton>
            <IconButton onClick={handleCopyToClipboard} color={copied ? "success" : "default"}>
              <ContentCopyIcon />
            </IconButton>
          </ButtonGroup>
          <Button
            onClick={handleCloseShare}
            variant="text"
            className={classes.fontFamily}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreativeExamplePreview;