import * as actionTypes from '../../store/action';

export function enableConfirmPopUp(dispatch, confirmCallback, discardCallback, content) {
    dispatch({type: actionTypes.ENABLE_CONFIRM_POPUP, confirmCallback: confirmCallback, discardCallback: discardCallback, content: content});
}

export function disableConfirmPopUp(dispatch) {
    dispatch({type: actionTypes.DISABLE_CONFIRM_POPUP});
}

export function showBannerPreview(dispatch) {
    dispatch({type: actionTypes.SHOW_BANNER_PREVIEW});
}

export function hideBannerPreview(dispatch) {
    dispatch({type: actionTypes.HIDE_BANNER_PREVIEW});
}

export function handleShowShapePopUp(dispatch, discardCallback, confirmCallback, shapes, defaultColor) {
    dispatch({type: actionTypes.INIT_SHAPES, value: shapes, defaultColor: defaultColor});
    dispatch({type: actionTypes.SHOW_SHAPE_POPUP, confirmCallback: confirmCallback, discardCallback: discardCallback});
}

export function handleHideShapePopUp(dispatch) {
    dispatch({type: actionTypes.HIDE_SHAPE_POPUP});
}

