import * as actionTypes from '../action';

const initialState = {
    shapeId:null,
    imageUrl: null,
    color:null,
    opacity: null,
    index: null,
    animationType:'none',
    iterationType:'default',
    animationDelay:0,
    animationDuration:0,
    redirectionUrl:'',
    borderRadius:0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STYLE_SHAPE_FOCUS:
            return {
                ...state,
                shapeId: action.uiId,
                imageUrl: action.image,
                color:action.color,
                opacity: action.opacity,
                index: action.index,
                shapeType: action.shapeType,
                animationDelay: action.animationDelay,
                animationType: action.animationType,
                iterationType: action.iterationType,
                animationDuration: action.animationDuration,
                animationData: action.animationData,
                redirectionUrl: action.redirectionUrl,
                borderRadius: action.borderRadius
            }
        case actionTypes.SET_STYLE_SHAPE_REDIRECTION_URL:
            return {
                ...state,
                redirectionUrl: action.value
            }
        case actionTypes.SET_STYLE_SHAPE_COLOR:
            return {
                ...state,
                color: action.value
            }
        case actionTypes.SET_STYLE_SHAPE_Z_INDEX:
            return {
                ...state,
                index: action.value
            }
        case actionTypes.SET_STYLE_SHAPE_OPACITY:
            return {
                ...state,
                opacity: action.value
            }

        case actionTypes.SET_STYLE_SHAPE_ANIMATION_TYPE:
            return {
                ...state,
                animationType: action.value
            }
        case actionTypes.SET_STYLE_SHAPE_ITERATION_TYPE:
            return {
                ...state,
                iterationType: action.value
            }
        case actionTypes.SET_STYLE_SHAPE_DELAY_TYPE:
            return {
                ...state,
                animationDelay: action.value
            }
        case actionTypes.SET_STYLE_SHAPE_ANIMATION_DURATION:
            return {
                ...state,
                animationDuration: action.value
            }
        case actionTypes.SET_STYLE_SHAPE_IMAGE:
            return {
                ...state,
                imageUrl: action.value
            }
        case actionTypes.SET_STYLE_SHAPE_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.value
            }
        default:
            return state;
    }

}

export default reducer