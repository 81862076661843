import "./login_style.scss";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate/*, Link*/ } from "react-router-dom";
// import Button from '../Input/Button';
import { SET_USER } from "../../store/action";
import { getAuthToken, tokenKey } from "../../api_requests";
import { MAIN } from "../../constants/path";
import { toast } from "react-toastify";
import {
  Box,
  // Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Stack,
  TextField,
  Typography,
  // Button,
  Card,
  FormControl,
  InputLabel,
  Radio,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  main_container: {
    height: 'calc(100% - 70px)',
    marginTop: '3%',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5vw',
    padding: '3.5vw 9vw',
    maxWidth: '58.594vw',
    height: 'fit-content',
    boxShadow: 'none',
  },
  card_title_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.4vw',
    textAlign: 'start',
    '& h4': {
      fontSize: '1.25vw',
      fontWeight: '700',
      fontFamily: 'Heebo, sans-serif',
    },
    '& p': {
      fontSize: '0.942vw',
      color: 'black',
      fontWeight: 400,
      fontFamily: 'Heebo, sans-serif',
    },
    boxShadow: 'none',
  },
  formControl: {
    width: '100%',
  },
  label: {
    position: 'initial',
    transform: 'none',
    transition: 'none',
    textAlign: 'left',
    fontSize: '0.833vw',
    marginBottom: '0.333vw',
    color: 'black',
    fontWeight: 'bold',
    fontFamily: 'heebo',
  },
  input: {
    width: '100%',
    '& .MuiInputBase-input': {
      width: '19.271vw',
      padding: '0.625vw 0.938vw',
      fontFamily: 'Lexend',
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: '10vw',
      border: '1px solid #35A7E0'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  remember_device: {
    color: 'black',
    fontWeight: 400,
    fontFamily: 'Heebo, sans-serif',
    '& span': {
      fontSize: '0.677vw',
    },
  },
  remember_device_checkbox: {
    '& .MuiIconButton-root': {
      padding: 0, // Adjust padding to make it look like a circle
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '50%', // Make the icon circular
    },
    '&.Mui-checked .MuiSvgIcon-root': {
    //   backgroundColor: theme.palette.primary.main, // Change background color when checked
    },
  },
  forgot_password: {
    color: '#35A7E0',
    textTransform: 'capitalize',
    fontSize: '0.677vw',
    fontWeight: 700,
  },
  submit_btn: {
    alignSelf: 'flex-start',
    marginTop: '0.417vw',
    backgroundColor: '#35A7E0',
    boxShadow: 'none',
    transition: '1s scale',
    fontSize: '1.083vw',
    padding: '0.083vw 1.458vw',
    borderRadius: '1.667vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    '&:hover': {
      scale: 0.9,
      backgroundColor: '#35A7E0',
      boxShadow: 'none',
    },
  },
}));

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailVal, setEmailVal] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordVal, setPasswordVal] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [rememberVal, setRememberVal] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const classes = useStyles();

  const handleEmailChange = (e) => {
    setEmailVal(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPasswordVal(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setRememberVal(prevState => !prevState);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const emailTest = /([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})/;
    if (!emailTest.test(emailVal)) {
      // !emailVal || emailVal === '' || ____ (emailVal is not valid email)
      toast.error("Error: Valid email address is required to proceed", {
        theme: "colored",
      });
      setEmailError(true);
      setIsSubmitting(false);
      return;
    }
    setEmailError(false);
    if (!passwordVal && passwordVal === "") {
      // !passwordVal || passwordVal === ''
      toast.error("Error: Password is required to proceed", {
        theme: "colored",
      });
      setPasswordError(true);
      setIsSubmitting(false);
      return;
    }
    setPasswordError(false);
    //api request for token
    getAuthToken(emailVal, passwordVal, handleTokenResponse);
  };

  const handleTokenResponse = (data) => {
    setIsSubmitting(false);
    if (data.error !== 0) {
      toast.error("Error: " + data.message, { theme: "colored" });
      return;
    }
    const user = data.user;
    //if no error then store(if remember me is checked), set & redirect
    dispatch({
      type: SET_USER,
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      token: data.token,
      roles: user.roles,
    });
    if (rememberVal) {
      localStorage.setItem(tokenKey, data.token);
    }
    navigate(MAIN);
  };

  // const resendConfirmationCode = () => {
  // }

  return (
    <Container
      component="main"
      maxWidth="md"
      className={classes.main_container}
    >
      <CssBaseline />
      <Card className={classes.card}>
        <Box className={classes.card_title_container}>
          <Typography component="h4">Sign in to Swift Ad</Typography>
          <Typography component="p">Enter your details below.</Typography>
        </Box>
        <Stack spacing={1}>
          <Stack spacing={2}>
            {/* {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )} */}
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.label}>Email</InputLabel>
              <TextField
                onChange={handleEmailChange}
                value={emailVal}
                type="text"
                className={`${classes.input} ${emailError ? "error" : ""}`}
                variant="outlined"
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel className={classes.label}>Password</InputLabel>
              <TextField
                onChange={handlePasswordChange}
                value={passwordVal}
                type="password"
                className={`${classes.input} ${emailError ? "error" : ""}`}
                variant="outlined"
              />
            </FormControl>

            {/* {!authenticated && (
                        <>
                            <Alert severity="info">
                            {'A confirmation code has been sent to your email'}
                        </Alert>
                            <Stack
                                direction="row"
                                spacing={1}
                                justifyContent="center"
                            >
                                <Stack alignItems={'center'}>
                                    <ReactInputVerificationCode
                                    length={5}
                                    autoFocus={true}
                                    placeholder=""
                                    value={valueInput}
                                    onChange={(value) => setValueInput(value)}
                                    onCompleted={(value) => {
                                        setValueInput(value);
                                        handleSubmit(onSubmit);
                                    }}
                                />
                                </Stack>
                            </Stack>
                        </>
                    )} */}
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormLabel>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="flex-start"
              >
                <FormControlLabel
                  control={
                    <Radio
                      sx={{ 
                        '& .MuiSvgIcon-root': {
                          fontSize: 16,
                        } ,
                        '&.Mui-checked': {
                          color: '#35A7E0',
                        },
                      }}
                      className={classes.remember_device_checkbox}
                      onClick={handleCheckboxChange}
                      checked={rememberVal}
                      color="primary"
                    />
                  }
                  label="Remember Device"
                  className={classes.remember_device}
                />
              </Stack>
            </FormLabel>

            {/* {!authenticated && (
                        <Button onClick={() => resendConfirmationCode()}>
                            {'Resend code'}
                        </Button>
                    )} */}


            {/* <Button
              component={Link}
              to={"#"}
              size="small"
              className={classes.forgot_password}
            >
              Forgot password?
            </Button> */}
          </Stack>

          <LoadingButton
            type="submit"
            className={classes.submit_btn}
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
            // disabled={formIsValid}
          >
            {"Login"}
          </LoadingButton>
        </Stack>
      </Card>
    </Container>
  );
}
