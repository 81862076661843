import React from 'react';
import { useDispatch, useSelector } from "react-redux";

import { RiImageAddFill } from "react-icons/ri";
import { IoShapes } from "react-icons/io5";

import { handleAddImage, handleAddText, handleAddShape } from './handlers';
import FileButton from '../Input/FileButton';
import InputWithButton from '../Input/InputWithButton';
import AddTextIcon from '../CustomIcon/AddTextIcon';
import { handleShowShapePopUp, handleHideShapePopUp } from '../PopUp/handlers';
import creativeTypes from '../utils/options/creativeTypes';

export default function InputBox() {
    const imageFilInputId = "imageFileInput";
    const dispatch = useDispatch();
    const textInputState = '';
    const creativeType = useSelector(state => state.creative.type);
    const currentCreativeType = creativeTypes.find(type => type.id === creativeType);

    const handleTextIconClick = (event, value) => {
        handleAddText(dispatch, value);
    };

    const handleOnImageInput = (event) => {
        handleAddImage(event, dispatch);
    };

    const handleShapeBtnClick = (event) => {
        handleShowShapePopUp(dispatch, handleDiscardCallback, handleConfirmCallback);
    };

    const handleDiscardCallback = () => {
        handleHideShapePopUp(dispatch);
    };

    const handleConfirmCallback = (shape) => {
        //add shape To banner
        // add styleMenu for shape
        handleAddShape(dispatch, shape);
        handleHideShapePopUp(dispatch);
    };

    const hasAddImage = currentCreativeType?.options?.addImage;
    const hasAddShape = currentCreativeType?.options?.addShape;
    const hasAddText = currentCreativeType?.options?.addText;

    if (!hasAddImage || !hasAddShape || !hasAddText) {
        return null;
    }


    return (
        <div className="input-box" >
            {hasAddImage ?
                <FileButton className="rounded-40" elemId={imageFilInputId} onInput={handleOnImageInput} icon={<RiImageAddFill />} acceptedFormat="image/*" />
                : null}
            {hasAddShape ?
                <div className="ds-inputs ds-i-button rounded-40">
                    <IoShapes className="icon" onClick={handleShapeBtnClick} />
                </div>
                : null}
            {hasAddText ?
                <InputWithButton className="rounded-40" value={textInputState} onClick={handleTextIconClick} icon={<AddTextIcon />} placeholder={"Add text"} />
                : null}
        </div>

    )
}