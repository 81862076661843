import * as actionTypes from '../action';

const initialState = {
    imageId: null,
    imageUrl: null,
    imageOpacity: null,
    imageZIndex: null,
    animationType: 'none',
    iterationType: 'default',
    animationDelay: 0,
    animationDuration: 0,
    redirectionUrl: '',
    imageIndex: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STYLE_IMAGE_FOCUS:
            return {
                ...state,
                imageId: action.uiId,
                imageUrl: action.image,
                imageOpacity: action.opacity,
                imageZIndex: action.index,
                animationType: action.animationType,
                iterationType: action.iterationType,
                animationDelay: action.animationDelay,
                animationDuration: action.animationDuration,
                animationData: action.animationData,
                redirectionUrl: action.redirectionUrl,
                imageIndex: action.imageIndex
            }
        case actionTypes.SET_STYLE_IMAGE_REDIRECTION_URL:
            return {
                ...state,
                redirectionUrl: action.value
            }
        case actionTypes.SET_STYLE_IMAGE_OPACITY:
            return {
                ...state,
                imageOpacity: action.value
            }
        case actionTypes.SET_STYLE_IMAGE_Z_INDEX:
            return {
                ...state,
                imageZIndex: action.value
            }
        case actionTypes.SET_STYLE_IMAGE_ANIMATION_TYPE:
            return {
                ...state,
                animationType: action.value
            }
        case actionTypes.SET_STYLE_IMAGE_ITERATION_TYPE:
            return {
                ...state,
                iterationType: action.value
            }
        case actionTypes.SET_STYLE_IMAGE_DELAY_TYPE:
            return {
                ...state,
                animationDelay: action.value
            }
        case actionTypes.SET_STYLE_IMAGE_ANIMATION_DURATION:
            return {
                ...state,
                animationDuration: action.value
            }
        case actionTypes.SET_STYLE_IMAGE_CONTENT:
            return {
                ...state,
                imageUrl: action.value
            }
        case actionTypes.SET_STYLE_IMAGE_INDEX:
            return {
                ...state,
                imageIndex: action.value
            }
        default:
            return state;
    }

}

export default reducer