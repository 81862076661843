import './style.scss';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';

const stringToJson = (value) => {
    return value && value !== '' ? JSON.parse(value) : '';
}

export default function Interstitial(props) {
    const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);
    const bgColor = useSelector(state => state.creative.backgroundColor);
    const assets = useSelector(state => state.creative.assets);
    const showTimer = useSelector(state => state.creative.showTimer);
    const timerPosition = useSelector((state) => state.creative.timerPosition);
    const font = useSelector((state) => state.creative.font);
    const textColor = useSelector((state) => state.creative.textColor);

    // eslint-disable-next-line
    const [hours, setHours] = useState('00');
    // eslint-disable-next-line
    const [minutes, setMinutes] = useState('00');
    // eslint-disable-next-line
    const [seconds, setSeconds] = useState('00');
    const [creativeFont, setCreativeFont] = useState(stringToJson(font));

    const displayedImg = (assets && assets.length > 0 && assets.find(asset => asset.width === width && asset.height === height)?.url) || "";
    const bannerRatio = 0.5;

    const bannerStyle = {
        backgroundColor: bgColor,
        backgroundImage: `url(${displayedImg})`,
        minHeight: height * 0.2,
        minWidth: height * 0.2 * bannerRatio,
        width: '100%',
        height: '100%',
        borderRadius: '1.5vw',
    };

    useEffect(() => {
        setCreativeFont(stringToJson(font));
    }, [font]);

    useEffect(() => {
        const newStyle = document.createElement('style');
        newStyle.appendChild(document.createTextNode(`\
            @font-face {\
                font-family: ${creativeFont?.family};\
                src: url('"${creativeFont?.files?.regular}"');\
            }\
            `));

        document.head.appendChild(newStyle);
    }, [creativeFont]);

    const timerTextStyle = {
        color: textColor || 'black',
        fontFamily: creativeFont ? `${creativeFont?.family}, ${creativeFont?.category}` : '',
    };

    return (
        <div 
            className="banner-display-container interstitial-border" 
            style={{ 
                // backgroundImage: `url(${IphoneFrameImage})`, 
                width: '13vw', 
                height: '26vw',
                backgroundColor: 'white',
                borderRadius: '2vw',
            }}
        >
        {/* <div className="banner-display-container grid-content"> */}
            <img className='banner-display-frame-image' alt='' /> {/* src={IphoneFrameFrontCameraImage} /> */}
            <div className="banner-box">
                <div className="target" style={bannerStyle}></div>
            </div>
            {showTimer && (
                <div className={`interstitial-timer-container ${timerPosition.toLowerCase()}`} style={timerTextStyle}>
                    <p className="interstitial-timer" style={{ fontSize: '100%' }}>
                        <span name="hours">{hours}</span>:
                        <span name="minutes">{minutes}</span>:
                        <span name="seconds">{seconds}</span>
                    </p>
                    {/* <div className="interstitial-timer-text-container" style={{ fontSize: bannerStyle.width / 60, gap: bannerStyle.width * 0.045 }}>
                        <span>שעות</span>
                        <span>דקות</span>
                        <span>שניות</span>
                    </div> */}
                </div>
            )}
        </div>
    )
}

