export const YNET = 'YNET';
export const MAKO = 'MAKO';
export const WALLA = 'WALLA';
export const ONE = 'ONE';
export const ISRAEL_HAYOM = 'ISRAEL_HAYOM';
export const SPORT5 = 'SPORT5';
export const GLOBS = 'GLOBS';
export const THE_MARKER = 'THE_MARKER';
export const BIZPORTAL = 'BIZPORTAL';
export const CALCALIST = 'CALCALIST';
