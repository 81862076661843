import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// import placeholder from "../utils/images/avatar_box_placeholder.png";
import videoImages from "../utils/images/video_buttons.json";

// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';
import { VIDEO } from "../../constants/imageTypes";

const stringToJson = (value) => {
    return value && value !== '' ? JSON.parse(value) : '';
}

export default function VideoTimerBanner(props) {
    const videoRef = useRef(null);

    const [playState, setPlayState] = useState(true);
    const [muteState, setMuteState] = useState(true);

    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');

    const width = useSelector((state) => state.creative.bannerWidth);
    const height = useSelector((state) => state.creative.bannerHeight);
    const bgColor = useSelector((state) => state.creative.backgroundColor);
    const assets = useSelector((state) => state.creative.assets);
    const mute = useSelector((state) => state.creative.mute);
    const play = useSelector((state) => state.creative.play);
    const font = useSelector((state) => state.creative.font);
    const textColor = useSelector((state) => state.creative.textColor);
    const darkMode = useSelector((state) => state.creative.darkMode);
    const showTimer = useSelector((state) => state.creative.showTimer);
    const timerPosition = useSelector((state) => state.creative.timerPosition);
    const buttonsPosition = useSelector(
        (state) => state.creative.buttonsPosition
    );

    const [creativeFont, setCreativeFont] = useState(stringToJson(font));

    const background = (assets && assets.length > 0 && assets.find(asset => asset.type.toLowerCase() === "background_image" && asset.width === width && asset.height === height)) || null;
    const video = (assets && assets.length > 0 && assets.find(asset => asset.type === VIDEO)) || null;

    const pauseBtn = darkMode ? videoImages.black.pause : videoImages.white.pause;
    const playBtn = darkMode ? videoImages.black.play : videoImages.white.play;
    const muteBtn = darkMode ? videoImages.black.mute : videoImages.white.mute;
    const unmuteBtn = darkMode ? videoImages.black.unmute : videoImages.white.unmute;

    const bannerRatio = 0.5;

    const bannerStyle = {
        backgroundColor: bgColor,
        minHeight: height * 0.2,
        minWidth: height * 0.2 * bannerRatio,
        width: '100%',
        height: '100%',
        borderRadius: '1.5vw',
    };

    const videoStyle = {
        width: '100%',
        // height: bannerStyle.width * 0.99 * 0.55,
        objectFit: "contain",
    };

    const isVideoPlaying = (video) =>
        !!(
            video?.currentTime > 0 &&
            !video?.paused &&
            !video?.ended &&
            video?.readyState > 2
        );

    const toggleVideoSound = () => {
        if (videoRef.current.muted) {
            setMuteState(false);
        } else {
            setMuteState(true);
        }
    };

    const toggleVideoPlay = () => {
        if (isVideoPlaying(videoRef.current)) {
            videoRef.current.pause();
            setPlayState(false);
        } else {
            videoRef.current.play();
            setPlayState(true);
        }
    };

    function checkDate() {
        const now = new Date();
        const END_DATE = new Date(now.setDate(now.getDate() + 7));

        var _second = 1000;
        var _minute = _second * 60;
        var _hour = _minute * 60;

        const remainingTime = END_DATE - now;
        if (remainingTime <= 0) {
            setHours('00');
            setMinutes('00');
            setSeconds('00');
            return;
        }

        const remainingTimeInHours = Math.floor(remainingTime / _hour);
        const remainingTimeInMinutes = Math.floor((remainingTime % _hour) / _minute);
        const remainingTimeInSeconds = Math.floor((remainingTime % _minute) / _second);

        setHours(remainingTimeInHours < 10 ? `0${remainingTimeInHours}` : remainingTimeInHours);
        setMinutes(remainingTimeInMinutes < 10 ? `0${remainingTimeInMinutes}` : remainingTimeInMinutes);
        setSeconds(remainingTimeInSeconds < 10 ? `0${remainingTimeInSeconds}` : remainingTimeInSeconds);
    }

    useEffect(() => {
        checkDate();
    }, []);

    useEffect(() => {
        setCreativeFont(stringToJson(font));
    }, [font]);

    useEffect(() => {
        const newStyle = document.createElement('style');
        newStyle.appendChild(document.createTextNode(`\
            @font-face {\
                font-family: ${creativeFont?.family};\
                src: url('"${creativeFont?.files?.regular}"');\
            }\
            `));

        document.head.appendChild(newStyle);
    }, [creativeFont]);

    const timerTextStyle = {
        display: background.url !== '' ? 'flex' : 'none',
        color: textColor || 'black',
        fontFamily: creativeFont ? `${creativeFont?.family}, ${creativeFont?.category}` : '',
    };

    return (
        // <div className="banner-display-container grid-content">
        <div 
            className="banner-display-container interstitial-border" 
            style={{ 
                // backgroundImage: `url(${IphoneFrameImage})`, 
                width: '13vw', 
                height: '26vw',
                backgroundColor: 'white',
                borderRadius: '2vw',
            }}
        >
            <img className='banner-display-frame-image' alt="" /> {/* src={IphoneFrameFrontCameraImage} /> */}
            <div
                className="banner-box video-timer-banner-container"
                style={{
                    backgroundImage:
                        background.url !== '' ? `url(${background.url})` : "",
                }}
            >
                <div className='video-timer-banner-video-container'>
                    <div id="ds_div_buttons" className='video-timer-banner-btns-container' style={{ width: bannerStyle.width, bottom: buttonsPosition === 'Top' ? '80%' : buttonsPosition === 'Center' ? '45%' : '7%' }}>
                        <img className={`ds_buttons left ${play ? '' : 'd-none'}`} id="sa_play_video" alt="play button" src={playState ? pauseBtn : playBtn} onClick={toggleVideoPlay} />
                        <img className={`ds_buttons right ${mute ? '' : 'd-none'}`} id="sa_play_sound" alt="sound button" src={muteState ? muteBtn : unmuteBtn} onClick={toggleVideoSound} />
                    </div>
                    <video className='video-timer-banner-video' autoPlay={true} muted={muteState} loop ref={videoRef} src={video?.url} style={videoStyle}>
                        <source type="video/mp4" />
                    </video>
                </div>
                {showTimer && (
                    <div className={`video-timer-banner-timer-container  ${timerPosition.toLowerCase()}`} style={timerTextStyle}>
                        <p className="video-timer-banner-timer">
                            <span name="hours">{hours}</span>:
                            <span name="minutes">{minutes}</span>:
                            <span name="seconds">{seconds}</span>
                        </p>
                        {/* <div className="video-timer-banner-timer-text-container">
                            <span>שעות</span>
                            <span>דקות</span>
                            <span>שניות</span>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    );
}
