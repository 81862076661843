import * as actionTypes from '../action';

const shapeTemplate = {
    animationType:'none',
    iterationType:'default',
    animationDelay: 0,
    animationDuration:0,
    opacity: 1,
    index: 0,
    type: "shape",
    redirectionUrl: '',
    inFocus:false,
    borderRadius:0
};

const initialState = {
    shapes: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_SHAPE:
            let newShape = Object.assign({},shapeTemplate);
            newShape.uiId = action.uiId;
            newShape.className = action.uiId;
            newShape.color = action.value.color;
            newShape.shapeType = action.value.shapeType;
            newShape.shapeData = action.value;
            if (action.value.hasBgImg && action.value.image) {
                newShape.image = action.value.image;
                newShape.imageName = action.value.imageName;
            }
            if (action.value.hasBorderRadius && action.value.borderRadius) {
                newShape.borderRadius = action.value.borderRadius;
            }
            return {
                ...state,
                shapes: state.shapes.concat(newShape)
            }
        case actionTypes.DELETE_SHAPE:
            return {
                ...state,
                shapes: state.shapes.filter(sh => sh.uiId !== action.uiId)
            }
        case actionTypes.ADD_ALL_SHAPES:
             return {
                 ...state,
                 shapes: [...action.shapes]
        }
        case actionTypes.DELETE_ALL_SHAPES:
            return {
                ...state,
                shapes:[]
        }
        case actionTypes.REVERT_POSITION_SHAPE:
            let transformVal = action.transform ? action.transform.replace(/\b(\d*\.?\d+) *(px)/g, 0) : null;
            return {
                ...state,
                shapes: state.shapes.map(sh => sh.uiId === action.uiId ? { ...sh, transform: transformVal} : sh)
            }
        case actionTypes.SET_SHAPE_REDIRECTION_URL:
            return {
                ...state,
                shapes: state.shapes.map( sh => sh.uiId === action.uiId ? { ...sh, redirectionUrl: action.value } : sh )
            }
        case actionTypes.SET_SHAPE_Z_INDEX:
            return {
                ...state,
                shapes: state.shapes.map(sh => sh.uiId === action.uiId ? { ...sh, index: action.value } : sh)
            }
        case actionTypes.SET_SHAPE_OPACITY:
            return {
                ...state,
                shapes: state.shapes.map( sh => sh.uiId === action.uiId ? { ...sh, opacity: action.value } : sh )
            }

        case actionTypes.SET_SHAPE_ANIMATION:
            return {
                ...state,
                shapes: state.shapes.map(
                    sh => sh.uiId === action.uiId ? {
                        ...sh,
                        animationType: action.data.animationType,
                        iterationType: action.data.iterationType,
                        animationDelay: action.data.animationDelay,
                        animationDuration: action.data.animationDuration,
                        className: action.data.className
                    } : sh
                )
            }

        case actionTypes.SET_SHAPE_TRANSFORM:
            return {
                ...state,
                shapes: state.shapes.map(sh => sh.uiId === action.uiId ? { ...sh, transform: action.value} : sh)
            }
        case actionTypes.SET_SHAPE_COLOR:
            return {
                ...state,
                shapes: state.shapes.map( sh => sh.uiId === action.uiId ? { ...sh, color: action.value, image: null } : sh )
            }
        case actionTypes.SET_SHAPE_IMAGE:
            return {
                ...state,
                shapes: state.shapes.map(sh => sh.uiId === action.uiId ? { ...sh, image: action.value, imageName: action.imageName} : sh)
            }
        case actionTypes.FOCUS_SHAPE:
            return {
                ...state,
                shapes: state.shapes.map(sh => sh.uiId === action.uiId ? { ...sh, inFocus: true} : sh)
            }
        case actionTypes.SHAPE_REMOVE_FOCUS:
            return {
                ...state,
                shapes: state.shapes.map(sh => sh.inFocus === true ? { ...sh, inFocus: false} :sh)
            }
        case actionTypes.SET_SHAPE_BORDER_RADIUS:
            return {
                ...state,
                shapes: state.shapes.map(sh => sh.uiId === action.uiId ? { ...sh, borderRadius: action.value} :sh)
            }
        default:
            return state;
    }

}

export default reducer