import React, { useState, useEffect, useMemo  } from "react";
import ReactDOM from "react-dom";
import './modal_overlay.scss';
import Button from '../Input/Button';
import ComplexTextInput from '../Input/ComplexTextInput';
import ImageInput from '../Input/ImageInput';

const PresetModal = ({ visible, toggle, settings, callback }) => {
    const inputValues = useMemo(() => [], []);
    if (settings && settings.assets) {
        settings.assets.forEach(a=>inputValues[a.className] = {...a});
    }
    const [bgVal, setBgVal] = useState({});
    const [logoVal, setLogoVal] = useState({});
    const [image1Val, setImage1Val] = useState({});

    const [action1Val, setAction1Val] = useState({});
    const [slogan1Val, setSlogan1Val] = useState({});
    const [slogan2Val, setSlogan2Val] = useState({});
    const [credentials1Val, setCredentials1Val] = useState({});
    const [credentials2Val, setCredentials2Val] = useState({});

    useEffect(() => {
        if (!settings) return;
        if(inputValues.bg1.value) setBgVal(inputValues.bg1);
        if(inputValues.logo1.value) setLogoVal(inputValues.logo1);
        if(inputValues.image1.value) setImage1Val(inputValues.image1);

        if(inputValues.action1.value) setAction1Val(inputValues.action1);
        if(inputValues.slogan1.value) setSlogan1Val(inputValues.slogan1);
        if(inputValues.slogan2.value) setSlogan2Val(inputValues.slogan2);
        if(inputValues.credentials1.value) setCredentials1Val(inputValues.credentials1);
        if(inputValues.credentials2.value) setCredentials2Val(inputValues.credentials2);

    }, [settings, inputValues]);

    if(!visible) return null;

    const handleBgChange = (value)=>{
        setBgVal(prevState => ({...prevState, value: value.image}));
    };

    const handleLogoChange = (value)=>{
        setLogoVal(prevState => ({...prevState, value: value.image}));
    };

    const handleImageChange = (value)=>{
        setImage1Val(prevState => ({...prevState, value: value.image}));
    };

    const handleTextChange = (val, field)=>{
        switch(field){
            case 'slogan1':
                setSlogan1Val(prevState => ({...prevState, fontColor: val.fontColor, fontFamily: val.fontFamily, fontSize: val.fontSize, value: val.value}));
                break;
            case 'slogan2':
                setSlogan2Val(prevState => ({...prevState, fontColor: val.fontColor, fontFamily: val.fontFamily, fontSize: val.fontSize, value: val.value}));
                break;
            case 'credentials1':
                setCredentials1Val(prevState => ({...prevState, fontColor: val.fontColor, fontFamily: val.fontFamily, fontSize: val.fontSize, value: val.value}));
                break;
            case 'credentials2':
                setCredentials2Val(prevState => ({...prevState, fontColor: val.fontColor, fontFamily: val.fontFamily, fontSize: val.fontSize, value: val.value}));
                break;
            default:
                setAction1Val(prevState => ({...prevState, fontColor: val.fontColor, fontFamily: val.fontFamily, fontSize: val.fontSize, value: val.value}));
        }
    };

    const handleOnSaveClick = ()=>{
        const newValues = [bgVal, logoVal, image1Val ];
        if (inputValues.hasOwnProperty('action1')) newValues.push(action1Val);
        if (inputValues.hasOwnProperty('slogan1')) newValues.push(slogan1Val);
        if (inputValues.hasOwnProperty('slogan2')) newValues.push(slogan2Val);
        if (inputValues.hasOwnProperty('credentials1')) newValues.push(credentials1Val);
        if (inputValues.hasOwnProperty('credentials2')) newValues.push(credentials2Val);
        const populated = newValues.map((val)=>Object.assign(inputValues[val.className], val));
        callback(populated)
    };

    return ReactDOM.createPortal(
        <div className="modal-overlay">
            <div className="content">
                <div className="header">{settings.name}</div>
                <div className="form">
                    <div className="image-section">
                        {inputValues['bg1']?
                        <div className="input-section">
                            <div className="title">{inputValues.bg1.title}</div>
                            <ImageInput elemId={'bg1'} addImage={handleBgChange} image={bgVal.value}/>
                        </div>
                        : null}

                        {inputValues['logo1']?
                        <div className="input-section">
                            <div className="title">{inputValues.logo1.title}</div>
                            <ImageInput elemId={'logo1'} addImage={handleLogoChange} image={logoVal.value}/>
                        </div>
                        : null}

                        {inputValues['image1']?
                        <div className="input-section">
                            <div className="title">{inputValues.image1.title}</div>
                            <ImageInput elemId={'image1'} addImage={handleImageChange} image={image1Val.value}/>
                        </div>
                        : null}
                    </div>
                    <div className="text-section">
                        {inputValues['action1']?
                        <div className="input-section texts">
                            <div className="title">{inputValues.action1.title}</div>
                            <ComplexTextInput elemId={'action1'} onChange={(val)=>{handleTextChange(val,'action1')}}
                                value={inputValues.action1.value}
                                fontSize={inputValues.action1.fontSize}
                                fontFamily={inputValues.action1.fontFamily}
                                fontColor={inputValues.action1.fontColor}/>

                        </div>
                        : null}

                        {inputValues['slogan1']?
                        <div className="input-section texts">
                            <div className="title">{inputValues.slogan1.title}</div>
                            <ComplexTextInput elemId={'slogan1'} onChange={(val)=>{handleTextChange(val,'slogan1')}}
                                value={inputValues.slogan1.value}
                                fontSize={inputValues.slogan1.fontSize}
                                fontFamily={inputValues.slogan1.fontFamily}
                                fontColor={inputValues.slogan1.fontColor}/>
                        </div>
                        : null}

                        {inputValues['slogan2']?
                        <div className="input-section texts">
                            <div className="title">{inputValues.slogan2.title}</div>
                            <ComplexTextInput elemId={'slogan2'} onChange={(val)=>{handleTextChange(val,'slogan2')}}
                                value={inputValues.slogan2.value}
                                fontSize={inputValues.slogan2.fontSize}
                                fontFamily={inputValues.slogan2.fontFamily}
                                fontColor={inputValues.slogan2.fontColor}/>
                        </div>
                        : null}

                        {inputValues['credentials1']?
                        <div className="input-section texts">
                            <div className="title">{inputValues.credentials1.title}</div>
                            <ComplexTextInput elemId={'credentials1'} onChange={(val)=>{handleTextChange(val,'credentials1')}}
                                value={inputValues.credentials1.value}
                                fontSize={inputValues.credentials1.fontSize}
                                fontFamily={inputValues.credentials1.fontFamily}
                                fontColor={inputValues.credentials1.fontColor}/>
                        </div>
                        : null}

                        {inputValues['credentials2']?
                        <div className="input-section texts">
                            <div className="title">{inputValues.credentials2.title}</div>
                            <ComplexTextInput elemId={'credentials2'} onChange={(val)=>{handleTextChange(val,'credentials2')}}
                                value={inputValues.credentials2.value}
                                fontSize={inputValues.credentials2.fontSize}
                                fontFamily={inputValues.credentials2.fontFamily}
                                fontColor={inputValues.credentials2.fontColor}/>
                        </div>
                        : null}
                    </div>
                </div>
                <div className="action-footer">
                    <Button className='back-btn' text={'Close'} onClick={toggle}></Button>
                    <Button className='colored-light' text={'Save'} onClick={handleOnSaveClick}></Button>
                </div>
            </div>
        </div>, document.body
    );
};

function areEqual(prevProps, nextProps) {
    /* return true if value is not updated,  otherwise return false */
    if (prevProps.visible !== nextProps.visible || JSON.stringify(prevProps.settings) !== JSON.stringify(nextProps.settings)) {
        return false;
    }
    return true;
}

export default React.memo(PresetModal, areEqual);