import './style.scss';
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import placeholder from '../../Components/utils/images/avatar_box_placeholder.png';

export default function BounceGame(props) {
    const outerRef = useRef();

    const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);
    const bgColor = useSelector(state => state.creative.backgroundColor);
    const assets = useSelector(state => state.creative.assets);

    const backgroundImage = assets.find(asset => asset.type.toLowerCase() === 'background_image' && asset.url !== placeholder ? asset.url : null);
    const logoImage = assets.find(asset => asset.type.toLowerCase() === 'logo_image' && asset.url !== placeholder);
    const scoreImage = assets.find(asset => asset.type.toLowerCase() === 'score_image' && asset.url !== placeholder);
    const posterImage = assets.find(asset => asset.type.toLowerCase() === 'poster' && asset.order === 4 && asset.url !== placeholder);
    const barImage = assets.find(asset => asset.type.toLowerCase() === 'movable_image' && asset.order === 10 && asset.url !== placeholder);
    const loadingImage = assets.find(asset => asset.type.toLowerCase() === 'loading_image' && asset.url !== placeholder);

    const hide = (img) => {
        if (!img) {
            return 'hidden';
        }
        return 'visible'
    }

    const bannerStyle = {
        backgroundColor: bgColor,
        backgroundImage: `url(${backgroundImage?.url})`,
        height: height,
        width: width,
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5%',
        alignItems: 'center',
    };

    return (
        <div className="banner-display-container grid-content">
            <div className="banner-box box-shadow">
                <div className="target" style={bannerStyle} ref={outerRef}>
                    <div className='bounce_top' style={{ visibility: hide(backgroundImage?.url) }}>
                        <img className='bounce_logo' src={logoImage?.url} alt='' style={{ visibility: hide(logoImage?.url) }} />
                        <div className='bounce_timer_container'>
                            <span className='bounce_timer'>
                                00:00
                            </span>
                        </div>
                        <div className='bounce_score_container' style={{ backgroundImage: `url(${scoreImage?.url})`, visibility: hide(scoreImage?.url) }}>
                            <span className='bounce_score'>
                                5
                            </span>
                        </div>
                    </div>
                    <img className='bounce_poster' src={posterImage?.url} alt='' style={{ visibility: hide(posterImage?.url) }} />
                    <img className={`bounce_loading ${loadingImage ? 'spin_forever' : ''}`} src={loadingImage?.url} alt='' style={{ visibility: hide(loadingImage?.url) }} />
                    <img className={`bounce_bar ${barImage ? 'shake' : ''}`} src={barImage?.url} alt='' style={{ visibility: hide(barImage?.url) }} />
                </div>
            </div>
        </div>
    )
}

