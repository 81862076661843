import './style.scss';
import React, { useState, useEffect, useCallback } from "react";

function TextInput(props) {
    const value = props.value ? props.value : '';
    const classNames = props.className ? 'ds-inputs ds-txt-input ' + props.className : 'ds-inputs ds-txt-input';
    const [valueState, setValueState] = useState(value);
    const [isValid, setIsValid] = useState(true);
    const validation = props.validation;

    const handleOnChange = (event) => {
        setValueState(event.target.value);
        props.onComplete(event.target.value);
    };

    const handleValidation = useCallback((val) => {
        if (val !== '') setIsValid(true);
        else if (validation && typeof (validation) === 'function') setIsValid(validation(val));
        else setIsValid(true);
    }, [validation]);

    useEffect(() => {
        if (props.value && props.value !== valueState) {
            const newVal = props.value;
            setValueState(newVal);
            handleValidation(newVal);
        }
    }, [props.value, valueState, setValueState, handleValidation]);



    return (
        <div className={`${classNames}${isValid ? '' : ' error'} rounded-40`}>
            <input className={props.inputClassName} onChange={handleOnChange} value={valueState} type="text" placeholder={props.placeholder} />
        </div>
    )
}

function areEqual(prevProps, nextProps) {
    /* return true if value is not updated,  otherwise return false */
    if (prevProps.value === nextProps.value && prevProps.itemId === nextProps.itemId) {
        return true;
    }
    return false;
}
export default React.memo(TextInput, areEqual);