import React, { useRef, useEffect } from "react";
import Moveable from "react-moveable";
import { useDispatch, useSelector  } from "react-redux";
import * as actionTypes from '../../store/action';
import * as utils from '../../utils.js';
import './style.css';
import { handleFocus, handleTransform } from './handlers';
import { handleSideMenu } from '../StyleMenus/handlers';
import fonts, { FONT_MAP} from '../utils/options/fonts';

function MoveableText(props) {
    const dispatch = useDispatch();
    const didMountRef = useRef(false);
    const movableRef = useRef();
    const moveableElemRef = useRef();
    const fontType = props.fontType? props.fontType : fonts[0].id;
    const textDivStyle = {
        display: 'inline-block',
        color: props.color,
        fontSize: props.fontSize+'px',
        fontFamily: FONT_MAP[fontType].css,
        transform: props.transform
    };

    let assignedTransformVal = props.transform;

    const animationType = useSelector(state => state.txt_style.animationType);
    const iterationType = useSelector(state => state.txt_style.iterationType);
    const animationDelay = useSelector(state => state.txt_style.animationDelay);
    const animationDuration = useSelector(state => state.txt_style.animationDuration);

    const handleTextFocus = (event) =>{
        handleFocus(dispatch, props, props.type);
        handleSideMenu(dispatch, props.type);
    };

    const handleTransformChange = (target) =>{
        if(!assignedTransformVal || assignedTransformVal === '') return;
        handleTransform(dispatch, assignedTransformVal, props.uiId, props.type);
        if (animationType !== 'none') {
            dispatch({ type: actionTypes.SET_TEXT_ANIMATION, uiId: props.uiId, data: utils.getAnimationData(props, animationType, iterationType, animationDelay, animationDuration)});
        }
    };

    useEffect(() => {
        if (didMountRef.current) {
            moveableElemRef.current.updateRect();
        } else didMountRef.current = true
    })

    return (
        <div className="item-container" data-focus={props.inFocus} id={`${props.uiId}_container`} style={{zIndex: props.index}}>
            <div className="text has-context-menu" onMouseDown={handleTextFocus} id={props.uiId} style={textDivStyle} ref={movableRef}>{props.text}</div>
            <Moveable className={ `moveable_${props.uiId}`} moveableId={props.uiId}
                target={movableRef}
                ref={moveableElemRef}
                /* rootContainer={props.parentRef.current}*/
                /* Resize event edges */
                /* draggable */
                draggable={true}
                throttleDrag={0}
                onDragStart={({ target, clientX, clientY }) => {}}
                onDrag={({ target, beforeDelta, beforeDist, left, top, right, bottom, delta, dist, transform,  clientX, clientY }: OnDrag) => {
                    target.style.transform = transform;
                    assignedTransformVal = transform;
                }}
                onDragEnd={({ target, isDrag, clientX, clientY }) => {
                    handleTransformChange(target);
                }}

                /* rotatable */
                rotatable={true}
                throttleRotate={0}
                onRotateStart={({ target, clientX, clientY }) => {}}
                onRotate={({ target,delta, dist, transform, clientX, clientY}: onRotate) => {
                    target.style.transform = transform;
                    assignedTransformVal = transform;
                }}
                onRotateEnd={({ target, isDrag, clientX, clientY }) => {
                    handleTransformChange(target);
                }}
            />
        </div>
    )
}

function anyFieldChanges(prev, next, fields) {
    for (let i = 0; i < fields.length; ++i) {
        const field = fields[i];
        if(prev[field] !== next[field]){
            return true;
        }
    }
    return false;
}

function areEqual(prevProps, nextProps) {
    /* return true if value is not updated,  otherwise return false */
    const fields = ['id', 'text', 'inFocus', 'index', 'fontSize', 'fontType', 'transform', 'color', 'redirectionUrl', 'animationDuration', 'animationType', 'animationDelay', 'iterationType', 'className'];
    if (!anyFieldChanges(prevProps, nextProps, fields)) {
        return true;
    }
    return false;
}

export default React.memo(MoveableText, areEqual);


