import React from 'react';
import './style.scss';
import { useState } from "react";

export default function InputWithButton(props) {
    const classNames = props.className ? 'ds-inputs ds-i-input ' + props.className : 'ds-inputs ds-i-input';
    const [valueState, setValueState] = useState(props.value);
    const [isValidState, setIsValidState] = useState(true);

    const handleIconClick = (event) => {
        if (!valueState) return;
        if (valueState === '') {
            setIsValidState(true);
            props.onClick(event, valueState);
            return;
        }
        if (props.validation && !props.validation(valueState)) {
            setIsValidState(false);
            return;
        }
        props.onClick(event, valueState);
        setIsValidState(true);
        setValueState('');
    };

    const handleChange = (event) => {
        setValueState(event.target.value);
    };

    return (
        <div className={isValidState ? classNames : classNames + ' error'}>
            <div className="icon-holder" onClick={handleIconClick}>{props.icon}</div>
            <input onChange={handleChange} value={valueState} type="text" placeholder={props.placeholder} />
        </div>
    )
}