import { BACKGROUND, BANNER, LOGO } from '../../constants/imageTypes';
import * as actionTypes from '../../store/action';

export function handleSideMenu(dispatch, type, creativeType = null) {
    if (!type) return;
    const lowerCaseType = type.toLowerCase();
    let assetType;
    if ((creativeType === 'gallery' && (lowerCaseType === BACKGROUND || lowerCaseType === LOGO)) || lowerCaseType === BANNER) assetType = "image";
    else assetType = lowerCaseType;
    switch (assetType) {
        case "image":
            dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: true });
            dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: false });
            dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: false });
            break;
        case "text":
            dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: true });
            dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: false });
            dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: false });
            break;
        case "shape":
            dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: true });
            dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: false });
            dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: false });
            break;
        default:
            dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: false });
            dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: false });
            dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: false });
    }
}

export function handleRedirectionUrlChange(dispatch, type, itemId, value) {
    switch (type.toLowerCase()) {
        case "image":
            dispatch({ type: actionTypes.SET_STYLE_IMAGE_REDIRECTION_URL, value: value });
            dispatch({ type: actionTypes.SET_IMAGE_REDIRECTION_URL, uiId: itemId, value: value });
            break;
        case "text":
            dispatch({ type: actionTypes.SET_STYLE_TEXT_REDIRECTION_URL, value: value });
            dispatch({ type: actionTypes.SET_TEXT_REDIRECTION_URL, uiId: itemId, value: value });
            break;
        case "shape":
            dispatch({ type: actionTypes.SET_STYLE_SHAPE_REDIRECTION_URL, value: value });
            dispatch({ type: actionTypes.SET_SHAPE_REDIRECTION_URL, uiId: itemId, value: value });
            break;
        default:
    }
}

export function handleImageInputChange(dispatch, type, itemId, value) {
    switch (type.toLowerCase()) {
        case "image":
            dispatch({ type: actionTypes.SET_IMAGE_CONTENT, uiId: itemId, value: value, imageName: value.name });
            dispatch({ type: actionTypes.SET_STYLE_IMAGE_CONTENT, value: value.image });
            break;
        case "text":
            break;
        case "shape":
            dispatch({ type: actionTypes.SET_SHAPE_IMAGE, uiId: itemId, value: value.image, imageName: value.name });
            dispatch({ type: actionTypes.SET_STYLE_SHAPE_IMAGE, value: value.image });
            break;
        default:
    }
}

export function handleColorChange(dispatch, type, itemId, color) {
    switch (type.toLowerCase()) {
        case "image":
            break;
        case "text":
            dispatch({ type: actionTypes.SET_TEXT_COLOR, uiId: itemId, value: color.hex });
            dispatch({ type: actionTypes.SET_STYLE_TEXT_COLOR, value: color.hex });
            break;
        case "shape":
            dispatch({ type: actionTypes.SET_SHAPE_COLOR, uiId: itemId, value: color.hex });
            dispatch({ type: actionTypes.SET_STYLE_SHAPE_IMAGE, uiId: itemId, value: null });
            dispatch({ type: actionTypes.SET_STYLE_SHAPE_COLOR, value: color.hex });
            break;

        default:
    }
}

export function handleZetIndexChange(dispatch, type, itemId, value) {
    switch (type.toLowerCase()) {
        case "image":
            dispatch({ type: actionTypes.SET_IMAGE_Z_INDEX, uiId: itemId, value: value });
            dispatch({ type: actionTypes.SET_STYLE_IMAGE_Z_INDEX, value: value });
            break;
        case "text":
            dispatch({ type: actionTypes.SET_TEXT_Z_INDEX, uiId: itemId, value: value });
            dispatch({ type: actionTypes.SET_STYLE_TEXT_Z_INDEX, value: value });
            break;
        case "shape":
            dispatch({ type: actionTypes.SET_SHAPE_Z_INDEX, uiId: itemId, value: value });
            dispatch({ type: actionTypes.SET_STYLE_SHAPE_Z_INDEX, value: value });
            break;
        default:
    }
}

export function handleOpacityChange(dispatch, type, itemId, value) {
    switch (type.toLowerCase()) {
        case "image":
            dispatch({ type: actionTypes.SET_IMAGE_OPACITY, uiId: itemId, value: value });
            dispatch({ type: actionTypes.SET_STYLE_IMAGE_OPACITY, value: value });
            break;
        case "text":
            break;
        case "shape":
            dispatch({ type: actionTypes.SET_SHAPE_OPACITY, uiId: itemId, value: value });
            dispatch({ type: actionTypes.SET_STYLE_SHAPE_OPACITY, value: value });
            break;
        default:
    }
}

export function handleIterationChange(dispatch, type, itemId, animationData, value) {
    switch (type.toLowerCase()) {
        case "image":
            dispatch({ type: actionTypes.SET_STYLE_IMAGE_ITERATION_TYPE, value: value });
            dispatch({ type: actionTypes.SET_IMAGE_ANIMATION, uiId: itemId, data: animationData });
            break;
        case "text":
            dispatch({ type: actionTypes.SET_STYLE_TEXT_ITERATION_TYPE, value: value });
            dispatch({ type: actionTypes.SET_TEXT_ANIMATION, uiId: itemId, data: animationData });
            break;
        case "shape":
            dispatch({ type: actionTypes.SET_STYLE_SHAPE_ITERATION_TYPE, value: value });
            dispatch({ type: actionTypes.SET_SHAPE_ANIMATION, uiId: itemId, data: animationData });
            break;
        default:
    }
}

export function handleDelayChange(dispatch, type, itemId, animationData, value) {
    switch (type.toLowerCase()) {
        case "image":
            dispatch({ type: actionTypes.SET_STYLE_IMAGE_DELAY_TYPE, value: value });
            dispatch({ type: actionTypes.SET_IMAGE_ANIMATION, uiId: itemId, data: animationData });
            break;
        case "text":
            dispatch({ type: actionTypes.SET_STYLE_TEXT_DELAY_TYPE, value: value });
            dispatch({ type: actionTypes.SET_TEXT_ANIMATION, uiId: itemId, data: animationData });
            break;
        case "shape":
            dispatch({ type: actionTypes.SET_STYLE_SHAPE_DELAY_TYPE, value: value });
            dispatch({ type: actionTypes.SET_SHAPE_ANIMATION, uiId: itemId, data: animationData });
            break;
        default:
    }
}

export function handleDurationChange(dispatch, type, itemId, animationData, value) {
    switch (type.toLowerCase()) {
        case "image":
            dispatch({ type: actionTypes.SET_STYLE_IMAGE_ANIMATION_DURATION, value: value });
            dispatch({ type: actionTypes.SET_IMAGE_ANIMATION, uiId: itemId, data: animationData });
            break;
        case "text":
            dispatch({ type: actionTypes.SET_STYLE_TEXT_ANIMATION_DURATION, value: value });
            dispatch({ type: actionTypes.SET_TEXT_ANIMATION, uiId: itemId, data: animationData });
            break;
        case "shape":
            dispatch({ type: actionTypes.SET_STYLE_SHAPE_ANIMATION_DURATION, value: value });
            dispatch({ type: actionTypes.SET_SHAPE_ANIMATION, uiId: itemId, data: animationData });
            break;
        default:
    }
}

export function handleAnimationChange(dispatch, type, itemId, animationData, animationId, iterationId, delay, duration, isClearAnimation) {
    switch (type.toLowerCase()) {
        case "image":
            handleImageAnimation(dispatch, itemId, animationData, animationId, iterationId, delay, duration, isClearAnimation);
            break;
        case "text":
            handleTextAnimation(dispatch, itemId, animationData, animationId, iterationId, delay, duration, isClearAnimation);
            break;
        case "shape":
            handleShapeAnimation(dispatch, itemId, animationData, animationId, iterationId, delay, duration, isClearAnimation);
            break;
        default:
    }
}

//////////IMAGE STYLE HANDLERS////////////


function handleImageAnimation(dispatch, imageId, animationData, animationId, iterationId, delay, duration, isClearAnimation) {
    dispatch({ type: actionTypes.SET_STYLE_IMAGE_ANIMATION_TYPE, value: animationId });
    dispatch({ type: actionTypes.SET_IMAGE_ANIMATION, uiId: imageId, data: animationData });
    if (isClearAnimation) {
        dispatch({ type: actionTypes.SET_STYLE_IMAGE_ITERATION_TYPE, value: iterationId });
        dispatch({ type: actionTypes.SET_STYLE_IMAGE_DELAY_TYPE, value: delay });
        dispatch({ type: actionTypes.SET_STYLE_IMAGE_ANIMATION_DURATION, value: duration });
    }
}

//////////TEXT STYLE HANDLERS////////////

export function handleTextContent(dispatch, uiId, value) {
    dispatch({ type: actionTypes.SET_TEXT_CONTENT, uiId: uiId, value: value });
    dispatch({ type: actionTypes.SET_STYLE_TEXT_CONTENT, value: value });
}

export function handleTextFontFamily(dispatch, textId, val) {
    dispatch({ type: actionTypes.SET_TEXT_FONT_FAMILY, uiId: textId, value: val });
    dispatch({ type: actionTypes.SET_STYLE_TEXT_FONT_FAMILY, value: val });
}

export function handleTextFontSize(dispatch, textId, val) {
    dispatch({ type: actionTypes.SET_TEXT_FONT_SIZE, uiId: textId, value: val });
    dispatch({ type: actionTypes.SET_STYLE_TEXT_FONT_SIZE, value: val });
}


function handleTextAnimation(dispatch, textId, animationData, animationId, iterationId, delay, duration, isClearAnimation) {
    dispatch({ type: actionTypes.SET_STYLE_TEXT_ANIMATION_TYPE, value: animationId });
    dispatch({ type: actionTypes.SET_TEXT_ANIMATION, uiId: textId, data: animationData });
    if (isClearAnimation) {
        dispatch({ type: actionTypes.SET_STYLE_TEXT_ITERATION_TYPE, value: iterationId });
        dispatch({ type: actionTypes.SET_STYLE_TEXT_DELAY_TYPE, value: delay });
        dispatch({ type: actionTypes.SET_STYLE_TEXT_ANIMATION_DURATION, value: duration });
    }
}

//////////TEXT STYLE HANDLERS////////////

function handleShapeAnimation(dispatch, itemId, animationData, animationId, iterationId, delayId, duration, isClearAnimation) {
    dispatch({ type: actionTypes.SET_STYLE_SHAPE_ANIMATION_TYPE, value: animationId });
    dispatch({ type: actionTypes.SET_SHAPE_ANIMATION, uiId: itemId, data: animationData });
    if (isClearAnimation) {
        dispatch({ type: actionTypes.SET_STYLE_SHAPE_ITERATION_TYPE, value: iterationId });
        dispatch({ type: actionTypes.SET_STYLE_SHAPE_DELAY_TYPE, value: delayId });
        dispatch({ type: actionTypes.SET_STYLE_SHAPE_ANIMATION_DURATION, value: duration });
    }
}



export function handleShapeRadiusChange(dispatch, itemId, value) {
    dispatch({ type: actionTypes.SET_STYLE_SHAPE_BORDER_RADIUS, value: value });
    dispatch({ type: actionTypes.SET_SHAPE_BORDER_RADIUS, uiId: itemId, value: value });
}


// export function handleImageSelect(dispatch, value) {
//     dispatch({ type: actionTypes.SET_IMAGE_REDIRECTION_URL, value: value })
// }