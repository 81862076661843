import './style.scss';
import React from 'react';
import logo from '../../Components/utils/images/logo3.png';

export default function LoaderPage(props) {

    return (
        <div className="loader-page">
            <img className="loader" src={logo} alt="DolphinSoft" />
        </div>
    )
}