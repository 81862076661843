import React from 'react';
import './style.scss';

export default function FileButton (props) {
    const classNames = props.className? 'ds-inputs ds-i-button '+ props.className: 'ds-inputs ds-i-button';
    const acceptedFormat = props.acceptedFormat? props.acceptedFormat : '';

    const handleValueChange = (event) =>{
        props.onInput(event);
    };

    return (
        <div className={classNames}>
            <label htmlFor={props.elemId} >
                {props.icon}
                <input className="icon" onInput={handleValueChange} name={props.elemId} id={props.elemId} type="file" accept={acceptedFormat} />
            </label>
        </div>
    )
}

