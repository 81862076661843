export const DIVIDER = 'divider';
export const LEFT = 'left';
export const RIGHT = 'right';
export const VIDEO = 'video';
export const IMAGE = 'image';
export const POSTER = 'poster';
export const BACKGROUND = 'background_image';
export const BANNER = 'banner_image';
export const LOGO = 'logo_image';
export const FRONT = 'front_image';
export const BACK = 'back_image';
