import React, { useEffect, useState } from 'react';
// import './style.scss';
import Button from '../Input/Button';
import DropDown from '../Input/DropDown';
import { useDispatch, useSelector } from 'react-redux';
import { SET_NEW_CREATIVE_NAME, SET_NEW_CREATIVE_TYPE, SET_NEW_CREATIVE_CLIENT_ID, SET_NEW_CREATIVE_CAMPAIGN_ID, CLEAR_NEW_CREATIVE_FIELDS, SET_CREATIVE_FIELDS, SET_LEFT_DRAWER_CURRENT_TAB, SET_CREATIVE_NAME, SET_CREATIVE_CLIENT_ID, SET_CREATIVE_CAMPAIGN_ID, SET_CREATIVE_TYPE, CLEAR_CREATIVE_FIELDS } from '../../store/action';
import creativeTypes from '../utils/options/creativeTypes';
import { toast } from 'react-toastify';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';

import { makeStyles } from "@mui/styles";
import TextInput from '../Input/mui_inputs/TextInput';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '16px 24px 0 24px',
    margin: 0,
  },
  contentContainer: { 
    display: 'flex', 
    flexDirection: 'column', 
    gap: '1vw',
    paddingTop: '20px !important',
  },
  btnContainer: {
    padding: '8px 24px',
    justifyContent: 'space-between',
  },
  proceedBtn: {
    padding: '0.1rem 1rem',
  },
  fontFamily: {
    fontFamily: 'Lexend',
  },
}));

export default function NewCreative(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const nameVal = useSelector(state => state.new_creative.name) || "";
    const type = useSelector(state => state.new_creative.type);
    const clientID = useSelector(state => state.new_creative.clientId);
    const campaignID = useSelector(state => state.new_creative.campaignId);
    
    const currentType = props.creative.id ? props.creative.type : type ? type : props.creativeType ? props.creativeType : creativeTypes[0].id;
    const currentClient = clientID ? clientID : user.clients[0].id;
    const campaigns = user.campaigns.filter(c => c.clientId === currentClient);
    const currentCampaign = (campaignID) ? (campaignID) : ((campaigns.length > 0) ? (campaigns[0].id) : (null));

    const [nameError, setNameError] = useState(false);

    useEffect(() => {
    if (props.creative.id) {
      dispatch({ type: SET_NEW_CREATIVE_NAME, value: props.creative.name });
      dispatch({ type: SET_NEW_CREATIVE_TYPE, value: props.creative.type });
      dispatch({ type: SET_NEW_CREATIVE_CLIENT_ID, value: props.creative.clientId });
      dispatch({ type: SET_NEW_CREATIVE_CAMPAIGN_ID, value: props.creative.campaignId });
    }
  }, [props.creative, dispatch]);

    const handleNameChange = (value) => {
        dispatch({ type: SET_NEW_CREATIVE_NAME, value: value });
        dispatch({ type: SET_CREATIVE_NAME, name: value });
    };

    const handleTypeChange = (e) => {
        dispatch({ type: SET_NEW_CREATIVE_TYPE, value: e.id });
        dispatch({ type: SET_CREATIVE_FIELDS, name: nameVal, creativeType: e.id, clientId: currentClient, campaignId: currentCampaign });
        dispatch({ type: SET_CREATIVE_TYPE, creativeType: e.id });
    };

    const handleClientChange = (e) => {
        dispatch({ type: SET_NEW_CREATIVE_CLIENT_ID, value: e.id });
        dispatch({ type: SET_CREATIVE_CLIENT_ID, clientId: e.id });

        // Find the first campaign for the selected client
        const campaignsForClient = user.campaigns.filter(c => c.clientId === e.id);
        if (campaignsForClient.length > 0) {
            const firstCampaignId = campaignsForClient[0].id;
            dispatch({ type: SET_NEW_CREATIVE_CAMPAIGN_ID, value: firstCampaignId });
            dispatch({ type: SET_CREATIVE_CAMPAIGN_ID, campaignId: firstCampaignId });
        }
    };

    const handleCampaignChange = (e) => {
        dispatch({ type: SET_NEW_CREATIVE_CAMPAIGN_ID, value: e.id });
        dispatch({ type: SET_CREATIVE_CAMPAIGN_ID, campaignId: e.id });
    };

    const handleConfirm = () => {
        if (!nameVal && nameVal === '') {
            toast.error('Error: Missing creative name', { theme: "colored" });
            setNameError(true);
            return;
        }
        if (campaigns.length < 1) {
            toast.error('Error: This Client has no campaigns, choose another client or create campaign', { theme: "colored" });
            return;
        }
        if (props.creativeType)
            // start of creating new creative
            setNameError(false);
        dispatch({ type: SET_LEFT_DRAWER_CURRENT_TAB , value: 1 });
        dispatch({ type: SET_CREATIVE_FIELDS, name: nameVal, creativeType: currentType, clientId: currentClient, campaignId: currentCampaign });
        props.confirmCallback();
        dispatch({ type: CLEAR_NEW_CREATIVE_FIELDS });
        dispatch({ type: CLEAR_CREATIVE_FIELDS });
    };

    const handleDiscard = () => {
        dispatch({ type: CLEAR_NEW_CREATIVE_FIELDS });
        dispatch({ type: CLEAR_CREATIVE_FIELDS });
        props.discardCallback();
    };

    if(props.popup) {
        return (
            <Dialog
                onKeyDown={(event) => {
                    if(event.key === 'Escape') {
                        dispatch({ type: CLEAR_NEW_CREATIVE_FIELDS });
                        props.onKeyDown(event);
                    }
                }}
                onBackdropClick={handleDiscard}
                open={true}
                PaperProps={{
                    sx: {
                        maxWidth: 'unset',
                        maxHeight: 'unset',
                    }
                }}
            >
                <DialogTitle className={classes.title}>
                    New Creative
                </DialogTitle>
                <DialogContent className={classes.contentContainer}>
                    <TextField
                        label="Creative Name"
                        value={nameVal}
                        error={nameError}
                        type="text"
                        onChange={handleNameChange}
                        autoFocus
                    />
                    <DropDown value={currentType} onChange={handleTypeChange} options={creativeTypes} disable={props.carouselNewCreative} label="Creative Type" />
                    <DropDown value={currentClient} onChange={handleClientChange} options={user.clients} label="Client" />
                    <DropDown value={currentCampaign} onChange={handleCampaignChange} options={campaigns} label="Campaign" />
                </DialogContent>
                <DialogActions className={classes.btnContainer}>
                    <Button 
                        onClick={handleConfirm} 
                        variant="contained"
                        className={`${classes.fontFamily} ${classes.proceedBtn}`}
                    >
                        Proceed
                    </Button>
                    <Button 
                        onClick={handleDiscard} 
                        variant="text"
                        className={classes.fontFamily}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return (
            <Box className="general-menu-container">
                <Stack className="general-menu" sx={{ gap: '1.381vw' }}>
                    <TextInput label={"Creative Name"} autoFocus value={nameVal} fullWidth variant='outlined' error={nameError} onComplete={handleNameChange} itemId={'bannerTextBackgroundColorInput'} />
                    <DropDown style={{ alignItems: 'flex-start' }} value={currentType} onChange={handleTypeChange} options={creativeTypes} disable={props.carouselNewCreative} label="Creative Type" />
                    <DropDown style={{ alignItems: 'flex-start' }} value={currentClient} onChange={handleClientChange} options={user.clients} label="Client" />
                    <DropDown style={{ alignItems: 'flex-start' }} value={currentCampaign} onChange={handleCampaignChange} options={campaigns} label="Campaign" />
                </Stack>
            </Box>
        );
    }
}