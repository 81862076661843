export const CUSTOM_BANNER = 'custom_banner';
export const INTERSTITIAL = 'interstitial';
export const HALF_HALF = 'half_and_half'
export const INLINE = 'inline';
export const VIDEO_INTERSTITIAL = 'video_interstitial';
export const GALLERY = 'gallery';
export const MOBILE_BANNER_VIDEO = 'mobile_banner_video';
export const FLIPPER = 'flipper';
export const SCRATCH = 'scratch';
export const BOUNCE_GAME = 'bounce_game';
export const VIDEO_TIMER_BANNER = 'video_timer_banner';
export const DESKTOP_TIMER_BANNER = 'desktop_timer_banner';
export const VIDEO_GALLERY = 'video_gallery';
export const COLLECT_OR_EVADE_GAME = 'collect_or_evade_game';
export const PROGRAM_VIDEO = 'program_video';
export const HALF_VIDEO = 'half_video';
export const UNIVERSAL = 'universal';

const creativeTypes = [
    {
        id: CUSTOM_BANNER,
        name: 'Custom Banner',
        options: {
            addImage: true,
            addShape: true,
            addText: true,
            bgColor: true,
            bgImage: true,
            bgVideo: true,
            sizeSelect: true,
            tagOptions: true,
            videoControls: false,
            textControls: false,
            fontOptions: false,
            timerControls: false,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: INTERSTITIAL,
        name: 'Static Interstitial',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: false,
            textControls: true,
            fontOptions: true,
            timerControls: true,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: HALF_HALF,
        name: 'Half & Half',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: false,
            textControls: false,
            fontOptions: false,
            timerControls: false,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: INLINE,
        name: 'Inline Video',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: true,
            textControls: true,
            fontOptions: true,
            timerControls: true,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 360
        }
    },
    {
        id: VIDEO_INTERSTITIAL,
        name: 'Video Interstitial',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: true,
            textControls: false,
            fontOptions: false,
            timerControls: false,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: GALLERY,
        name: 'Gallery',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: false,
            textControls: false,
            fontOptions: false,
            timerControls: false,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: MOBILE_BANNER_VIDEO,
        name: 'Mobile Banner Video',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: true,
            textControls: false,
            fontOptions: false,
            timerControls: false,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: FLIPPER,
        name: 'Flipper',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: false,
            textControls: false,
            fontOptions: false,
            timerControls: false,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: SCRATCH,
        name: 'Scratch',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: false,
            textControls: false,
            fontOptions: false,
            timerControls: false,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: BOUNCE_GAME,
        name: 'Bounce Game',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: false,
            textControls: false,
            fontOptions: false,
            timerControls: false,
            gameControls: true,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: VIDEO_TIMER_BANNER,
        name: 'Video Timer Banner',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: true,
            textControls: true,
            fontOptions: true,
            timerControls: true,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: DESKTOP_TIMER_BANNER,
        name: 'Desktop Timer Banner',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: true,
            tagOptions: false,
            videoControls: true,
            textControls: true,
            fontOptions: true,
            timerControls: true,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 960,
            height: 330
        }
    },
    {
        id: VIDEO_GALLERY,
        name: 'Video Gallery',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: true,
            textControls: false,
            fontOptions: false,
            timerControls: false,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: COLLECT_OR_EVADE_GAME,
        name: 'Collect or Evade Game',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: false,
            textControls: true,
            fontOptions: false,
            timerControls: false,
            gameControls: true,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: PROGRAM_VIDEO,
        name: 'Program Video',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: false,
            textControls: false,
            fontOptions: false,
            timerControls: false,
            gameControls: false,
            programVideo: true
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: HALF_VIDEO,
        name: 'Half Video',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: true,
            textControls: true,
            fontOptions: true,
            timerControls: true,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
    {
        id: UNIVERSAL,
        name: 'Universal',
        options: {
            addImage: false,
            addShape: false,
            addText: false,
            bgColor: false,
            bgImage: false,
            bgVideo: false,
            sizeSelect: false,
            tagOptions: false,
            videoControls: true,
            textControls: true,
            fontOptions: true,
            timerControls: true,
            gameControls: false,
            programVideo: false
        },
        size: {
            width: 640,
            height: 960
        }
    },
];

export default creativeTypes;

