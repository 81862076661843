import { toast } from 'react-toastify';

import {
    HALF_HALF_URL,
    STATIC_URL,
    INLINE_URL,
    VIDEO_INTERSTITIAL_URL,
    GALLERY_URL,
    MOBILE_BANNER_VIDEO_URL,
    FLIPPER_URL,
    SCRATCH_URL,
    BOUNCE_GAME_URL,
    VIDEO_TIMER_BANNER_URL,
    DESKTOP_TIMER_BANNER_URL,
    VIDEO_GALLERY_URL,
    COLLECT_OR_EVADE_GAME_URL,
    PROGRAM_VIDEO_URL,
    HALF_VIDEO_URL,
    UNIVERSAL_URL,
}
    from "../../../constants/adsUrl";
import {
    CUSTOM_BANNER,
    HALF_HALF,
    INTERSTITIAL,
    INLINE,
    VIDEO_INTERSTITIAL,
    GALLERY,
    MOBILE_BANNER_VIDEO,
    FLIPPER,
    SCRATCH,
    BOUNCE_GAME,
    VIDEO_TIMER_BANNER,
    DESKTOP_TIMER_BANNER,
    VIDEO_GALLERY,
    COLLECT_OR_EVADE_GAME,
    PROGRAM_VIDEO,
    HALF_VIDEO,
    UNIVERSAL,
} from "../../../constants/creativeTypes";

const interstitialCreatives = [
    INTERSTITIAL,
    HALF_HALF,
    VIDEO_INTERSTITIAL,
    GALLERY,
    FLIPPER,
    MOBILE_BANNER_VIDEO,
    SCRATCH,
    BOUNCE_GAME,
    VIDEO_TIMER_BANNER,
    COLLECT_OR_EVADE_GAME,
    PROGRAM_VIDEO,
    HALF_VIDEO
];
const inlineCreatives = [
    INLINE,
];
const elvisCreatives = [
    // DESKTOP_TIMER_BANNER,
];
const billboardCreatives = [
    DESKTOP_TIMER_BANNER
];

// eslint-disable-next-line
const INSERT_CLICKMACRO_HERE = "${INSERT_CLICKMACRO_HERE}";

const GENERATE_LINK = (creativeType, creativeId) => {
    let link = '';
    switch (creativeType) {
        case INTERSTITIAL:
            link = `${STATIC_URL}?id=${creativeId}`
            break;
        case HALF_HALF:
            link = `${HALF_HALF_URL}?id=${creativeId}`
            break;
        //TODO: CHECK CUSTOM BANNER METHODOLOGY
        case CUSTOM_BANNER:
            link = `${CUSTOM_BANNER}?id=${creativeId}`
            break;
        case INLINE:
            link = `${INLINE_URL}?id=${creativeId}`
            break;
        case VIDEO_INTERSTITIAL:
            link = `${VIDEO_INTERSTITIAL_URL}?id=${creativeId}`
            break;
        case GALLERY:
            link = `${GALLERY_URL}?id=${creativeId}`
            break;
        case MOBILE_BANNER_VIDEO:
            link = `${MOBILE_BANNER_VIDEO_URL}?id=${creativeId}`
            break;
        case FLIPPER:
            link = `${FLIPPER_URL}?id=${creativeId}`
            break;
        case SCRATCH:
            link = `${SCRATCH_URL}?id=${creativeId}`
            break;
        case BOUNCE_GAME:
            link = `${BOUNCE_GAME_URL}?id=${creativeId}`
            break;
        case VIDEO_TIMER_BANNER:
            link = `${VIDEO_TIMER_BANNER_URL}?id=${creativeId}`
            break;
        case DESKTOP_TIMER_BANNER:
            link = `${DESKTOP_TIMER_BANNER_URL}?id=${creativeId}`
            break;
        case VIDEO_GALLERY:
            link = `${VIDEO_GALLERY_URL}?id=${creativeId}`
            break;
        case COLLECT_OR_EVADE_GAME:
            link = `${COLLECT_OR_EVADE_GAME_URL}?id=${creativeId}`
            break;
        case PROGRAM_VIDEO:
            link = `${PROGRAM_VIDEO_URL}?id=${creativeId}`
            break;
        case HALF_VIDEO:
            link = `${HALF_VIDEO_URL}?id=${creativeId}`
            break;
        case UNIVERSAL:
            link = `${UNIVERSAL_URL}?id=${creativeId}`
            break;
        default:
            link = `${STATIC_URL}?id=${creativeId}`
            break;
    }
    return link;
}
export const GENERATE_PREVIEW_LINK = (creativeType, creativeName, creativeId) => {
    return `https://adsil1.com/demos/settings/preview/?src=${GENERATE_LINK(creativeType, creativeId)}/&name=${creativeName}&type=${interstitialCreatives.includes(creativeType) ? 'interstitial' : inlineCreatives.includes(creativeType) ? 'inline' : elvisCreatives.includes(creativeType) ? 'elvis' : billboardCreatives.includes(creativeType) ? 'billboard' : 'interstitial'}`
}

const DOWNLOAD_TEXTFILE = (tag, selectedMedia, creativeName, creativeType) => {
    const element = document.createElement("a");

    element.href = `data:text/plain;charset=utf-11,${encodeURIComponent(tag)}`
    element.download = `${selectedMedia}-${creativeName}-${creativeType}.txt`;
    document.body.appendChild(element);
    element.click();
    element.remove();
}

const RETURN_TEXTFILE = (tag) => {
    const fileContent = new Blob([tag], { type: "text/plain" });
    return fileContent;
};

// TODO: Add all creative types for each media
export const TAGS = {
    YNET: {
        interstitial: (selectedMedia, myVendor, selectedLink, creativeType, creativeName) => {
            let appLink = selectedLink.split('?')[0] + 'SA_ad_init.js?' + selectedLink.split('?')[1];
            return `
            /////////////////// ${creativeName} - ${creativeType} ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=${selectedLink}/&name=${creativeName}&type=interstitial ////////////////
            
            <!--${selectedMedia} - App -->
            <script id="SA_ad_init" src="${appLink}&SA_vendor=${myVendor}&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$"></script>

            <!--${selectedMedia} - Web -->
            <iframe id="div_iframe" src="${selectedLink}&SA_vendor=${myVendor}&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" frameborder="0" scrolling="no" style="width:100%;height: 100%;" width="100%" height="400px"> </iframe>

            <!--${selectedMedia} - פנימי -->
            <iframe id="div_iframe" src="${selectedLink}&SA_vendor=${myVendor}&SA_type=innerweb&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" frameborder="0" scrolling="no" style="width:100%;height: 100%;" width="100%" height="430px"> </iframe>
        `;
        },
        inline: `
            ///////////////////creativeName - Inboard ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=inline ///////////////////

            <!--ynetApp-->
            <div id="div_iframe" style="width:100vw; height:56.25vw; display:block;"><iframe src="selectedLink/SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" style="width:100%; height:100%;"></iframe></div>
       
            `,
        custom_banner: `
            
        `,
    },
    MAKO: {
        interstitial: `
            /////////////////// creativeName - creativeType ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=interstitial ////////////////

            <!--selectedMedia - App -->
            <iframe id="div_iframe" src="selectedLink&SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" frameborder="0" scrolling="no" style="width:100%;height:100%;position:absolute" width="100%" height="100%"> </iframe>

            <!--selectedMedia - Web -->
            <iframe id="div_iframe" src="selectedLink&SA_vendor=myVendor&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" frameborder="0" scrolling="no" style="width:100%;height: 100%;" width="100%" height="450px"> </iframe>
            
            <!--selectedMedia - Web Full Screen -->
            <iframe id="div_iframe" src="selectedLink&SA_vendor=myVendor&SA_type=web_full_screen&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" frameborder="0" scrolling="no" style="width:100%;height: 100%;" width="100%" height="100%"> </iframe>
        `,
        inline: `
            /////////////////// creativeName - LiveBox ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=inline ///////////////////

            <!--mako - ראשי-->
            <div id="div_iframe" style="width:100vw;height:200px;display:block;position: absolute;"><iframe src="selectedLink/SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" style="width:100%; height:100%;"></iframe></div>

            <!--mako - פנימי-->
            <div id="div_iframe" style="width:100vw;height:56.25vw;display:block;position: absolute;"><iframe src="selectedLink/SA_vendor=myVendor&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" style="width:100%; height:100%;"></iframe></div>
            
       
            `,

        placard: `
            /////////////////// creativeName - Elvis ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=elvis ///////////////////
            
            <!--makoApp-->
            <iframe id="div_iframe" src="selectedLink&SA_vendor=myVendor&SA_type=desktop&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" width="970px" height="330px" display:block;position: absolute;"></iframe>
            `,
            
        billboard: `
            /////////////////// creativeName - Mega Plazma ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=billboard ///////////////////

        <!--makoApp-->
            <iframe id="div_iframe" src="selectedLink&SA_vendor=myVendor&SA_type=desktop&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" width="970px" height="540px" display:block;position: absolute;"></iframe>
        `,

        custom_banner: `
        
        `,
    },
    WALLA: {
        interstitial: `
            /////////////////// creativeName - creativeType ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=interstitial ////////////////

            <!--selectedMedia - App -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%"></iframe></div>

            <!--selectedMedia - Web -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%" width="100%" height="450px"></iframe></div>
        `,
        inline: `
            /////////////////// creativeName - King ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=inline ///////////////////


            <!--'wallaApp-->
            <div id="div_iframe" style="width:100vw; height:220px; display:block;"><iframe src="selectedLink/SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" style="width:100%; height:100%;"></iframe></div>
       
            `,

        placard: `
            /////////////////// creativeName - Billboard ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=billboard ///////////////////

            <!--walla-->
            <div id="div_iframe" style="width:970px; height:350px; display:block;"><iframe src="selectedLink&SA_vendor=myVendor&SA_type=desktop&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" style="width:100%; height:100%;"></iframe></div>
        `,

        custom_banner: `
        
        `,
    },
    ONE: {
        interstitial: `
            /////////////////// creativeName - creativeType ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=interstitial ////////////////

            <!--selectedMedia - App -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%"></iframe></div>

            <!--selectedMedia - Web -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%" width="100%" height="450px"></iframe></div>
        `,
        inline: `
            ///////////////////creativeName - Inboard ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=inline ///////////////////

            <!--selectedMedia - App-->
            <div id="div_iframe" style="width:100vw; height:56.25vw; display:block;"><iframe src="selectedLink/SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" style="width:100%; height:100%;"></iframe></div>
       
        `,
    },
    ISRAEL_HAYOM: {
        interstitial: `
            /////////////////// creativeName - creativeType ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=interstitial ////////////////

            <!--selectedMedia - App --> 
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%"></iframe></div>

            <!--selectedMedia - Web -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%" width="100%" height="450px"></iframe></div>
        `,
        inline: `
            ///////////////////creativeName - Inboard ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=inline ///////////////////

            <!--selectedMedia - App-->
            <div id="div_iframe" style="width:100vw; height:56.25vw; display:block;"><iframe src="selectedLink/SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" style="width:100%; height:100%;"></iframe></div>
       
        `,
    },
    SPORT5: {
        interstitial: `
            /////////////////// creativeName - creativeType ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=interstitial ////////////////

            <!--selectedMedia - App -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%"></iframe></div>

            <!--selectedMedia - Web -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%" width="100%" height="450px"></iframe></div>
        `,
        inline: `
            ///////////////////creativeName - Inboard ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=inline ///////////////////

            <!--selectedMedia - App-->
            <div id="div_iframe" style="width:100vw; height:56.25vw; display:block;"><iframe src="selectedLink/SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" style="width:100%; height:100%;"></iframe></div>
       
        `,
    },
    GLOBS: {
        interstitial: `
            /////////////////// creativeName - creativeType ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=interstitial ////////////////

            <!--selectedMedia - App -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%"></iframe></div>

            <!--selectedMedia - Web -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%" width="100%" height="450px"></iframe></div>
        `,
        inline: `
            ///////////////////creativeName - Inboard ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=inline ///////////////////

            <!--selectedMedia - App-->
            <div id="div_iframe" style="width:100vw; height:210px; display:block;"><iframe src="selectedLink/SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" style="width:100%; height:100%;"></iframe></div>
       
        `,
    },
    THE_MARKER: {
        interstitial: `
            /////////////////// creativeName - creativeType ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=interstitial ////////////////

            <!--selectedMedia - App -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%"></iframe></div>

            <!--selectedMedia - Web -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%" width="100%" height="450px"></iframe></div>
        `,
        inline: `
            ///////////////////creativeName - Inboard ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=inline ///////////////////

            <!--selectedMedia - App-->
            <iframe src="selectedLink/SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" scrolling="no" frameborder="0" style="width:100%; height:250px;"></iframe>
       
        `,
    },
    BIZPORTAL: {
        interstitial: `
            /////////////////// creativeName - creativeType ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=interstitial ////////////////

            <!--selectedMedia - App -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%"></iframe></div>

            <!--selectedMedia - Web -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%" width="100%" height="450px"></iframe></div>
        `,
    },
    CALCALIST: {
        interstitial: `
            /////////////////// creativeName - creativeType ///////////////////
            /////////////////// Preview Link - https://adsil1.com/demos/settings/preview/?src=selectedLink/&name=creativeName&type=interstitial ////////////////

            <!--selectedMedia - App -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=app&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%"></iframe></div>

            <!--selectedMedia - Web -->
            <div id="div_iframe" style="width:100vw; height:100vh; display:block;"><iframe frameborder="0" scrolling="no" src="selectedLink&SA_vendor=myVendor&SA_type=web&SA_clickMacro=$${INSERT_CLICKMACRO_HERE}$$" style="width:100%; height:100%" width="100%" height="450px"></iframe></div>
        `,
    }
}

export const GENERATE_TAG = (selectedMedia, creativeName, creativeType, creativeId, downloadTag = false) => {
    let selectedTagType = creativeType;
    const link = GENERATE_LINK(creativeType, creativeId);

    if (interstitialCreatives.includes(creativeType)) selectedTagType = INTERSTITIAL;
    if (creativeType === DESKTOP_TIMER_BANNER) {
        selectedTagType = 'billboard';
        // selectedTagType = 'placard';
    }
    if (selectedTagType !== 'interstitial' && selectedTagType.includes('interstitial')) {
        const name_split = selectedTagType.split('_');
        selectedTagType = name_split[name_split.length - 1];
    }
    if (creativeType === VIDEO_GALLERY) selectedTagType = 'billboard';

    let tag;


    if (selectedTagType === 'interstitial' && selectedMedia === 'YNET') {
        tag = TAGS[selectedMedia][selectedTagType](selectedMedia, selectedMedia.toLowerCase(), link, creativeType, creativeName);
    } else {
        if(TAGS[selectedMedia][selectedTagType]) {
            tag = TAGS[selectedMedia][selectedTagType]
                .replaceAll('selectedMedia', selectedMedia)
                .replaceAll('myVendor', selectedMedia.toLowerCase())
                .replaceAll('selectedLink', link)
                .replaceAll('creativeType', creativeType)
                .replaceAll('&name=creativeName', `&name=${creativeName.replace(/ /g, '%20')}`)
                .replaceAll('creativeName', creativeName);
        } else {
            toast.error('The selected media does not have tag for the selected creative type.');
            return;
        }
    }


    if (creativeType === CUSTOM_BANNER) {
        // tag = TAGS[selectedMedia][selectedTagType]
        // .replaceAll('div_iframeWidth', )
        // .replaceAll('div_iframeHeight', )
        // .replaceAll('iframeWidth', )
        // .replaceAll('iframeHeight', );
    }

    if (downloadTag) {
        DOWNLOAD_TEXTFILE(tag, selectedMedia, creativeName, creativeType);
    } else {
        return RETURN_TEXTFILE(tag);
    }
}
