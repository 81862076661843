import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from '../../store/action';
import { getCustomBannerHtml, getHalfHalfHtml, getVideoInterstitialHtml } from '../../utils.js';
import { HALF_HALF, VIDEO_INTERSTITIAL } from '../utils/options/creativeTypes';
import './style.scss';
import { AiFillCloseSquare } from 'react-icons/ai';
import { Backdrop } from "@mui/material";

export default function CreativePreview(props) {
    const dispatch = useDispatch();
    const iframeRef = useRef();

    const imageArr = useSelector(state => state.img.images);
    const textArr = useSelector(state => state.txt.texts);
    const shapeArr = useSelector(state => state.shape.shapes);
    const banner = useSelector(state => state.creative);


    const handleClose = () => {
        dispatch({ type: actionTypes.HIDE_BANNER_PREVIEW });
    };

    const handleHtmlByCreativeType = (type) => {
        let previewData;
        switch (type) {
            case HALF_HALF:
                previewData = getHalfHalfHtml(banner);
                break;
            case VIDEO_INTERSTITIAL:
                previewData = getVideoInterstitialHtml(banner);
                break;
            default:
                previewData = getCustomBannerHtml(banner, imageArr, textArr, shapeArr);
        }
        return previewData;
    }

    const generatedPreview = handleHtmlByCreativeType(banner.type);

    useEffect(() => {
        if (iframeRef.current) {
            const iframe = iframeRef.current;
            iframe.contentDocument.open();
            iframe.contentDocument.write(['<head></head><style>', generatedPreview.css, '</style></head><body>', generatedPreview.html, '<script>', generatedPreview.script, '</script></body>'].join(''));
            iframe.contentDocument.close();
        }
    });

    return (
        <div className="pop-up-container">
            <div className="pop-up banner-preview box-shadow">
                <Backdrop
                    sx={{ color: '#fff', zIndex: -1 }}
                    open={props.backdrop}
                    onClick={handleClose}
                ></Backdrop>
                <AiFillCloseSquare className="close-btn" onClick={handleClose} />
                <iframe ref={iframeRef} title="halfAndHalfPreview" height={banner.bannerHeight} width={banner.bannerWidth} style={{ border: '30px solid rgb(50,50,50)', boxSizing: 'content-box', fontFamily: 'initial' }} />
            </div>
        </div>
    )
}