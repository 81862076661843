import { useState, useRef } from "react";
import { useSelector } from "react-redux";

// import placeholder from '../../Components/utils/images/avatar_box_placeholder.png';
import videoImages from "../utils/images/video_buttons.json";

// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';

import './style.scss';
import { VIDEO } from "../../constants/imageTypes";

export default function HalfVideo(props) {
    const videoRef = useRef(null);

    const [playState, setPlayState] = useState(true);
    const [muteState, setMuteState] = useState(true);

    // const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);
    const bgColor = useSelector(state => state.creative.backgroundColor);
    const assets = useSelector(state => state.creative.assets);
    const timerEndDate = useSelector(state => state.creative.timerEndDate);
    const textColor = useSelector(state => state.creative.textColor);
    const showTimer = useSelector(state => state.creative.showTimer);
    const darkMode = useSelector(state => state.creative.darkMode);
    const play = useSelector(state => state.creative.play);
    const mute = useSelector(state => state.creative.mute);
    const buttonsPosition = useSelector(state => state.creative.buttonsPosition);

    const firstImage = assets && assets.length > 0 && assets.find(asset => asset.type.toLowerCase() === "title_image")?.url;
    const secondImage = assets && assets.length > 0 && assets.find(asset => asset.type.toLowerCase() === "subtitle_image")?.url;
    const video = assets && assets.length > 0 && assets.find(asset => asset.type.toLowerCase() === VIDEO)?.url;

    const pauseBtn = darkMode ? videoImages.black.pause : videoImages.white.pause;
    const playBtn = darkMode ? videoImages.black.play : videoImages.white.play;
    const muteBtn = darkMode ? videoImages.black.mute : videoImages.white.mute;
    const unmuteBtn = darkMode ? videoImages.black.unmute : videoImages.white.unmute;

    const bannerRatio = 0.5;

    const bannerStyle = {
        backgroundColor: bgColor,
        minHeight: height * 0.2,
        minWidth: height * 0.2 * bannerRatio,
        width: '100%',
        height: '100%',
        borderRadius: '1.5vw',
    };

    function returnParagraphWithDays(daysDiff) {
        // If the time left is more than 1 day
        if (daysDiff > 1) {
            return (
                <span style={{ textAlign: "center" }}>
                    נותרו רק עוד
                    <br />
                    <span>{daysDiff} ימים</span>
                </span>
            );
        }
        // If the time left is exactly 1 day
        else if (daysDiff === 1) {
            return (
                <>
                    נותר רק עוד <span>יום אחד</span>
                </>
            );
        }
        // If the target and today are the same day
        else if (daysDiff === 0) {
            return (
                <>
                    <span>היום </span><span>היום האחרון</span>
                </>
            );
        }

        return "";
    }

    function returnDaysUntil(target) {
        // Get the current date
        const currentDate = new Date();

        // Set the time of currentDate to 00:00:00
        currentDate.setHours(0, 0, 0, 0);

        // Get the target date
        const targetDate = new Date(target);
        targetDate.setHours(0, 0, 0, 0);

        // Calculate the time difference in milliseconds
        const timeDiff = targetDate - currentDate;

        // Convert the time difference to days
        const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        // Return the html instead of a number
        return returnParagraphWithDays(daysDiff);
    }

    const isVideoPlaying = video => !!(video?.currentTime > 0 && !video?.paused && !video?.ended && video?.readyState > 2);

    const toggleVideoSound = () => {
        if (videoRef.current.muted) {
            setMuteState(false);
        } else {
            setMuteState(true);
        }
    };

    const toggleVideoPlay = () => {
        if (isVideoPlaying(videoRef.current)) {
            videoRef.current.pause();
            setPlayState(false);
        } else {
            videoRef.current.play();
            setPlayState(true);
        }
    };

    return (
        // <div className="banner-display-container grid-content">
        <div 
            className="banner-display-container interstitial-border" 
            style={{ 
                // backgroundImage: `url(${IphoneFrameImage})`, 
                width: '13vw', 
                height: '26vw',
                backgroundColor: 'white',
                borderRadius: '2vw',
            }}
        >
            <img className='banner-display-frame-image' alt="" /> {/* src={IphoneFrameFrontCameraImage} /> */}
            <div className="banner-box">
                <div className="target half_video" style={bannerStyle}>
                    <img className='half_video_title' src={firstImage} style={{ display: firstImage && firstImage !== '' ? "block" : "none" }} alt="half video first" />
                    <div className="half_video_daysUntilContainer" style={{ display: showTimer ? "flex" : "none" }}>
                        <span style={{ color: textColor }}>{returnDaysUntil(timerEndDate)}</span>
                    </div>
                    <img className='half_video_subtitle' src={secondImage} style={{ display: secondImage && secondImage !== '' ? "block" : "none" }} alt="half video second" />
                    <div className="half_video_video_container">
                        <div id="ds_div_buttons" style={{ width: bannerStyle.width, bottom: buttonsPosition === 'Top' ? '85%' : buttonsPosition === 'Center' ? '45%' : '5%' }}>
                            <img className={`ds_buttons left ${play ? '' : 'd-none'}`} id="sa_play_video" alt="play button" src={playState ? pauseBtn : playBtn} onClick={toggleVideoPlay} />
                            <img className={`ds_buttons right ${mute ? '' : 'd-none'}`} id="sa_play_sound" alt="sound button" src={muteState ? muteBtn : unmuteBtn} onClick={toggleVideoSound} />
                        </div>
                        <video className='half_video_video' loop={true} autoPlay={true} muted={muteState} playsInline={true} src={video} ref={videoRef} style={{ display: video ? "block" : "none" }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

