import { useSelector } from "react-redux";
import Button from '../Input/Button';
import './style.scss';

export default function ConfirmPopUp() {
    const confirmCallback = useSelector(state => state.pop_up.confirmCallback);
    const discardCallback = useSelector(state => state.pop_up.discardCallback);
    const overrideContent = useSelector(state => state.pop_up.content);

    const titleText = overrideContent && overrideContent.title ? overrideContent.title : 'Confirm changes';
    const msgText = overrideContent && overrideContent.text ? overrideContent.text : null;
    const okBtnText = overrideContent && overrideContent.okBtnText ? overrideContent.okBtnText : 'Ok';
    const noBtnText = overrideContent && overrideContent.noBtnText ? overrideContent.noBtnText : 'Cancel';

    return (
        <div className="pop-up-container">
            <div className="pop-up confirm-pop-up box-shadow">
                <header>{titleText}</header>
                <div className="text-msg">{msgText}</div>
                <footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button className="no-btn mr-20" onClick={discardCallback} variant="text">{noBtnText}</Button>
                    <Button className="yes-btn colored-light" onClick={confirmCallback} variant="contained">{okBtnText}</Button>
                </footer>
            </div>
        </div>
    )
}
