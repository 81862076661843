import React, { useState } from "react";
import { isValidUrl } from '../../../utils';
import { InputAdornment, Stack, TextField } from '@mui/material';
import { LinkRounded } from '@mui/icons-material';
import './inputs.scss';

function UrlInput(props) {
  const classNames = props.className ? 'ds-mui-inputs ds-url-input ' + props.className : 'ds-mui-inputs ds-url-input';
  const [valueState, setValueState] = useState(props.value);
  const isValid = !valueState || valueState === '' || isValidUrl(valueState);

  const handleOnChange = (event) => {
    setValueState(event.target.value);
    props.onComplete(event.target.value);
  }

  const icons = {
    'url': <LinkRounded />,
  }

  const icon = icons[props.icon];

  return (
    <Stack
      sx={props.sx}
       spacing={1}
    >
     <label 
        style={{
          fontSize: '1.1vw',
          fontWeight: 'bold', 
          fontFamily: 'Lexend',
          lineHeight: '2vw',
        }}
      >
        {props.label}
      </label>
      <TextField
        className={classNames}
        onChange={handleOnChange}
        value={props.value}
        type="text"
        label={props.placeholder}
        InputProps={{
          startAdornment:
            props.icon && (
              <InputAdornment position="start">
                {icon}
              </InputAdornment>
            )
        }}
        variant="outlined"
        disabled={props.disabled}
        error={!isValid}
        color={props.color || "info"}
        sx={{
          width: '100%',
          fontFamily: 'Lexend',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid #35A7E0',
              borderRadius: '10vw',
            },
            '& legend': {
              display: 'none', // Hide the legend
            },
            '& input': {
              fontSize: '1vw', 
              padding: '0.6vw 1.2vw',
              height: '1.883vw',
              fontFamily: 'Lexend',
            },
          }
        }}
      />
    </Stack>
  )
}

function areEqual(prevProps, nextProps) {
  /* return true if value is not updated,  otherwise return false */
  if (prevProps.value === nextProps.value && prevProps.itemId === nextProps.itemId) {
    return true;
  }
  return false;
}
export default React.memo(UrlInput, areEqual);

