import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button as MuiButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    default: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: '0.833vw',
        borderRadius: '1.667vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.2vw 1vw',
    },
    contained: {
        backgroundColor: '#30a4d0',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#1c90bc'
        }
    },
    outlined: {
        color: '#30a4d0',
        border: '1px solid #1c90bc80',
        '&:hover': {
            border: '1px solid #30a4d0'
        }
    },
    text: {
        color: '#30a4d0'
    }
}));

export default function Button(props) {
    const classes = useStyles();

    // Merge the existing class name(s) with the additional class name(s)
    const mergedClassName = `${classes.default} ${props.variant === "contained" ? classes.contained : props.variant === "outlined" ? classes.outlined : classes.text} ${props.className ? props.className : ''}`;

    return (
        <MuiButton
            sx={props.sx}
            className={mergedClassName}
            onClick={props.onClick}
            disabled={props.disabled}
            style={props.style}
            variant={props.variant}
            autoFocus={props.autoFocus}
        >
            {props.children}
        </MuiButton>
    )
}