import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

import './inputs.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '20vw',
    overflow: 'hidden',
    gap: '0.5vw',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '5vw',
    overflow: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5),
  }
}));

export default function ChipsInput(props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [uniqueValues, setUniqueValues] = useState(new Set());
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddChip = () => {
    if (inputValue && !uniqueValues.has(inputValue)) {
      const emailTest = /([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})/;
      if (!emailTest.test(inputValue)) {
        setError(true);
        setErrorText('Invalid email address!');
        setInputValue('');
        setTimeout(() => {
          setError(false);
          setErrorText('');
        }, 2000);
        return
      }
      props.setChips([...props.chips, inputValue]);
      setUniqueValues(new Set([...uniqueValues, inputValue]));
      setInputValue('');
    }
  };

  const handleDeleteChip = (chip) => () => {
    props.setChips((chips) => props.chips.filter((c) => c !== chip));
    setUniqueValues(new Set(props.chips.filter((c) => c !== chip)));
  };

  return (
    <div className={`${classes.root} ${props.className}`}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1vw',
        }}
      >
        <Typography>
          Enter valid emails and press Enter
        </Typography>
        <TextField
          sx={{
            '& legend': {
              display: 'none'
            },
            '& input::placeholder': {
              color: error ? '#d32f2f' : '#b9bdc2',
            },
            width: '100%',
            '& .MuiInputBase-input': {
              width: '370px',
              padding: '12px 18px',
            },
            '& .MuiInputBase-root': {
              borderRadius: '10vw',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#35A7E0',
            },
          }}
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleAddChip();
            }
          }}
          fullWidth
          variant='outlined'
          error={error}
          placeholder={errorText}
        />
      </Box>
      <div className={classes.chips}>
        {props.chips.map((chip) => (
          <Chip
            key={chip}
            label={chip}
            onDelete={handleDeleteChip(chip)}
            className={classes.chip}
          />
        ))}
      </div>
    </div>
  );
};