import * as utils from '../../utils';
import * as actionTypes from '../../store/action'

export async function handleAddImage(e, dispatch) {
    const id = utils.generateId();
    let imageData = await utils.processImage(e);
    dispatch({ type: actionTypes.ADD_IMAGE, value: imageData.image, width: imageData.width, height: imageData.height, uiId: id, imageName: imageData.name });
}

export function handleAddText(dispatch, text) {
    const id = utils.generateId();
    dispatch({ type: actionTypes.ADD_TEXT, value: text, uiId: id });
}

export function handleAddShape(dispatch, shape) {
    const id = utils.generateId();
    dispatch({ type: actionTypes.ADD_SHAPE, value: shape, uiId: id });
}



