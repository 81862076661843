import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { exportReports, getCreativeList } from '../../api_requests';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import creativeTypes from '../utils/options/creativeTypes';
import { toast } from 'react-toastify';
import { MdContentCopy, MdEdit, MdDelete, MdGeneratingTokens, MdPreview } from "react-icons/md";
import { FaFileExport } from "react-icons/fa";
import { copyToClipboard } from '../../utils';
import GenerateTagPopup from '../PopUp/GenerateTagPopup';
import { GENERATE_PREVIEW_LINK } from '../utils/options/tags';
import DeleteCreativeDialog from '../PopUp/DeleteCreativeDialog';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import Button from '../Input/Button';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { makeStyles } from '@mui/styles';
import { SET_CREATIVE_CAMPAIGN_ID, SET_CREATIVE_CLIENT_ID } from '../../store/action';

const useStyles = makeStyles((theme) => ({
    container: { 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        gap: '1vw', 
        width: '100%' 
    },
    title: {
        width: '90%', 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '1.5vw', 
        fontFamily: 'Lexend'
    },
    grid_title: {
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold !important',
        }
    },
    green: {
        color: 'green',
    },
    red: {
        color: 'red',
    },
    proceedBtn: {
        padding: '0.083vw 0.833vw',
    },
    fontFamily: {
        fontFamily: 'Lexend',
    },
}));

const now = new Date();
const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

function CreativeGrid(props) {
    const classes = useStyles();

    const mountRef = React.useRef(false);
    const authToken = useSelector(state => state.user.token);
    const [apiDataNotReady, setApiDataNotReady] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [menuTop, setMenuTop] = useState(0);
    const [menuLeft, setMenuLeft] = useState(0);
    const [selected, setSelected] = useState(null);
    const [generateTagPopupOpen, setGenerateTagPopupOpen] = useState(false);
    const [creativeData, setCreativeData] = useState([]);

    const [openExportDialog, setOpenExportDialog] = useState(false);
    const [from, setFrom] = useState(oneWeekAgo);
    const [to, setTo] = useState(now);

    const campaigns = props.campaigns.reduce((map, obj) => { map[obj.id] = obj; return map }, {});
    const clients = props.clients.reduce((map, obj) => { map[obj.id] = obj; return map }, {});
    const types = creativeTypes.reduce((map, obj) => { map[obj.id] = obj.name; return map }, {});

    const dispatch = useDispatch();

    const [sortModel, setSortModel] = useState([
        {
            field: 'date',
            sort: 'desc',
        },
    ]);

    const formatDate = (date) => {
        // let month = '' + (date.getMonth() + 1 + Math.floor(Math.random() * 4)),
        //     day = '' + (date.getDate() + Math.floor(Math.random() * 27)),
        let month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear(),
            hour = date.getHours(),
            minute = date.getMinutes();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (hour < 10)
            hour = '0' + hour;
        if (minute < 10)
            minute = '0' + minute;

        return [day, month, year].join('-') + ' ' + [hour, minute].join(':');
    }

    const [rows, setRows] = useState([]);
    const columns = [
        { field: 'creativeName', headerName: 'Name', headerClassName: classes.grid_title, flex: 1 },
        { field: 'creativeType', headerName: 'Type', headerClassName: classes.grid_title, flex: 1 },
        { field: 'campaignName', headerName: 'Campaign Name', headerClassName: classes.grid_title, flex: 1 },
        { field: 'clientName', headerName: 'Client Name', headerClassName: classes.grid_title, flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: classes.grid_title,
            flex: 1,
            renderCell: (params) => {
                const statusClassName = params.value === 'active' ? 'green' : 'red';
                return <div className={` ${statusClassName}`}>{params.value}</div>;
            },
        },
        {
            field: 'date', 
            headerName: 'Date', 
            headerClassName: classes.grid_title, 
            flex: 1,
            valueFormatter: (params) => formatDate(params.value),
        },
    ];

    const handleGridData = useCallback((data) => {
        const creatives = data.creatives;
        let gridRows = [];
        for (let i = 0; i < creatives.length; i++) {
            let creative = creatives[i];
            let campaign = campaigns[creative.campaignId];
            const creativeCreationDate = new Date(creative.created);
            gridRows.push({
                id: creative.id,
                creativeId: creative.id,
                creativeName: creative.name,
                creativeType: types[creative.type.toLowerCase()],
                // creativeSize: `${creative.width}x${creative.height}`,
                campaignName: campaign.name,
                clientName: clients[campaign.clientId].name,
                type: creative.type,
                date: creativeCreationDate//.getDate() + '-' + (creativeCreationDate.getMonth() + 1) + '-' + creativeCreationDate.getFullYear() + ' ' + creativeCreationDate.getHours() + ':' + creativeCreationDate.getMinutes()
            });
        }
        setRows(gridRows);
        setApiDataNotReady(false);
    }, [campaigns, clients, types]);

    const handleRowClick = (e) => {
        e.preventDefault();
        setSelected(rows.find(row => row.id === e.currentTarget.getAttribute('data-id')));
        setMenuOpen(true);
        setMenuTop(e.pageY);
        setMenuLeft(e.pageX);
    };

    const handleClickAway = (e) => {
        setMenuOpen(false);
    };

    const handleEdit = (e) => {
        setMenuOpen(false);
        const creative = creativeData.find(creative => creative.id === selected.id);
        let campaign = campaigns[creative.campaignId];
        let client = clients[campaign.clientId];
        dispatch({ type: SET_CREATIVE_CLIENT_ID, clientId: client.id });
        dispatch({ type: SET_CREATIVE_CAMPAIGN_ID, campaignId: campaign.id });
        props.editCreative(selected.id);
    };

    const handleCopyId = (e) => {
        copyToClipboard(selected.id);
        setMenuOpen(false);
    };

    const handleDelete = (e) => {
        props.deleteCreative(selected.id, deleteCallback);
    };
    const handleDeleteDialogOpen = () => {
        setMenuOpen(false);
        setDeleteDialogOpen(true);
    }
    const handleDeleteDialogClose = (e) => {
        setDeleteDialogOpen(false);
    }

    const handleGenerateTagClick = (e) => {
        setMenuOpen(false);
        setGenerateTagPopupOpen(true);
    }

    const handlePreviewCreative = (e) => {
        const { type, campaignName, id } = selected;
        window.open(GENERATE_PREVIEW_LINK(type, campaignName, id), '_blank');
        setMenuOpen(false);
    }
    const deleteCallback = (id) => {
        handleDeleteDialogClose();
        const gridRows = rows.filter((r) => r.id !== id);
        setRows(gridRows);
    };

    const handleOpenExportDialog = () => {
        setOpenExportDialog(true);
        setMenuOpen(false);
    }

    const handleCloseExportDialog = () => {
        setOpenExportDialog(false);
    };

    const handleChangeFromDate = (value) => {
        const dateValue = new Date(value);
        dateValue.setHours(0, 0, 0, 0);
        setFrom(dateValue);
    };

    const handleChangeToDate = (value) => {
        setTo(new Date(value));
    };

    // useEffect(() => {
    //     if (rows && rows.length === 0) {
    //         setApiDataNotReady(false);
    //     }
    // }, [rows]);

    const exportReportCallback = (response) => {
        //* Getting xslx base64 string value from response result, convert it to xlsx file and download it.

        if (!response.result) {
            const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
            if (now - selected.date >= now - yesterday) {
                toast.error('Report was not found or there is no data!');
            } else {
                toast.warning('The report will be ready 24 hours after creative creation.');
            }
        } else {
            const base64xlsx = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.result}`;
            const a = document.createElement("a");
            a.href = base64xlsx;
            a.download = `report_${selected.creativeId}.xlsx`;
            a.click();
        }
        setOpenExportDialog(false);
    };

    const handleGetReports = (e) => {
        // const creativeType = selected.creativeType === 'interstitial' ? selected.creativeType :
        //     selected.creativeType === 'Half & Half' ? 'half_half' :
        //         selected.creativeType === 'Inline Video' ? 'inline' :
        //             selected.creativeType === 'Video Interstitial' ? 'video_interstitial' :
        //                 selected.creativeType === 'Gallery' ? 'gallery' :
        //                     selected.creativeType === 'Mobile Banner Video' ? 'mobile_banner_video' :
        //                         selected.creativeType === 'Flipper' ? 'flipper' :
        //                             selected.creativeType === 'Scratch' ? 'scratch' :
        //                                 selected.creativeType === 'Bounce Game' ? 'bounce_game' :
        //                                     selected.creativeType === 'Video Timer Banner' ? 'video_timer_banner' :
        //                                         selected.creativeType === 'Desktop Timer Banner' ? 'desktop_timer_banner' : '';

        const requestDate = {
            publisher: selected.creativeId,
            campigns: [
                ''
            ],
            medias: [
                'dolphin'
            ],
            from: new Date(from),
            to: new Date(to),
            forReport: false
        };

        exportReports(authToken, requestDate, exportReportCallback);
    };

    const handleDiscardTagGeneration = () => {
        setGenerateTagPopupOpen(false);
    };

    const handleEscapeTagGeneration = (event) => {
        if (event.key === 'Escape') {
            setGenerateTagPopupOpen(false);
        }
    };

    const handleEscDelete = (event) => {
        if (event.key === 'Escape') {
            setDeleteDialogOpen(false);
        }
    }

    useEffect(() => {


        if (!mountRef.current) {
            if (props.campaigns.length === 0 || props.clients.length === 0) {
                return;
            }
            getCreativeList(authToken, (data) => {
                if(data.error !== 0) {
                    toast.error('Error: ' + data.message);
                    return;
                }
                handleGridData(data);
                setCreativeData(data.creatives);
            });
            mountRef.current = true;
        }
    }, [authToken, handleGridData, props.campaigns, props.clients]);

    const dolphinCreativeOptions = <div>
        <MenuItem onClick={handlePreviewCreative}><MdPreview className='icon' />Preview Creative</MenuItem>
        <MenuItem onClick={handleGenerateTagClick}><MdGeneratingTokens className='icon' />Generate Tag</MenuItem>
    </div>
    /* CustomBanner Menu isn't allowed access to this options */
    const menu = <MenuList autoFocusItem={menuOpen} className="grid-menu" style={{ top: `calc( ${menuTop}px)`, left: `calc( ${menuLeft}px)`, overflowY: "auto", height: '160px' }}>
        {selected?.row?.type !== "custom_banner" && dolphinCreativeOptions}
        <MenuItem onClick={handleEdit}><MdEdit className="icon" />Edit</MenuItem>
        <MenuItem onClick={handleCopyId}><MdContentCopy className="icon" />Copy ID</MenuItem>
        <MenuItem onClick={handleDeleteDialogOpen}><MdDelete className="icon" />Delete</MenuItem>
        <MenuItem onClick={handleOpenExportDialog}><FaFileExport className="icon" />Get Reports</MenuItem>
    </MenuList >

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>
                Latest Campaigns
            </Typography>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className="grid" onContextMenu={(e) => e.preventDefault()}>
                    {/* {apiDataNotReady ? null : <InlineLoader />} */}
                    {generateTagPopupOpen && <GenerateTagPopup uniqueUser={props.uniqueUser} setOpen={setGenerateTagPopupOpen} selectedRow={selected} onDiscard={handleDiscardTagGeneration} onKeyDown={handleEscapeTagGeneration} />}
                    <DataGrid
                        density="compact"
                        components={{ Toolbar: GridToolbar }}
                        rows={rows}
                        columns={columns}
                        // onRowClick={handleRowClick}
                        autoPageSize
                        disableMultipleSelection={true}
                        disableColumnSelector
                        loading={apiDataNotReady}
                        onCellFocusOut={handleClickAway}
                        hideFooterSelectedRowCount
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        componentsProps={{
                            row: {
                                onContextMenu: handleRowClick,
                                style: { cursor: 'context-menu' },
                            },
                            toolbar: {
                                style: { backgroundColor: '#9BD4F5', padding: '4px 8px 4px 8px', borderRadius: '10px 10px 0 0', gap: '1vw' },
                            }
                        }}
                    />
                    {menuOpen && menu}
                    {deleteDialogOpen && <DeleteCreativeDialog onClose={handleDeleteDialogClose} handleDelete={handleDelete} onKeyDown={handleEscDelete} />}
                </div>
            </ClickAwayListener>

            <Dialog 
                open={openExportDialog} 
                onClose={handleCloseExportDialog}
                sx={{ padding: '1vw' }}
                >
                <DialogTitle sx={{ fontSize: '1.042vw' }}>
                    Export Reports
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '1vw'
                    }}
                >
                    <DialogContentText sx={{ fontSize: '0.833vw' }}>
                        Please select the date range:
                        </DialogContentText>
                    <Stack spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <DesktopDatePicker
                                    label="From"
                                    value={from}
                                    onChange={handleChangeFromDate}
                                    renderInput={(params) => <TextField {...params} />}
                                    inputFormat='DD-MM-YYYY'
                                />
                                <DesktopDatePicker
                                    label="To"
                                    value={to}
                                    onChange={handleChangeToDate}
                                    renderInput={(params) => <TextField {...params} />}
                                    inputFormat='DD-MM-YYYY'
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Stack>
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: 'space-between',
                        padding: '8px 24px',
                    }}
                >
                    <Button
                        onClick={handleGetReports}
                        variant='contained'
                        className={`${classes.fontFamily} ${classes.proceedBtn}`}
                    >
                        Export
                    </Button>
                    <Button
                        onClick={handleCloseExportDialog}
                        variant='text'
                        className={classes.fontFamily}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

function areEqual(prevProps, nextProps) {
    /* return true if value is not updated,  otherwise return false */
    if (JSON.stringify(prevProps.campaigns) !== JSON.stringify(nextProps.campaigns) || JSON.stringify(prevProps.clients) !== JSON.stringify(nextProps.clients)) {
        return false;
    }
    return true;
}

export default React.memo(CreativeGrid, areEqual);

