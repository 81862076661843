import React, { useState } from 'react';
import './style.scss';
import Button from '../Input/Button';
import { toast } from 'react-toastify';

export default function NewClient(props) {
    const titleValue = props.title ? props.title : 'New Client';
    const [nameVal, setNameVal] = useState(props.name);
    const [nameError, setNameError] = useState(false);

    const handleNameChange = (e) => {
        setNameVal(e.target.value);
    };

    const handleConfirm = () => {
        if (!nameVal && nameVal === '') {
            toast.error('Error: Missing client name', { theme: "colored" });
            setNameError(true);
            return;
        }
        setNameError(false);
        props.confirmCallback({ name: nameVal });
    };

    const handleDiscard = () => {
        props.discardCallback();
    };

    return (
        <div className="pop-up-container">
            <div className="new-creative pop-up box-shadow">
                <header>{titleValue}</header>
                <main className="form">
                    <div className="input-section">
                        <label className="label">Client Name:</label>
                        <input onChange={handleNameChange} value={nameVal} type="text" className={nameError ? 'input error' : 'input'} />
                    </div>
                </main>
                <footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button className="no-btn mr-20" onClick={handleDiscard} variant="text">Cancel</Button>
                    <Button className="yes-btn colored-light" onClick={handleConfirm} variant="contained">Proceed</Button>
                </footer>
            </div>
        </div>
    )
}