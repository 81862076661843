export const COLOR_VALUE = 'COLOR_VALUE';
export const DEFAULT_COLOR = '#0A4356';

const SQUARE = 'Square';
const RECTANGLE = 'Rectangle';
/*const CIRCLE = 'Circle';
const OVAL = 'Oval';*/
const TRIANGLE = 'Triangle';
const TRAPEZOID = 'Trapezoid';
const EGG = 'Egg';

const shapes = [
    {
        id:SQUARE,
        name: SQUARE,
        styleCss:'.square {width: 100px;height: 100px;background-size: cover; background-position: center;background-color: COLOR_VALUE;}',
        inlineCss: {width: '100px',height: '100px', backgroundSize: 'cover', backgroundPosition: 'center'},
        hasBgImg:true,
        hasBorderRadius:true,
        colorField:{
            reactField: 'backgroundColor',
            value:`${COLOR_VALUE}`
        }
    },
    {
        id:RECTANGLE,
        name: RECTANGLE,
        styleCss:'.rectangle {width: 200px;height: 100px;background-size: cover; background-position: center;background-color: COLOR_VALUE;}',
        inlineCss: {width: '200px',height: '100px', backgroundSize: 'cover', backgroundPosition: 'center'},
        hasBgImg: true,
        hasBorderRadius:true,
        colorField: {
            reactField: 'backgroundColor',
            value:`${COLOR_VALUE}`
        }
    },
/*    {
        id:CIRCLE,
        name: CIRCLE,
        styleCss:'.circle {width: 100px;height: 100px; border-radius: 50%;background-size: cover; background-position: center;background-color: COLOR_VALUE;}',
        inlineCss: {width: '100px',height: '100px', borderRadius: '50%', backgroundSize: 'cover', backgroundPosition: 'center'},
        hasBgImg: true,
        colorField: {
            reactField: 'backgroundColor',
            value:`${COLOR_VALUE}`
        }
    },
    {
        id:OVAL,
        name: OVAL,
        styleCss:'.oval {width: 200px;height: 100px;border-radius: 100px / 50px;background-size: cover; background-position: center;background-color: COLOR_VALUE;}',
        inlineCss: {width: '200px',height: '100px', borderRadius: '100px / 50px', backgroundSize: 'cover', backgroundPosition: 'center'},
        hasBgImg: true,
        colorField: {
            reactField: 'backgroundColor',
            value:`${COLOR_VALUE}`
        }
    },*/
    {
        id:TRIANGLE,
        name: TRIANGLE,
        styleCss:'.triangle {width: 0px;height: 0px;border-left: 50px solid transparent; border-right: 50px solid transparent; border-bottom: 100px solid COLOR_VALUE;}',
        inlineCss: {width: '0px',height: '0px', borderLeft: '50px solid transparent', borderRight: '50px solid transparent'},
        hasBgImg: false,
        hasBorderRadius:false,
        colorField: {
            reactField: 'borderBottom',
            value:`100px solid ${COLOR_VALUE}`
        }
    },
    {
        id:TRAPEZOID,
        name: TRAPEZOID,
        styleCss:'.trapezoid {width: 100px;height: 0;border-left: 25px solid transparent;border-right: 25px solid transparent; border-bottom: 100px solid COLOR_VALUE;}',
        inlineCss: {width: '100px',height: 0, borderLeft: '25px solid transparent', borderRight: '25px solid transparent'},
        hasBgImg: false,
        hasBorderRadius:false,
        colorField: {
            reactField: 'borderBottom',
            value:`100px solid ${COLOR_VALUE}`
        }
    },
    {
        id:EGG,
        name: EGG,
        styleCss:'.egg {width: 126px;height: 180px; border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;background-size: cover; background-position: center;background-color: COLOR_VALUE;}',
        inlineCss: {width: '126px',height: '180px', borderRadius:'50% 50% 50% 50% / 60% 60% 40% 40%', backgroundSize: 'cover', backgroundPosition: 'center'},
        hasBgImg: true,
        hasBorderRadius:false,
        colorField: {
            reactField: 'backgroundColor',
            value:`${COLOR_VALUE}`
        }
    }
]

export default shapes;
