import './style.scss';
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import placeholder from '../../Components/utils/images/creative_images/placeholder.png';
// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';

export default function Interstitial(props) {
    const outerRef = useRef();

    // const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);
    const bgColor = useSelector(state => state.creative.backgroundColor);
    const assets = useSelector(state => state.creative.assets);

    const firstFrontImage = assets?.find(asset => asset.url !== '' && asset.type.toLowerCase() === "front_image" && asset.width === 640 && asset.height === 1370) || { url: '' };
    const firstBackgroundImage = assets?.find(asset => asset.url !== '' && asset.type.toLowerCase() === "background_image" && asset.width === 640 && asset.height === 1370) || { url: '' };
    const firstBackImage = assets?.find(asset => asset.url !== '' && asset.type.toLowerCase() === "back_image" && asset.width === 640 && asset.height === 1370) || { url: '' };

    const [xDown, setXDown] = useState(null);
    const [yDown, setYDown] = useState(null);

    const bannerRatio = 0.5;

    const inCommonStyles = {
        backgroundColor: bgColor,
        minHeight: height * 0.2,
        minWidth: height * 0.2 * bannerRatio,
        width: '100%',
        height: '100%',
        borderRadius: '1.5vw',
        transformStyle: 'preserve-3d',
        WebkitTransformStyle: 'preserve-3d',
        MozTransformStyle: 'preserve-3d',
        OTransformStyle: 'preserve-3d',
        msTransformStyle: 'preserve-3d',
    }

    const right = {
        ...inCommonStyles,
        transform: 'rotateY(0deg)',
        WebkitTransform: 'rotateY(0deg)',
        MozTransform: 'rotateY(0deg)',
        OTransform: 'rotateY(0deg)',
        msTransform: 'rotateY(0deg)',
    };

    const left = {
        ...inCommonStyles,
        transform: 'rotateY(180deg)',
        WebkitTransform: 'rotateY(180deg)',
        MozTransform: 'rotateY(180deg)',
        OTransform: 'rotateY(180deg)',
        msTransform: 'rotateY(180deg)',
    };

    const [currentSide, setCurrentSide] = useState(right);

    function getTouches(event) {
        return (
            event.touches || // browser API
            event.originalEvent.touches
        ); // jQuery
    }

    const handleTouchStart = (event) => {
        const firstTouch = getTouches(event)[0];
        setXDown(firstTouch.clientX);
        setYDown(firstTouch.clientY);
    };

    const handleTouchMove = (event) => {
        if (!xDown || !yDown) {
            return;
        }

        const xUp = event.touches[0].clientX;
        const yUp = event.touches[0].clientY;

        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /*most significant*/
            if (xDiff > 0) {
                /* right swipe */
                setCurrentSide(right);

            } else {
                /* left swipe */
                setCurrentSide(left);
            }
        } else {
            if (yDiff > 0) {
                /* down swipe */
            } else {
                /* up swipe */
            }
        }
        /* reset values */
        setXDown(null);
        setYDown(null);
    };

    const handleMouseDown = (event) => {
        setXDown(event.pageX);
        setYDown(event.pageY);

    };

    const handleMouseMove = (event) => {
        if (!xDown || !yDown) {
            return;
        }

        const xUp = event.clientX;
        const yUp = event.clientY;

        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /*most significant*/
            if (xDiff > 0) {
                /* right swipe */
                setCurrentSide(right);

            } else {
                /* left swipe */
                setCurrentSide(left);
            }
        } else {
            if (yDiff > 0) {
                /* down swipe */
            } else {
                /* up swipe */
            }
        }
        /* reset values */
        setXDown(null);
        setYDown(null);
    };

    return (
        <div 
            className="banner-display-container interstitial-border" 
            style={{ 
                // backgroundImage: `url(${IphoneFrameImage})`, 
                width: '13vw', 
                height: '26vw',
                backgroundColor: 'white',
                borderRadius: '2vw',
            }}
        >
        {/*<div className="banner-display-container grid-content">*/}
            <img className='banner-display-frame-image' alt="" /> {/* src={IphoneFrameFrontCameraImage} /> */}
            <div className="banner-box flipper">
                <div className=''></div>
                <div id="flip-box" className="target flip-box"
                    ref={outerRef}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                >
                    <div className="flip-box-inner" id="flip-box-inner" style={currentSide}>
                        <div className="front" style={{ borderRadius: '1vw', backgroundImage: `url(${firstBackgroundImage?.url !== '' ? firstBackgroundImage.url : ""})` }}>
                            <div className="inner">
                                <img id="ds__img" className="frontImg" src={firstFrontImage?.url !== '' ? firstFrontImage.url : placeholder} alt="" draggable="false" style={{ borderRadius: '1vw', background: firstFrontImage?.url !== '' ? 'none' : '#ff00004f' }} />
                            </div>
                        </div>
                        <div id="flip-box-back" className="back" style={{ borderRadius: '1vw', backgroundImage: `url(${firstBackgroundImage?.url !== '' ? firstBackgroundImage.url : ""})` }}>
                            <div className="inner">
                                <img id="ds__img" className="backImg" src={firstBackImage?.url !== '' ? firstBackImage.url : placeholder} alt="" draggable="false" style={{ borderRadius: '1vw', background: firstBackImage?.url !== '' ? 'none' : '#0000ff75' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

