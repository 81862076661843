import * as actionTypes from '../action';


const initialState = {
    textId: null,
    text: null,
    fontSize: null,
    fontColor:null,
    fontType:null,
    textIndex:null,
    animationType:'none',
    iterationType:'default',
    animationDelay:0,
    animationDuration:0,
    redirectionUrl:''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
            case actionTypes.SET_STYLE_TEXT_FOCUS:
                return {
                    ...state,
                    textId: action.uiId,
                    fontType: action.fontType,
                    fontSize: action.fontSize,
                    fontColor: action.fontColor,
                    textIndex:action.index,
                    animationDelay: action.animationDelay,
                    animationType: action.animationType,
                    iterationType: action.iterationType,
                    animationDuration: action.animationDuration,
                    animationData: action.animationData,
                    redirectionUrl: action.redirectionUrl,
                    text: action.text
                }
        case actionTypes.SET_STYLE_TEXT_REDIRECTION_URL:
            return {
                ...state,
                redirectionUrl: action.value
            }
        case actionTypes.SET_STYLE_TEXT_COLOR:
            return {
                ...state,
                fontColor: action.value
            }
        case actionTypes.SET_STYLE_TEXT_Z_INDEX:
            return {
                ...state,
                textIndex: action.value
            }
        case actionTypes.SET_STYLE_TEXT_FONT_FAMILY:
            return {
                ...state,
                fontType: action.value
            }
        case actionTypes.SET_STYLE_TEXT_FONT_SIZE:
            return {
                ...state,
                fontSize: action.value
            }
        case actionTypes.SET_STYLE_TEXT_ANIMATION_TYPE:
            return {
                ...state,
                animationType: action.value
            }
        case actionTypes.SET_STYLE_TEXT_ITERATION_TYPE:
            return {
                ...state,
                iterationType: action.value
            }
        case actionTypes.SET_STYLE_TEXT_DELAY_TYPE:
            return {
                ...state,
                animationDelay: action.value
            }
        case actionTypes.SET_STYLE_TEXT_ANIMATION_DURATION:
            return {
                ...state,
                animationDuration: action.value
            }
        case actionTypes.SET_STYLE_TEXT_CONTENT:
            return {
                ...state,
                text: action.value
            }
        default:
            return state;
    }

}

export default reducer;