import {PRESET_ONE_LOGO, PRESET_ONE_MAIN, PRESET_ONE_BG} from '../images/imagePresets';
import {OPEN_SANS} from './fonts';
import * as animations from './animations';
export const DEFAULT_PRESET_ID = 'default';

const defaultPreset = {
    id:DEFAULT_PRESET_ID,
    name:''
};

const presets = [
    {
        id:'slide_in_1',
        name:'Portrait #1',
        className: 'portrait-slide-in-1',
        size:'300x600',
        assets:[
            {
                type: 'image',
                title:'Background',
                className: 'bg1',
                value: PRESET_ONE_BG.base64,
                name: PRESET_ONE_BG.name,
                animationType: animations.NONE,
                animationDelay: 0
            },
            {
                type: 'image',
                title:'Logo',
                className: 'logo1',
                width: 150,
                height:88,
                value: PRESET_ONE_LOGO.base64,
                name: PRESET_ONE_LOGO.name,
                animationType: animations.SLIDE_IN_DOWN,
                animationDelay: 0,
                animationDuration:0.8,
                transform: 'matrix(1, 0, 0, 1, 0, 0) translate(80px, 10px)'
            },
            {
                type: 'image',
                title:'Main image',
                className: 'image1',
                width: 200,
                height:126,
                value: PRESET_ONE_MAIN.base64,
                name: PRESET_ONE_MAIN.name,
                animationType: animations.SLIDE_IN_LEFT,
                animationDelay: 0.3,
                animationDuration:0.8,
                transform: 'matrix(1, 0, 0, 1, 0, 0) translate(50px, 345px)'
            },
            {
                type: 'text',
                title:'Action text',
                className: 'action1',
                value:'Click Me',
                fontSize:30,
                fontFamily:OPEN_SANS,
                fontColor:'#ffffff',
                animationType: animations.SLIDE_IN_RIGHT,
                animationDelay: 0,
                animationDuration:1,
                transform: 'matrix(1, 0, 0, 1, 0, 0) translate(90px, 250px)'
            },
            {
                type: 'text',
                title:'Slogan 1',
                className: 'slogan1',
                value:'Attention captivating slogan',
                fontSize:20,
                fontFamily:OPEN_SANS,
                fontColor:'#ffffff',
                animationType: animations.SLIDE_IN_LEFT,
                animationDelay: 0.5,
                animationDuration:0.3,
                transform: 'matrix(1, 0, 0, 1, 0, 0) translate(20px, 135px)'
            },
            {
                type: 'text',
                title:'Slogan 2',
                className: 'slogan2',
                value:'continuation of slogan',
                fontSize:20,
                fontFamily:OPEN_SANS,
                fontColor:'#ffffff',
                animationType: animations.SLIDE_IN_RIGHT,
                animationDelay: 1.2,
                animationDuration:0.3,
                transform:'matrix(1, 0, 0, 1, 0, 0) translate(45px, 165px)'
            },
            {
                type: 'text',
                title:'Credentials 1',
                className: 'credentials1',
                value:'1, Fake Street,Fake City',
                fontSize:15,
                fontFamily:OPEN_SANS,
                fontColor:'#ffffff',
                animationType: animations.SLIDE_IN_UP,
                animationDelay: 0.3,
                animationDuration:1.1,
                transform:'matrix(1, 0, 0, 1, 0, 0) translate(70px, 535px)'
            },
            {
                type: 'text',
                title:'Credentials 2',
                className: 'credentials2',
                value:'www.fakesite@.com',
                fontSize:13,
                fontFamily:OPEN_SANS,
                fontColor:'#ffffff',
                animationType: animations.SLIDE_IN_UP,
                animationDelay: 0.8,
                animationDuration:1,
                transform:'matrix(1, 0, 0, 1, 0, 0) translate(90px, 565px)'
            }
        ]
    }
];

export function getPresets(size) {
    const matches = presets.filter((p)=> p.size === size);
    return [defaultPreset, ...matches];
}


