export const CUSTOM_BANNER = 'custom_banner';
export const INTERSTITIAL = 'interstitial';
export const HALF_HALF = 'half_and_half'
export const INLINE = 'inline';
export const VIDEO_INTERSTITIAL = 'video_interstitial';
export const GALLERY = 'gallery';
export const MOBILE_BANNER_VIDEO = 'mobile_banner_video';
export const FLIPPER = 'flipper';
export const SCRATCH = 'scratch';
export const BOUNCE_GAME = 'bounce_game';
export const VIDEO_TIMER_BANNER = 'video_timer_banner';
export const DESKTOP_TIMER_BANNER = 'desktop_timer_banner';
export const VIDEO_GALLERY = 'video_gallery';
export const COLLECT_OR_EVADE_GAME = 'collect_or_evade_game';
export const PROGRAM_VIDEO = 'program_video';
export const HALF_VIDEO = 'half_video';
export const UNIVERSAL = 'universal';