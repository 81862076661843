import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import CloseButton from 'react-bootstrap/CloseButton';

import Banner from '../CreativeTemplates/Banner';
import Interstitial from '../CreativeTemplates/Interstitial';
import HalfAndHalf from '../CreativeTemplates/HalfAndHalf';
import Inline from '../CreativeTemplates/Inline';
import VideoInterstitial from '../CreativeTemplates/VideoInterstitial';
import Gallery from '../CreativeTemplates/Gallery';
import MobileBannerVideo from '../CreativeTemplates/MobileBannerVideo';
import Flipper from '../CreativeTemplates/Flipper';
import Scratch from '../CreativeTemplates/Scratch';
import BounceGame from '../CreativeTemplates/BounceGame';
import VideoTimerBanner from '../CreativeTemplates/VideoTimerBanner';
import DesktopTimerBanner from '../CreativeTemplates/DesktopTimerBanner';
import VideoGallery from '../CreativeTemplates/VideoGallery';
import CollectOrEvadeGame from '../CreativeTemplates/CollectOrEvadeGame';
import ProgramVideo from '../CreativeTemplates/ProgramVideo';

// import CreativeMenu from '../CreativeTemplates/CreativeMenu';
// import StyleMenuContainer from '../StyleMenus/StyleMenuContainer';
import CreativeAssetsMenu from '../CreativeAssetsMenu/CreativeAssetsMenu';
import ActionFooter from '../Layout/ActionFooter';
import LoadingIndicator from '../Layout/LoadingIndicator';

import { CREATIVE, MAIN } from '../../constants/path';
import { toast } from 'react-toastify';
import { clearAllBannerStyleTags, getAnimationData, getAnimationClassName, formatCreativeForSave } from '../../utils.js';
import { getCreative, postCreative, resizeMedia } from '../../api_requests';
import { handleCurrentVideos, handleFieldInit } from '../CreativeTemplates/handlers';
import /*creativeTypes,*/ {
    CUSTOM_BANNER,
    INTERSTITIAL,
    HALF_HALF,
    INLINE,
    VIDEO_INTERSTITIAL,
    GALLERY,
    MOBILE_BANNER_VIDEO,
    FLIPPER,
    SCRATCH,
    BOUNCE_GAME,
    VIDEO_TIMER_BANNER,
    DESKTOP_TIMER_BANNER,
    VIDEO_GALLERY,
    COLLECT_OR_EVADE_GAME,
    PROGRAM_VIDEO,
    HALF_VIDEO,
    UNIVERSAL
} from '../utils/options/creativeTypes';
import { /*IMAGE, POSTER,*/ POSTER, VIDEO } from '../../constants/imageTypes';
// import placeholder from '../../Components/utils/images/avatar_box_placeholder.png';
import * as actionTypes from '../../store/action';
// import BottomMenu from '../CreativeTemplates/BottomMenu';
import HalfVideo from '../CreativeTemplates/halfVideo';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import CreativeRedirectionMenu from '../CreativeRedirectionMenu/CreativeRedirectionMenu.js';
import NewCreative from '../PopUp/NewCreative.js';
import Universal from '../CreativeTemplates/Universal.js';

export default function CreativePage(props) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const creativeTabs = ['General', 'Creative', 'Redirection'];

    const creative = useSelector(state => state.creative);
    // const creativeOptions = creativeTypes.find(type => type.id === creative.type);

    const background = useSelector(state => state.creative.backgroundColor);

    const didMountRef = React.useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.user.token);
    const crt = useSelector(state => state.creative);
    const images = useSelector(state => state.img.images);
    const texts = useSelector(state => state.txt.texts);
    const shapes = useSelector(state => state.shape.shapes);
    const currentVideos = useSelector(state => state.creative.currentVideos);
    const videoOnly = useSelector(state => state.creative.videoOnly);
    // const img_style = useSelector(state => state.img_style);
    // const user = useSelector(state => state.user);

    const nameVal = useSelector(state => state.new_creative.name);
    // const type = useSelector(state => state.new_creative.type);
    // const clientID = useSelector(state => state.new_creative.clientId);
    // const campaignID = useSelector(state => state.new_creative.campaignId);

    // const currentType = props.creativeType ? props.creativeType : type ? type : creativeTypes[0].id;
    const currentType = useSelector(state => state.creative.type);
    // const currentClient = clientID ? clientID : user.clients[0].id;
    // const campaigns = user.campaigns.filter(c => c.clientId === currentClient);
    // const currentCampaign = (campaignID) ? (campaignID) : ((campaigns.length > 0) ? (campaigns[0].id) : (null));

    const imageCompression = useSelector(state => state.localState.imageCompression);

    const [requestingData, setRequestingData] = useState(true);

    const currentPath = location.pathname.replace('/creative/', '');

    const handleReturn = useCallback(() => {
        dispatch({ type: actionTypes.SET_LEFT_DRAWER_CURRENT_TAB , value: 0 });
        dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: false });
        dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: false });
        dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: false });
        navigate(MAIN);
    }, [navigate, dispatch]);

    const setCurrentVideos = useCallback((creative) => {
        const videoAssets = creative.assets.filter(asset => asset.type === 'video');
        handleCurrentVideos(dispatch, videoAssets);
    }, [dispatch]);

    const handleCreativeInit = useCallback((data) => {
        handleFieldInit(dispatch, data.creative);
        setCurrentVideos(data.creative);
        setRequestingData(false);
    }, [dispatch, setCurrentVideos]);

    const handleProceed = async () => {
        dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: false });
        dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: false });
        dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: false });

        if ((!crt.name && !nameVal) || (crt.name === "" && nameVal === "")) {
            toast.error('Error: Missing creative name');
            return;
        } 
        // else {
        //     dispatch({ type: actionTypes.SET_CREATIVE_FIELDS, name: nameVal, creativeType: currentType, clientId: currentClient, campaignId: currentCampaign });
        // }
        if (crt.type === CUSTOM_BANNER) {
            if ((images.length < 1 && texts.length < 1 && shapes.length < 1) && (!crt.backgroundImage && !crt.backgroundVideo)) {
                toast.error('Error: Empty creative');
                return;
            }
        } else {
            if ((typeof crt.autoCloseTimer === 'number' && isNaN(crt.autoCloseTimer)) || (typeof crt.autoCloseTimer === 'string' && crt.autoCloseTimer.includes('-'))) {
                toast.error('Error: Invalid auto close timer input');
                return;
            }
        }

        if(crt.type === UNIVERSAL) {
            if (crt.assets.find(asset => asset.url === '') !== undefined || crt.assets.length === 0) {
                toast.error('Error: Missing assets');
                return;
            }
        }

        if ([INTERSTITIAL, HALF_HALF, FLIPPER, SCRATCH, COLLECT_OR_EVADE_GAME].includes(crt.type)) {
            if (crt.assets.find(asset => asset.url === '') !== undefined) {
                toast.error('Error: Missing image customs');
                return;
            }
        }

        if (crt.type === INLINE) {
            const vid = crt.assets.find(asset => asset.type === VIDEO);
            const poster = crt.assets.find(asset => asset.type === POSTER);
            if ((!vid?.url || vid?.url === '') && videoOnly) {
                toast.error('Error: Missing video');
                return;
            } else {
                if (!videoOnly && (!poster?.url || poster?.url === '')) {
                    toast.error('Error: Missing poster');
                    return;
                }
            }
        }

        if (crt.type === VIDEO_INTERSTITIAL) {
            const vids = crt.assets.every(asset => asset.url !== "");
            if (!vids) {
                toast.error('Error: Missing interstitial video');
                return;
            }
        }

        if (crt.type === GALLERY) {
            if (crt.assets.find(asset => asset.url === '') !== undefined) {
                // toast.error('Error: Missing image customs. \t Required: background, banner, logo and at least 3 images');
                toast.error('Error: Missing image customs.');
                return;
            }
        }

        if (crt.type === MOBILE_BANNER_VIDEO) {
            const vid = crt.assets.find(asset => asset.type === VIDEO);
            if (!vid?.url || vid?.url === '') {
                toast.error('Error: Missing video');
                return;
            }

            if (crt.assets.filter(img => img.type !== 'image').find(asset => asset.url === '') !== undefined) {
                toast.error('Error: Missing image customs. \t Required: background, top-image, bottom-image and button');
                return;
            }
        }

        if (crt.type === SCRATCH) {
            if (crt.assets.find(asset => asset.url === '' && asset.type !== 'Movable_Image') !== undefined) {
                toast.error('Error: Missing image customs');
                return;
            }
            if ((typeof crt.scratchComplete === 'number' && isNaN(crt.scratchComplete)) || (typeof crt.scratchComplete === 'string' && crt.scratchComplete.includes('-'))) {
                toast.error('Error: Invalid scratch completion input');
                return;
            } else {
                if (crt.scratchComplete < 0 || crt.scratchComplete > 100) {
                    toast.error('Error: scratch completion must be a number between 0 - 100');
                    return;
                }
            }
        }

        if (crt.type === BOUNCE_GAME) {

        }

        if (crt.type === VIDEO_TIMER_BANNER || crt.type === DESKTOP_TIMER_BANNER) {
            const vid = crt.assets.find(asset => asset.type === VIDEO);
            if (!vid?.url || vid?.url === '') {
                toast.error('Error: Missing video');
                return;
            }

            if (crt.assets.filter(img => img.type !== 'image').find(asset => asset.url === '') !== undefined) {
                toast.error('Error: Missing image customs. \t Required: background, top-image, bottom-image and button');
                return;
            }

            if (crt.showTimer && new Date(crt.timerEndDate) < new Date()) {
                toast.error('Error: End Date cannot be earlier than the current time');
                return;
            }
        }

        if (crt.type === VIDEO_GALLERY) {
            const vid = crt.assets.find(asset => asset.type === VIDEO);
            if (!vid?.url || vid?.url === '') {
                toast.error('Error: Missing video');
                return;
            }

            if (crt.assets.find(asset => asset.url === '') !== undefined) {
                // toast.error('Error: Missing image customs. \t Required: background, banner, logo and at least 3 images');
                toast.error('Error: Missing image customs.');
                return;
            }
        }

        let result = crt.assets;
        setRequestingData(true);

        result = [];
        for (let asset of crt.assets) {
            const mimeTypeRegex = /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/;
            const mimeType = asset.url.match(mimeTypeRegex)[1];
            if (currentVideos) { // if edit creative
                const videoDidNotChange = currentVideos.find(currentVideo => asset.url === currentVideo.url);
                if (!videoDidNotChange) { // if new video was uploaded
                    if (mimeType === 'video/mp4' || mimeType === 'image/gif') {
                        toast.warning("Your file is getting resized and compressed...");
                        const compressedFile = await resizeIfFileTooBig(asset, mimeType, false);
                        if (!compressedFile) {
                            setRequestingData(false);
                            toast.warning('Could not compress the file. Please try raise compression power or upload new file.', { autoClose: false });
                            return;
                        }
                        result.push({ ...asset, url: compressedFile });
                    } else {
                        result.push(asset);
                    }
                } else { // else video did not change
                    result.push(asset);
                    continue;
                }
            } else { // else new creative
                const mimeTypeRegex = /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/;
                const mimeType = asset.url.match(mimeTypeRegex)[1];
                if (mimeType === 'video/mp4' || mimeType === 'image/gif') {
                    toast.warning("Your file is getting resized and compressed...");
                    const compressedFile = await resizeIfFileTooBig(asset, mimeType, false);
                    if (!compressedFile) {
                        setRequestingData(false);
                        toast.warning('Could not compress the file. Please try raise compression power or upload new file.', { autoClose: false });
                        return;
                    }
                    result.push({ ...asset, url: compressedFile });
                } else {
                    const resizedAsset = await resizeIfImageTooBig(asset, mimeType);
                    result.push({...asset, url: resizedAsset});
                }
            }
        }
        // }
        // dispatch to async action

        const tempCreative = { ...crt, assets: result }
        const creative = formatCreativeForSave(tempCreative, images, texts, shapes);
        setTimeout(() => {
            postCreative(authToken, creative, handleAddCreativeCallback);
        }, 500);
    };

    const resizeIfFileTooBig = async (asset, mimeType, abortCreativeSubmition) => {
        const controller = new AbortController(); // create new AbortController
        const signal = controller.signal; // get the signal from the controller

        const res = await fetch(asset.url)
        const blob = await res.blob();
        const fileName = mimeType.split('/')[0].concat('.', mimeType.split('/')[1]); // video/mp4 => video.mp4
        const vid = new File([blob], fileName, { type: mimeType });
        const result = await resizeMedia(vid, { width: asset.width, height: asset.height, compression: crt.compression }, signal);

        if (abortCreativeSubmition) {
            controller.abort();
            return null;
        }

        if (result.fileSize < 4) {
            return result.base64File;
        } else {
            const roundedResult = Math.round((result.fileSize + Number.EPSILON) * 100) / 100;
            toast.error(`Error: File size exceeded 4MB. Got ${roundedResult}MB`);
            return await resizeIfFileTooBig({ url: result.base64File, width: asset.width, height: asset.height }, mimeType, true);
        }
    };

    const resizeIfImageTooBig = (asset, mimeType) => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const img = new Image();
            img.src = asset.url;

            img.onload = function () {
                // Set canvas dimensions to the image dimensions
                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, img.width, img.height);

                // Adjust quality to reduce file size
                const quality = imageCompression; // Change this value between 0 and 1 to adjust quality

                // Get the compressed image as base64
                const compressedDataUrl = canvas.toDataURL(mimeType, mimeType === 'image/jpeg' ? quality : 1);

                // Optional: Convert base64 to Blob and calculate size
                // const compressedBlob = dataURLToBlob(compressedDataUrl);

                // Clean up by removing the canvas from the DOM
                canvas.remove();

                // Resolve the promise with the compressed data URL
                resolve(compressedDataUrl);
            };

            img.onerror = function (error) {
                reject(error);
            };

        });

        // function dataURLToBlob(dataURL) {
        //     const byteString = atob(dataURL.split(',')[1]);
        //     const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        //     const ab = new ArrayBuffer(byteString.length);
        //     const ia = new Uint8Array(ab);
        //     for (let i = 0; i < byteString.length; i++) {
        //         ia[i] = byteString.charCodeAt(i);
        //     }
        //     return new Blob([ab], { type: mimeString });
        // }
    }

    const handleAddCreativeCallback = (data) => {
        setRequestingData(false);
        if (data.error === 0) {
            navigate(MAIN);
            return;
        }
    };

    useEffect(() => {
        clearAllBannerStyleTags();
        if (currentPath !== CREATIVE) {
            if (!didMountRef.current) {
                didMountRef.current = true;
                getCreative(authToken, currentPath, handleCreativeInit);
            }
        }
        // else if (!crt.type || !crt.campaignId) {
        //     handleReturn();
        // }
        else {
            if (!didMountRef.current) {
                didMountRef.current = true;
                setRequestingData(false);
            }
        }
    }, [authToken, currentPath, crt.type, crt.campaignId, handleCreativeInit, handleReturn]);

    useEffect(() => {
        if (!requestingData) {
            let combined = [...texts, ...images];
            for (let i = 0; i < combined.length; i++) {
                let item = combined[i];
                let className = getAnimationClassName(item.uiId, item.animationType, item.iterationType, item.animationDelay, item.animationDuration);
                if (document.querySelectorAll(`style[data-animation-id="${className}"]`).length === 0) {
                    getAnimationData(item, item.animationType, item.iterationType, item.animationDelay, item.animationDuration);
                }
            }
        }
    }, [requestingData, texts, images]);

    const renderCreativeByType = () => {
        switch (crt.type) {
            case CUSTOM_BANNER:
                return <Banner background={background} />;
            case INTERSTITIAL:
                return <Interstitial background={background} />;
            case HALF_HALF:
                return <HalfAndHalf background={background} />;
            case INLINE:
                return <Inline background={background} />;
            case VIDEO_INTERSTITIAL:
                return <VideoInterstitial background={background} />;
            case GALLERY:
                return <Gallery background={background} />;
            case MOBILE_BANNER_VIDEO:
                return <MobileBannerVideo background={background} />;
            case FLIPPER:
                return <Flipper background={background} />;
            case SCRATCH:
                return <Scratch background={background} />;
            case BOUNCE_GAME:
                return <BounceGame background={background} />;
            case VIDEO_TIMER_BANNER:
                return <VideoTimerBanner background={background} />;
            case DESKTOP_TIMER_BANNER:
                return <DesktopTimerBanner background={background} />;
            case VIDEO_GALLERY:
                return <VideoGallery background={background} />;
            case COLLECT_OR_EVADE_GAME:
                return <CollectOrEvadeGame background={background} />;
            case PROGRAM_VIDEO:
                return <ProgramVideo background={background} />;
            case HALF_VIDEO:
                return <HalfVideo background={background} />;
            case UNIVERSAL:
                return <Universal background={background} />;
            default:
                return null;
        }
    };

    // const abortSubmition = () => {
    //     setAbortCreativeSubmition(true);
    // }

    const handleTabChange = (event) => {
        const newTabIndex = creativeTabs.indexOf(event.target.value);
        setSelectedTabIndex(newTabIndex);
        // const doesCreativeHasAssets = creative?.assets?.some(assets => assets.url === placeholder);
        // if(!doesCreativeHasAssets) {
        //     dispatch({ type: actionTypes.SET_CREATIVE_FIELDS, name: nameVal, creativeType: currentType, clientId: currentClient, campaignId: currentCampaign });
        // }
    };

    return (
        <div className="page-container creative-page">
            {requestingData && <LoadingIndicator />}
            {/* {requestingData && (
                <div className='abort_container'>
                    <CloseButton className='abort_btn' variant='white' onClick={abortSubmition} />
                </div>
            )} */}
            {/* <SideMenu />
            <StyleMenuContainer />
            {creativeOptions?.options?.programVideo && <BottomMenu />}
            <ActionFooter returnCallback={handleReturn} proceedCallback={handleProceed} />
            <CreativeMenu itemsCount={images.length + texts.length + shapes.length} images={images} texts={texts} shapes={shapes} />
            {renderCreativeByType()} */}
            <div className='creative-page-container'>
                <ToggleButtonGroup
                    color="primary"
                    // value={alignment}
                    exclusive
                    // onChange={handleChange}
                    aria-label="Platform"
                    sx={{
                        borderRadius: '0',
                    }}
                >
                    {creativeTabs.map((tab, index) => (
                        <ToggleButton
                            key={`${tab}-${index}`}
                            value={tab}
                            onClick={handleTabChange}
                            sx={{
                                width: '100%',
                                textTransform: 'none',
                                border: 'none',
                                color: selectedTabIndex !== index ? 'white' : 'black',
                                backgroundColor: selectedTabIndex !== index ? '#9BD4F5' : 'white',
                                fontSize: '1vw',
                                borderRadius: '0',
                                '&:hover': {
                                    backgroundColor: selectedTabIndex !== index ? '#83d1ff' : 'white',
                                }
                            }}
                            disabled={(index === 1 || index === 2) && !creative.type ? true : false}
                        >
                            {tab}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                {selectedTabIndex === 0 && <NewCreative creative={creative} creativeType={currentType} />}
                {selectedTabIndex === 1 && <CreativeAssetsMenu itemsCount={images.length + texts.length + shapes.length} images={images} texts={texts} shapes={shapes} />}
                {selectedTabIndex === 2 && <CreativeRedirectionMenu />}
                <ActionFooter returnCallback={handleReturn} proceedCallback={handleProceed} />
            </div>
            <div className='creative-preview-container'>
                {renderCreativeByType()}
            </div>
        </div>
    )
}