import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import TextReducer from './store/reducer/text';
import ImageReducer from './store/reducer/image';
import ShapeReducer from './store/reducer/shape';
import CreativeReducer from './store/reducer/creative';
import ControlReducer from './store/reducer/control';
import ImageStyleReducer from './store/reducer/imageStyle';
import TextStyleReducer from './store/reducer/textStyle';
import ShapeStyleReducer from './store/reducer/shapeStyle';
import PopUpReducer from './store/reducer/popUp';
import UserReducer from './store/reducer/user';
import NewCreativeReducer from './store/reducer/newCreative';
import ShapeSelection from './store/reducer/shapeSelection';
import LocalStateReducer from './store/reducer/localState';


import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

const rootReducer = combineReducers({
    txt: TextReducer,
    img: ImageReducer,
    shape: ShapeReducer,
    creative: CreativeReducer,
    ctrl: ControlReducer,
    img_style: ImageStyleReducer,
    txt_style: TextStyleReducer,
    shape_style: ShapeStyleReducer,
    pop_up: PopUpReducer,
    user: UserReducer,
    new_creative: NewCreativeReducer,
    shape_selection: ShapeSelection,
    localState: LocalStateReducer,
});

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}><App /></Provider>
    // </React.StrictMode>
    ,
    document.getElementById('root')
);



/*if(process.env.NODE_ENV !== 'development') {
    const path = require("path");
    const express = require("express");
    const app = express();

    app.use((req, res, next) => {
        return res.sendFile(path.resolve( __dirname, '..' , 'index.html'));
    });
    app.use(express.static(path.join(__dirname, 'build')));
    app.use((req, res, next) => {
        res.sendFile(path.join(__dirname, "..", "build", "index.html"));
    });
    app.get('*//**//**//**//*', function (req, res) {
   res.sendFile(path.join(__dirname, 'build', 'index.html'));
});
}*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();