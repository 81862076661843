import React from 'react';
import './login_style.scss';
import atlantis from '../../Components/utils/images/atlantis.jpg';

export default function ErrorPage (props) {
    const errorText = "We went astray while browsing seven seas in search for our destination. We didn't reach it... But we found Atlantis!";

    //"It seems like we could not find what you searched. The page you were looking for doesn't exist, isn't available or was loading incorrectly.";
    //"It seems we lost the way in deep sea while navigating to our destination, but we found Atlantis!"
    // "We went astray while browsing seven seas in search for our destination. We didn't reach it... But we found Atlantis!"
    return (
        <div className="error-page" style={{backgroundImage:`url(${atlantis})`}}>

            <h1>404</h1>
            <p>{errorText}</p>
        </div>
    )
}