import * as actionTypes from '../action';

const initialState = {
    name: '',
    type: undefined,
    clientId: undefined,
    campaignId: undefined
}

// const initialBreakPoint = {
//     breakPoint: 0,
//     actionType: "",
//     interactionPath: [],
//     rewindFrom: [],
//     rewindTo: [],
//     redirectionUrl: "",
//     onExitLoop: 0,
//     loop: false,
//     mute: false,
//     play: false
// }

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NEW_CREATIVE_NAME:
            return {
                ...state,
                name: action.value
            }
        case actionTypes.SET_NEW_CREATIVE_TYPE:
            return {
                ...state,
                type: action.value
            }
        case actionTypes.SET_NEW_CREATIVE_CLIENT_ID:
            return {
                ...state,
                clientId: action.value
            }
        case actionTypes.SET_NEW_CREATIVE_CAMPAIGN_ID:
            return {
                ...state,
                campaignId: action.value
            }
        case actionTypes.CLEAR_NEW_CREATIVE_FIELDS:
            return {
                ...state,
                name: '',
                type: undefined,
                clientId: undefined,
                campaignId: undefined
            }
        case actionTypes.SET_MUTE_BTN:
            return {
                ...state,
                mute: action.value
            }
        case actionTypes.SET_PLAY_BTN:
            return {
                ...state,
                play: action.value
            }
        case actionTypes.SET_BLACK_BTN:
            return {
                ...state,
                darkMode: action.value
            }
        case actionTypes.SET_BTNS_POSITION:
            return {
                ...state,
                buttonsPosition: action.value
            }
        case actionTypes.SET_AUTO_CLOSE:
            return {
                ...state,
                autoClose: action.value
            }
        case actionTypes.SET_AUTO_CLOSE_TIMER:
            return {
                ...state,
                autoCloseTimer: action.value
            }
        case actionTypes.SET_SHOW_TIMER:
            return {
                ...state,
                showTimer: action.value
            }
        case actionTypes.SET_SHOW_VIDEO:
            return {
                ...state,
                showVideo: action.value
            }
        case actionTypes.SET_SCRATCH_COMPLETE:
            return {
                ...state,
                scratchComplete: action.value
            }
        case actionTypes.SET_TEXT_BACKGROUND_COLOR:
            return {
                ...state,
                textBackgroundColor: action.value
            }
        case actionTypes.SET_GAME_DURATION:
            return {
                ...state,
                gameDuration: action.value
            }
        case actionTypes.SET_SPEED_INTERVAL:
            return {
                ...state,
                speedInterval: action.value
            }
        case actionTypes.SET_INCREASE_SPEED_BY:
            return {
                ...state,
                increaseSpeedBy: action.value
            }
        case actionTypes.SET_GAME_BASE_SPEED:
            return {
                ...state,
                gameBaseSpeed: action.value
            }
        case actionTypes.SET_COLLECT_OR_EVADE:
            return {
                ...state,
                collectOrEvade: action.value
            }
        case actionTypes.SET_ENABLE_GAME_SCORE:
            return {
                ...state,
                enableGameScore: action.value
            }
        case actionTypes.SET_ENABLE_GAME_LIVES:
            return {
                ...state,
                enableGameLives: action.value
            }
        case actionTypes.SET_IMPRESSION_TAG:
            return {
                ...state,
                impressionTag: action.value
            }
        case actionTypes.SET_IMPRESSION_CLICK_TAG:
            return {
                ...state,
                impressionClickTag: action.value
            }
        // case actionTypes.ADD_NEW_BREAK_POINT: {
        //     return {
        //         ...state,
        //         programVideo: {
        //             ...state.programVideo,
        //             breakPoints: [...state.programVideo.breakPoints, initialBreakPoint]
        //         }
        //     }
        // }

        case actionTypes.SET_GRID_COLUMNS_NUMBER: {
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    gridColumnsNum: action.value
                }
            }
        }

        case actionTypes.SET_GRID_ROWS_NUMBER: {
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    gridRowsNum: action.value
                }
            }
        }

        case actionTypes.SET_BREAK_POINT: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, breakPoint: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT__ACTION_TYPE: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, actionType: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT__INTERACTION_PATH: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, interactionPath: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT__REWIND_FROM: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, rewindFrom: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT__REWIND_TO: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, rewindTo: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT_REDIRECTION_URL: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, redirectionUrl: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT_ON_EXIT_LOOP: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, onExitLoop: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT_LOOP: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, loop: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT_MUTE: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, mute: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT_PLAY: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, play: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_VIDEO_ONLY: {
            return {
                ...state,
                videoOnly: action.value
            }
        }

        case actionTypes.SET_TIMER_POSITION: {
            return {
                ...state,
                timerPosition: action.value
            }
        }

        case actionTypes.SET_BACKGROUND_COLOR: {
            return {
                ...state,
                backgroundColor: action.value
            }
        }

        // case actionTypes.SET_ASSET_SCORE: {
        //     const updatedAssets = [...state.assets].map(asset => {
        //         const isSameAsset = asset.uiId === action.uiId || asset.id === action.uiId;
        //         return isSameAsset ? { ...asset, score: action.value.score } : asset;
        //     });
        //     return {
        //         ...state,
        //         assets: updatedAssets
        //     };
        // }
        default:
            return state;
    }

}

export default reducer