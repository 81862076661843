import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import UrlInput from '../Input/mui_inputs/UrlInput';

import { 
    handleBannerRedirectionUrl, 
    handleImpressionClickTag, 
    handleImpressionTag 
} from '../CreativeTemplates/handlers';

// import { CUSTOM_BANNER, DESKTOP_TIMER_BANNER } from '../utils/options/creativeTypes';
// import { DESKTOP_TIMER_BANNER_SIZES } from '../utils/options/sizes';

import './style.scss';
// import { Box, Stack } from '@mui/material';

export default function CreativeRedirectionMenu() {
    const dispatch = useDispatch();

    const redUrl = "Redirection url";

    const redirectionUrl = useSelector(state => state.creative.redirectionUrl);
    const impressionTag = useSelector(state => state.creative.impressionTag);
    const impressionClickTag = useSelector(state => state.creative.impressionClickTag);

    const handleUrlChange = (value) => {
        handleBannerRedirectionUrl(dispatch, value);
    };

    const handleImpressionTagChange = (value) => {
        handleImpressionTag(dispatch, value);
    };

    const handleImpressionClickTagChange = (value) => {
        handleImpressionClickTag(dispatch, value);
    };

    return (
        <div className='creative-redirection-menu-container'>
            <div className='creative-redirection-menu'>
                <UrlInput sx={{ alignItems: 'flex-start' }} label={redUrl} value={redirectionUrl} onComplete={handleUrlChange} itemId={'bannerRedirectionInput'} />
                <UrlInput sx={{ alignItems: 'flex-start' }} label="Impression tag" value={impressionTag || ''} onComplete={handleImpressionTagChange} itemId={'impressionTagInput'} />
                <UrlInput sx={{ alignItems: 'flex-start' }} label="Impression click tag" value={impressionClickTag || ''} onComplete={handleImpressionClickTagChange} itemId={'impressionClickTagInput'} />
            </div>
        </div>
    );
}