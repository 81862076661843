import React from 'react';
import './style.scss';

import { AiFillPlusSquare, AiFillMinusSquare } from 'react-icons/ai';

export default function Number (props) {
    const maxValue = props.maxValue? props.maxValue: 9999;
    const minValue = props.minValue? props.minValue: -9999;
    const step = props.step? props.step: 1;
    const isDisabled = props.disable? props.disable : false;
    const isDisabledClass = isDisabled? 'disabled ':'';
    const classNames = props.className? isDisabledClass + 'ds-inputs ds-number '+ props.className: isDisabledClass + 'ds-inputs ds-number';


    const handleValueIncrement = () =>{
        if (isDisabled)return;
        const newValue = props.value + step;
        if (newValue > maxValue)return;
        props.onChange(newValue);
    };

    const handleValueDecrement = () =>{
        if (isDisabled)return;
        const newValue = props.value - step;
        if (newValue < minValue) return;
        props.onChange(newValue);
    };


    return (
        <div className={classNames}>
            <AiFillMinusSquare onClick={handleValueDecrement} className="icons minus"/>
            <input value={props.value} readOnly disabled={isDisabled}/>
            <AiFillPlusSquare onClick={handleValueIncrement} className="icons plus"/>
        </div>
    )
}