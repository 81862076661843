import './style.scss';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { VIDEO } from '../../constants/imageTypes';
import videoImages from "../utils/images/video_buttons.json";
// import IphoneFrameImage from '../utils/images/iphone_frame.png';
// import IphoneFrameFrontCameraImage from '../utils/images/phone-front-camera.png';

export default function Universal(props) {
    const videoRef = useRef(null);
    const parentRef = useRef(null);

    const [playState, setPlayState] = useState(true);
    const [muteState, setMuteState] = useState(true);
    const [parentSize, setParentSize] = useState({ width: 0, height: 0 });

    const width = useSelector(state => state.creative.bannerWidth);
    const height = useSelector(state => state.creative.bannerHeight);
    // const bgColor = useSelector(state => state.creative.backgroundColor);
    const assets = useSelector(state => state.creative.assets);
    const mute = useSelector(state => state.creative.mute);
    const play = useSelector(state => state.creative.play);
    const darkMode = useSelector(state => state.creative.darkMode);
    const buttonsPosition = useSelector(state => state.creative.buttonsPosition);

    const pauseBtn = darkMode ? videoImages.black.pause : videoImages.white.pause;
    const playBtn = darkMode ? videoImages.black.play : videoImages.white.play;
    const muteBtn = darkMode ? videoImages.black.mute : videoImages.white.mute;
    const unmuteBtn = darkMode ? videoImages.black.unmute : videoImages.white.unmute;

    const firstAsset = (assets && assets.length > 0 && assets[0]) || null;

    const bannerRatio = firstAsset?.width / firstAsset?.height || width / height;

    const MIN_BANNER_WIDTH = 200;
    const MIN_BANNER_HEIGHT = 200;

    const calculateSize = useCallback(() => {
        const maxWidth = parentSize.width * 0.8;
        const maxHeight = parentSize.height * 0.8;

        let bannerWidth = firstAsset ? firstAsset.width : width;
        let bannerHeight = firstAsset ? firstAsset.height : height;

        // Scale up if either dimension is smaller than the minimum, maintaining the aspect ratio
        if (bannerWidth < MIN_BANNER_WIDTH || bannerHeight < MIN_BANNER_HEIGHT) {
            if (bannerWidth < MIN_BANNER_WIDTH) {
                bannerWidth = MIN_BANNER_WIDTH;
                bannerHeight = bannerWidth / bannerRatio;
            } 
            if (bannerHeight < MIN_BANNER_HEIGHT) {
                bannerHeight = MIN_BANNER_HEIGHT;
                bannerWidth = bannerHeight * bannerRatio;
            }
        }

        // Scale down if larger than max width or height, while maintaining the aspect ratio
        if (bannerWidth > maxWidth) {
            bannerWidth = maxWidth;
            bannerHeight = bannerWidth / bannerRatio;
        }
        if (bannerHeight > maxHeight) {
            bannerHeight = maxHeight;
            bannerWidth = bannerHeight * bannerRatio;
        }

        return { bannerWidth, bannerHeight };
    }, [firstAsset, width, height, parentSize, bannerRatio]);

    const { bannerWidth, bannerHeight } = calculateSize();

    const bannerStyle = {
        width: bannerWidth,
        height: bannerHeight,
        backgroundColor: 'white',
        backgroundImage: firstAsset && firstAsset.type !== VIDEO ? `url(${firstAsset.url})` : '',
    };

    const videoStyle = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    }

    const isVideoPlaying = video => !!(videoRef.current?.currentTime > 0 && !videoRef.current?.paused && !videoRef.current?.ended && videoRef.current?.readyState > 2);

    const toggleVideoSound = () => {
        if (videoRef.current.muted) {
            setMuteState(false);
        } else {
            setMuteState(true);
        }
    };

    const toggleVideoPlay = () => {
        if (isVideoPlaying(videoRef.current)) {
            videoRef.current.pause();
            setPlayState(false);
        } else {
            videoRef.current.play();
            setPlayState(true);
        }
    };

    useEffect(() => {
        // Get the parent element size when the component mounts
        const updateSize = () => {
            if (parentRef.current) {
                const { width, height } = parentRef.current.getBoundingClientRect();
                setParentSize({ width, height });
            }
        };

        updateSize(); // Initial size on mount

        // Update size on window resize
        window.addEventListener("resize", updateSize);

        return () => window.removeEventListener("resize", updateSize); // Cleanup
    }, []);

    useEffect(() => {
        calculateSize(); // Recalculate size when assets change
    }, [assets, parentSize, calculateSize]);

    return (
        <div 
            ref={parentRef}
            className="banner-display-container" 
            style={{ 
                // backgroundImage: `url(${IphoneFrameImage})`, 
                width: '100%', 
                height: '100%',
            }}
        >
        {/*<div className="banner-display-container grid-content">*/}
            {/* <img className='banner-display-frame-image' src={IphoneFrameFrontCameraImage} /> */}
            <div style={firstAsset && firstAsset.type === VIDEO ? { position: 'relative' } : {}}>
                {firstAsset && firstAsset.type === VIDEO && (
                    <div id="ds_div_buttons" className='video-interstitial' style={{ bottom: buttonsPosition === 'Top' ? '85%' : buttonsPosition === 'Center' ? '45%' : '5%', width: '100%' }}>
                        <img className={`ds_buttons left ${play ? '' : 'd-none'}`} id="sa_play_video" alt="play button" src={playState ? pauseBtn : playBtn} onClick={toggleVideoPlay} />
                        <img className={`ds_buttons right ${mute ? '' : 'd-none'}`} id="sa_play_sound" alt="sound button" src={muteState ? muteBtn : unmuteBtn} onClick={toggleVideoSound} />
                    </div>
                )}
                <div style={bannerStyle}>
                    {firstAsset && firstAsset.type === VIDEO && (
                        <video autoPlay={true} muted={muteState} loop ref={videoRef} src={firstAsset?.url} style={videoStyle}>
                            <source type="video/mp4" />
                        </video>
                    )}
                </div>
            </div>
        </div>
    )
}

